var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AnimateCom',{key:_vm.element.id,attrs:{"animations":_vm.element.animations}},[(_vm.element.statusConfig
			&& _vm.element.statusConfig.isShow
			&& _vm.judgingArchiAPermission(_vm.element.archiLimit, _vm.element.authConfig.list)
			&& _vm.judgingUILogic(_vm.element.UILogic, _vm.componentList || _vm.componentData, _vm.mapData))?_c('div',{staticClass:"component-box 333",class:[
			'component-box-span-' + (+_vm.element.span),
			_vm.getHoverClass(_vm.element?.specialStyle?.hoverConfig)
		],style:(_vm.getBoxStyle(_vm.element)),attrs:{"data-id":_vm.element.id}},[_vm._t("inner"),_c(_vm.element.component,{tag:"component",style:(_vm.getComStyle(_vm.element)),attrs:{"element":_vm.element,"containerData":_vm.containerData,"defaultTableSelectData":_vm.defaultTableSelectData,"componentList":_vm.componentList,"mapData":_vm.mapData}})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }