<!--root
 * @Author: your name
 * @Date: 2021-12-02 15:32:01
 * @LastEditTime: 2023-03-06 10:42:32
 * @LastEditors: Shiltin 18580045074@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nanhu-micro\src\views\components\bindModel\index.vue
-->
<template>
  <div class="broadcast-cont">
    <Group
      v-if="element.statusConfig?.showDevices || element.statusConfig?.showDevices === undefined"
      @on-change="onChooseDevice"
      @on-add-group="dialogVisible = true"
      :groupData="groupData"
    />
    <div class="voice-cont">
      <div class="top-handle" v-if="chooseGroup">
        <div class="group-name">
          <span v-if="!isEditName">{{
            chooseGroup?.team_name || "未命名"
          }}</span>
          <el-input
            v-else
            v-model="groupNameVal"
            clearable
            @blur="onSaveName"
          />
        </div>
        <Dropdown class="middle-dropdown" :class="isOnLine?'online-status':'offline-status'" trigger="click">
          <span class="el-dropdown-link" v-if="groupName">
            {{ `终端设备（${Object.keys(groupName).length}）`
            }}<i class="iconfont iconjijia_sanjiaoxiangxia"></i>
          </span>
          <DropdownMenu
            v-if="groupName && Object.keys(groupName)?.length"
            slot="dropdown"
          >
            <DropdownItem
              v-for="(value, key, index) in groupName"
              :key="index"
              ><p :class="getStatus(key)?'is-on-broadcast':'is-off-broadcast'">{{ value }}</p></DropdownItem
            >
          </DropdownMenu>
        </Dropdown>
        <Dropdown>
          <span class="el-dropdown-link">
            <i class="iconfont icongengduomore" style="color: #4d535e"></i>
          </span>
          <DropdownMenu slot="dropdown">
            <DropdownItem
              ><p @click="isEditName = true">
                <i class="iconfont iconbianji2"></i>重命名
              </p></DropdownItem
            >
            <DropdownItem
              ><p @click="onDelGroup">
                <i class="iconfont icongis_shanchu"></i>删除
              </p></DropdownItem
            >
          </DropdownMenu>
        </Dropdown>
      </div>
      <div class="middle-handle">
        <div class="tab-middle-handle" v-if="tabIndex === 1">
          <img
            v-if="!chooseGroup && !liveCastIds"
            src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/73bd24219814e123c3c24b36aa50a133.png"
            alt=""
          />
          <div class="animate-cont" :style="{opacity:isOnLine?'1':'0.2'}" v-else>
            <img
              v-if="!isSpeaking"
              src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/7bdf905a3ef27b52dccaf912581587c4.png"
              alt=""
            />
            <VoiceWave v-else size="130" style="margin: 20px 0 15px 0"></VoiceWave>
          </div>  
          <div class="desc-cont">
            <p v-if="!chooseGroup  && !liveCastIds">点击组列表进行喊话</p>
            <p v-else-if="!isOnLine">设备离线，暂无法喊话</p>
            <p v-else-if="!isSpeaking">空格键长按说话</p>
            <p v-else>播放中...</p>
          </div>
        </div>
        <div class="tab-middle-file" v-else-if="tabIndex === 2">
          <p>文件列表</p>
          <el-input
            placeholder="请输入内容"
            class="file-input"
            @input="onFilterData"
            v-model="searchVal">
            <i slot="prefix" class="el-input__icon el-icon-search" @click="onFilterData(searchVal)"></i>
          </el-input>
          <div class="file-tree">
            <el-tree
              ref="treeRef"
              default-expand-all
              show-checkbox
              node-key="id"
              :data="files"
              :filterNodeMethod="filterNode"
              :props="defaultProps"
              @check="onCheckChange">
            </el-tree>
          </div>
          <div class="bottom-btn-cont">
            <div class="choose-font">已选中（{{checkFiles.length}}）</div> 
              <div class="send-btn cancel" v-if="isSpeech" @click="stopSpeech">停止</div> 
              <div class="send-btn" v-else @click="onSpeech" :style="{opacity:(!checkFiles?.length || !isOnLine)?'0.6':'1'}">{{ !isOnLine?'设备离线':'播放' }}</div> 
          </div>
        </div>    
      </div>
      <div v-if="chooseGroup" class="bottom-handle">
        <div class="icon-cont">
          <i
            class="iconfont icontongzhiguangbo"
            :class="tabIndex === 1 ? 'tab-active' : ''"
            @click="onChangeTab(1)"
          ></i>
          <i
            class="iconfont iconwenjianjia5"
            :class="tabIndex === 2 ? 'tab-active' : ''"
            @click="onChangeTab(2)"
          ></i>
        </div>
        <div class="voice-num-cont">
          <span> {{ voiceNum }}</span>
          <Slider
            v-model="voiceNum"
            :min="0"
            :max="100"
            :step="1"
            :disabled="!isOnLine"
            class="contentItem-slider"
            @change="onChangeVoice"
          ></Slider>
          <i class="iconfont iconyinliang"></i>
        </div>
      </div>
    </div>
    <History v-if="chooseGroup" :chooseGroup="chooseGroup" :groupName="groupName" :onlineDevices="onlineDevices" />
    <el-dialog
      title="创建喊话组"
      :visible.sync="dialogVisible"
      width="30%"
      @close="dialogVisible = false"
    >
      <ChooseDevice @on-save="onSaveGroup" v-if="dialogVisible" />
    </el-dialog>
  </div>
</template>
<script setup>
/* eslint-disable */
import { onMounted, onUnmounted, inject, ref, computed, getCurrentInstance  } from "vue";
import {
  Slider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  MessageBox,
} from "element-ui";
import { dataInterface } from "@/apis/data/index";
import Group from "./components/group.vue";
import History from "./components/history.vue";
import ChooseDevice from "./components/chooseDevice.vue";
import VoiceWave from "./components/voiceWave.vue";
import axios from "axios";
import { HZRecorder } from "@/utils/HZRecorder.js";
import { getToken, removeEventBus, initParams } from "@/utils/tools";
import { baseUrl } from "@/apis/http/request";
import eventBus from "@/plugins/eventBus";
const useStore = () => {
  const vm = getCurrentInstance();
  if (!vm) throw new Error("must be called in setup");
  return vm.proxy.$store;
};
const useRoute = () => {
  const vm = getCurrentInstance();
  if (!vm) throw new Error("must be called in setup");
  return vm.proxy.$route;
};

const props = defineProps({
  element: {
    type: Object,
    required: true,
    default: () => {},
  }
});
const EDITOR_pageUUID = inject("EDITOR_pageUUID");
const voiceNum = ref(50);
const chooseGroup = ref(null);
const timer = ref(null);
const treeRef = ref(null);
const isOnLine = ref(true);
const isSpeaking = ref(false);
const dialogVisible = ref(false);
const isEditName = ref(false);
const tabIndex = ref(1);
const baseRecorder = ref(null);
const groupData = ref([]);
const groupName = ref(null); //设备组名称
const elvenceChild = ref([]);
const onlineDevices = ref([]);
const files = ref([]);//播放文件
const checkFiles = ref([]);//选中的文件
const playFiles = ref([]);
const searchVal = ref('');
const groupNameVal = ref("");
const deviceBaseUrl = ref("https://bcast-service.bimcc.com:8080");
const liveCastIds = ref("");
const apiKey = ref("YWRtaW46YWRtaW5fYXBpX2tleQ==");
const store = useStore();
const route = useRoute();
const isSpeech = ref(false); //是否在播放文件
const defaultProps = ref({
  children: 'children',
  label: 'name'
})
const subComponentData = computed(() => {
  if (EDITOR_pageUUID) {
    return (
      store.state.subsidiaryComponentData?.[EDITOR_pageUUID]?.componentData ||
      store.state.componentData
    );
  }
  return store.state.componentData;
});
const getStatus = computed(()=>{
  return function (item) {
    return onlineDevices.value.filter(v=>v.device_number === item).length !== 0;
  }
})

const postRequest = (str, data) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      data: data,
      url: `${deviceBaseUrl.value}${str}`,
      headers: {
        Authorization: `basic ${apiKey.value}`,
      },
    }).then((res) => {
      resolve(res?.data?.data || []);
    }).catch(err => {
      console.log(err, '-----');
      reject();
    });
  })
};
const onChangeTab = (index) =>{
  tabIndex.value = index;
  if(isSpeech.value && +index === 1){
    stopSpeech()
  }
}
//左侧组列表数据
const getGroupData = () => {
  isEditName.value = false;
  dataInterface(null, "api/graph/1617").then((res) => {
    if (res.data.code === 200) {
      groupData.value = res.data.data || [];
      if (chooseGroup.value) {
        chooseGroup.value = groupData.value.find(
          (v) => v.id === chooseGroup.value.id
        );
      }
    }
  });
};
//获取文件
const getFilesData = () => {
  dataInterface(null, "api/graph/1627").then((res) => {
    if (res.data.code === 200) {
      files.value = res.data.data || [];
    }
  });
}
const onFilterData = (str) => {
  treeRef.value.filter(str);
}
//搜索
const filterNode = (value, data) => {
  if (!value || !defaultProps.value?.label || !data ) return true;
  if(!data[defaultProps.value?.label]){
    return false;
  }
  return data[defaultProps.value?.label]?.indexOf(value) !== -1;
};
//终止设备播放
const stopSpeech = () => {
  postRequest('/v1/text_speech/stop',{device_ids: JSON.parse(chooseGroup.value.team_device_id)});
  postRequest('/v1/url_speech/stop',{device_ids: JSON.parse(chooseGroup.value.team_device_id)});
  isSpeech.value = false;
}
//选中文件
const onCheckChange = () => {
  checkFiles.value = treeRef.value.getCheckedNodes();
}
const onSpeech = () => {
  // stopSpeech();
  playFiles.value = [...checkFiles.value];
  if(!playFiles.value?.length || !isOnLine.value){
    return;
  }
  const data = {...playFiles.value[0]};
  onPlayData(data);
  isSpeech.value = true;
  playFiles.value.splice(0,1);
}
const onPlayData = (data) =>{
  let url = '/v1/url_speech/start';
  let param = {
    device_ids: JSON.parse(chooseGroup.value.team_device_id),
    volume: voiceNum.value
  };
  if(data?.type === 1){
    //播放文件
    if(data.value.length){
      param.url = data.value[0].url;
    } else {
      return;
    }
  } else if(data?.type === 2){
    //播放文字
    url = '/v1/text_speech/start';
    param.vcn = data.people_type === 1?'xiaoyan':'xiaofeng';
    param.speech = param.people_speed?param.people_speed * 25 : 50;//语速
    param.text = data.value;
  } else if(data?.type === 3){
    //播放电台
    param.url = data.value;
  }
  //播放请求
  postRequest(url,param)
}
//保存组
const onSaveGroup = (data, name) => {
  let idArr = [];
  let nameObj = {};
  for (let i = 0; i < data.length; i++) {
    idArr.push(data[i].id);
    nameObj[data[i].device_number] = data[i].name;
  }
  dataInterface({
    __method_name__: "createData",
    object_uuid: "object67452bf10ffc4",
    team_name: name,
    team_device_id: idArr,
    team_device_name: nameObj,
  }).then((res) => {
    if (res.data.code === 200) {
      dialogVisible.value = false;
      getGroupData();
    }
  });
};
//修改名称
const onSaveName = () => {
  dataInterface({
    __method_name__: "updateData",
    object_uuid: "object67452bf10ffc4",
    data_id: chooseGroup.value.id,
    team_name: groupNameVal.value,
  }).then((res) => {
    if (res.data.code === 200) {
      isEditName.value = false;
      chooseGroup.value = res.data.data;
      getGroupData();
    }
  });
};
//删除组
const onDelGroup = () => {
  MessageBox.confirm("此操作将删除该广播组, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
  .then(() => {
    dataInterface({
      __method_name__: "deleteData",
      object_uuid: "object67452bf10ffc4",
      data_id: chooseGroup.value.id,
    }).then((res) => {
      if (res.data.code === 200) {
        chooseGroup.value = null;
        getGroupData();
      }
    });
  })
  .catch(() => {});
};
//调整音量
const onChangeVoice = () => {
  if (chooseGroup.value) {
    postRequest("/v1/device/batch_volume", {
      device_ids: JSON.parse(chooseGroup.value.team_device_id),
      ao_volume: voiceNum.value,
    });
  }
};
//选中设备组
const onChooseDevice = (item) => {
  const arr = JSON.parse(item.team_device_id);
  let n = 0;
  groupName.value = JSON.parse(item.team_device_name);
  liveCastIds.value = arr.join(",");
  elvenceChild.value = [];
  arr.forEach((item) => {
    elvenceChild.value.push({ device_id: item, commun_type: 1 });
  });
  isEditName.value = false;
  chooseGroup.value = {
    ...item,
    children: item.children.map((val) => {
      return {
        ...val,
        showFont: false,
        showDevice: false,
        text: "",
      };
    }),
  };
  groupNameVal.value = item.team_name;
  //判断该组是否都不在线
  if(groupName.value){
    for(let key in groupName.value){
      let isOff= onlineDevices.value.filter(v=>v.device_number === key).length === 0;
      if(isOff){
        n++;
      }
    }
    if(n === Object.keys(groupName.value).length){
      isOnLine.value = false;
    }
  }
};
const keydownFun = (event) => {
  if(!isOnLine.value && tabIndex.value === 2){
    return;
  }
  const keyCode = event.keyCode || event.which;
  if (+keyCode === 32) {
    if (!isSpeaking.value) {
      isSpeaking.value = true;
      startLive();
      if (baseRecorder.value) {
        stopSpeech();
        baseRecorder.value.start();
      }
    }
  }
};
const keyUpFun = (event) => {
  if(!isOnLine.value  && tabIndex.value === 2){
    return;
  }
  const keyCode = event.keyCode || event.which;
  if (+keyCode === 32) {
    if (isSpeaking.value) {
      isSpeaking.value = false;
      stopLive();
    }
  }
};
const startLive = () => {
  const ids = liveCastIds.value
    .split(",")
    .filter((x) => x !== "")
    .map((x) => Number(x));
  if (ids.length === 0) {
    alert("未指定目标设备ID列表");
    return;
  }
  if (window.client) {
    window.client.startLiveCast(ids);
  }
};
const stopLive = () => {
  if (window.client) {
    window.client.stopLiveCast();
    // 结束录音识别
    if (!baseRecorder.value || !chooseGroup.value) {
      return;
    }
    baseRecorder.value.stop();
    let audioData = new FormData();
    const newBolb = baseRecorder.value.getBlob();
    const fileName = `广播${new Date().getTime()}.wav`;
    const fileOfBlob = new File([newBolb], fileName, {
      type: "audio/wav",
    });
    audioData.append("file", fileOfBlob);
    const seconds = baseRecorder.value.seconds;
    baseRecorder.value.destory();
    // 重新初始化
    baseRecorder.value = null;
    initRecorder({ video: false, audio: true });
    axios({
      method: "post",
      url: `${baseUrl}api/mapi?__method_name__=file&token=${getToken()}`,
      data: audioData,
    }).then((res) => {
      if (res.data?.code === 200) {
        //存录音文件
        dataInterface({
          __method_name__: "createData",
          object_uuid: "object67482cfe71951",
          device_name: chooseGroup.value?.team_device_name,
          device_ids: chooseGroup.value.team_device_id,
          commun_type: 1,
          file_name: fileName,
          commu_volume: voiceNum.value,
          commun_times: seconds,
          files: res.data.data,
          team_id: chooseGroup.value.id,
          elvence_child: elvenceChild.value,
        }).then((re) => {
          if (re.data?.code === 200) {
            getGroupData();
          }
        });
      }
    });
  }
};
//初始化录音
const initRecorder = (constrains) => {
  if (navigator?.mediaDevices?.getUserMedia) {
    navigator?.mediaDevices
      ?.getUserMedia(constrains)
      .then((stream) => {
        baseRecorder.value = new HZRecorder(stream);
      })
      .catch((err) => {
        console.log(err);
      });
  } else if (navigator?.webkitGetUserMedia) {
    navigator
      .webkitGetUserMedia(constrains)
      .then((stream) => {
        baseRecorder.value = new HZRecorder(stream);
      })
      .catch((err) => {
        console.log(err);
      });
  } else if (navigator?.mozGetUserMedia) {
    navigator
      .mozGetUserMedia(constrains)
      .then((stream) => {
        baseRecorder.value = new HZRecorder(stream);
      })
      .catch((err) => {
        console.log(err);
      });
  } else if (navigator?.getUserMedia) {
    navigator
      .getUserMedia(constrains)
      .then((stream) => {
        baseRecorder.value = new HZRecorder(stream);
      })
      .catch((err) => {
        console.log(err);
      });
  }
};
const onBehavior = (type, param) => {
  console.log(param, "外部广播", type);
  if (!param?.device_id) {
    return;
  }
  if (type === "onBroadcast") {
    //一键喊话
    liveCastIds.value = `${param.device_id},`;
    if(param.device_id && param.device_name){
      let obj = {};
      groupName.value = obj[param.device_id] = param.device_name;
    }
  } else if (type === "onListen") {
    //监听
    postRequest("/v1/device_subscribe", { device_ids: param.device_id });
  } else if(type === "offListen"){
    //取消监听
    postRequest("/v1/device_subscribe_cancel", { device_ids: param.device_id });
  }
};

onUnmounted(() => {
  window.removeEventListener("keydown", keydownFun);
  window.removeEventListener("keyup", keyUpFun);
  removeEventBus(["doComponentBehavior"], props.element.id);
   eventBus.$off('deviceData');
});
onMounted(() => {
  const doComponentBehavior = {
    [props.element.id]: (config) => {
      const { component, list = [] } = config;

      if (props.element.id !== component) return false;
      list.forEach((ele) => {
        const { behaviors, params } = ele;
        const { param = {}, canPost } = initParams(
          params,
          false,
          subComponentData.value,
          []
        );
        if (canPost) {
          // 调用行为方法
          behaviors.forEach((funName) => {
            try {
              onBehavior(funName, param);
            } catch (err) {
              console.log(err);
            }
          });
        }
      });
    },
  };
  eventBus.$on("doComponentBehavior", doComponentBehavior[props.element.id]);
  eventBus.$on('deviceData',(data)=>{
    clearTimeout(timer.value);
    timer.value = setTimeout(()=>{
      onlineDevices.value = data.onLine || [];
      //播放文件监听完播放下一个文件内容
      if(isSpeech.value && chooseGroup.value){
        if(!data.busy?.length){
          if(playFiles.value?.length && checkFiles.value.length > 1){
            onPlayData({...playFiles.value[0]});
            playFiles.value.splice(0,1);
          } else {
            isSpeech.value = false;
          }
        } else {
          let n = 0;
          for(let key in groupName.value){
            if(!data.busy.filter(v=>v.device_number === key).length){
              n++;
            }
            if(n === Object.keys(groupName.value).length){
              if(playFiles.value?.length && checkFiles.value.length > 1){
                onPlayData({...playFiles.value[0]});
                playFiles.value.splice(0,1);
              } else {
                isSpeech.value = false;
              }
            }
          }
        }
      }
    },300);
  });
  if (
    navigator.mediaDevices.getUserMedia ||
    navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia
  ) {
    initRecorder({ video: false, audio: true });
  }
  //路由携带设备id情况
  if(route?.query?.device_id){
    //一键喊话
    liveCastIds.value = `${route?.query.device_id},`;
  }
  getGroupData();
  getFilesData();
  // 监听键盘按下事件
  window.addEventListener("keydown", keydownFun);
  window.addEventListener("keyup", keyUpFun);
});
</script>
<style>
.is-on-broadcast{
  color:#387ffc!important;
}
.is-off-broadcast{
  color:#a4acbd!important;
}
</style>
<style scoped lang="less">
.broadcast-cont {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  :deep(.el-dialog__body) {
    padding: 0;
  }
  .voice-cont {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: var(--overall-surface-variant, #f2f5fa);
    border: 1px solid var(--border-on-surface-medium, #e1e4ec);
    overflow: hidden;
    .top-handle {
      height: 56px;
      line-height: 36px;
      width: 100%;
      padding: 10px 16px;
      border-bottom: 1px solid var(--border-on-surface-medium, #e1e4ec);
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: var(--text-on-surface-primary, #181b22);
      font-family: "Source Han Sans CN";
      font-size: 16px;
      font-weight: 500;
      position: relative;
      .group-name {
        width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      :deep(.el-dropdown.middle-dropdown) {
        color: #387ffc;
        position: absolute;
        left: 50%;
        margin-left: -54px;
      }
      :deep(.el-dropdown.offline-status){
        color: #a4acbd!important
      }
      .more {
        font-size: 20px;
        font-family: "Source Han Sans CN";
      }
    }
    .middle-handle {
      flex: 1;
      width: 100%;
      overflow: hidden;
      .tab-middle-handle{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        img {
          width: 163px;
          height: 163px;
        }
        .desc-cont {
          margin-top: 40px;
          color: var(--text-on-surface-tertiary, #707786);
          font-family: "Source Han Sans CN";
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          span {
            color: var(--text-on-surface-brand, #387ffc);
          }
        }
      }
      .tab-middle-file {
        width: 100%;
        height: 100%;
        padding: 8px;
        box-sizing: border-box;
        background: #fff;
        display: flex;
        flex-direction: column;
        p{
          display: flex;
          height: 32px;
          line-height: 32px;
          padding: 0 12px;
          box-sizing: border-box;
          color: var(--sys-text-text-primary, #161C1F);
          font-family: "Source Han Sans CN";
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.5px;
        }
        .file-input{
          margin: 8px 0;
          border-radius: 6px;
          :deep(.el-input__inner){
            background: var(--sys-core-background, #F4F6FA);
          }
        }
        .file-tree{
          flex: 1;
          overflow-y: auto;
        }
        .bottom-btn-cont{
          height: 64px;
          width:100%;
          padding: 12px 20px;
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .choose-font{
            color: var(--sys-text-text-primary, #161C1F);
            font-family: "Source Han Sans CN";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 40px; /* 137.5% */
            letter-spacing: 0.5px;
          }
          .send-btn{
            width: 106px;
            height: 40px;
            box-sizing: border-box;
            text-align: center;
            line-height: 30px;
            padding: 5px 12px;
            color: var(--sys-core-on-color, #FFF);
            font-family: "Source Han Sans CN";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0.5px;
            border-radius: 6px;
            background: var(--sys-button-button-primary, #337EFF);
            margin-left:10px;
          }
          .cancel{
            background-color: transparent;
            border: 1px solid var(--sys-surface-surface-01-active, #C1C7CD);
            color: #C1C7CD;
          }
        }
      }
    }
    .bottom-handle {
      height: 48px;
      padding: 8px 16px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      line-height: 16px;
      color: #4d535e;
      .icon-cont {
        i {
          font-size: 16px;
          line-height: 16px;
          padding: 8px;
          margin-right: 8px;
          border-radius: 4px;
        }
        .tab-active {
          border-radius: var(--radius-2, 4px);
          color: #387ffc;
          background: var(--list-background-selected-variant, #d1e3ff);
        }
      }
      .voice-num-cont {
        text-align: right;
        display: flex;
        flex-direction: row;
        align-items: center;
        i,
        span {
          line-height: 24px;
          font-size: 16px;
        }
        :deep(.el-slider) {
          width: 130px;
          margin: 0 5px;
        }
        :deep(.el-slider__button) {
          width: 9px;
          height: 9px;
          border-width: 1px;
        }
      }
    }
  }
}
</style>
