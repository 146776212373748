var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"table-container",staticStyle:{"flex-direction":"column"},attrs:{"id":_vm.boxId}},[(
    _vm.showTable && 
      _vm.statusConfig &&
      ((!_vm.statusConfig.isTreeTable && !_vm.statusConfig.feTreeKey) ||
        (_vm.statusConfig.isTreeTable && _vm.rowKey) ||
        (_vm.statusConfig.feTreeKey && _vm.rowKey)) &&
      _vm.fieldConfig
    )?_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.statusConfig.loading),expression:"statusConfig.loading"}],key:_vm.element.id,ref:_vm.element.id,staticClass:"table-box",class:{
      'mounted-class': _vm.tableMounted,
      'transparent-table': _vm.statusConfig?.transparent,
    },style:({ width: _vm.mainWidth, border: '1px solid #f2f3f5', height: _vm.height }),attrs:{"data":_vm.tableData,"height":_vm.height,"row-key":_vm.rowKey,"stripe":_vm.statusConfig.stripe,"border":_vm.statusConfig.border,"size":_vm.statusConfig.size,"fit":_vm.statusConfig.fit,"show-header":_vm.statusConfig.showHeader,"highlight-current-row":_vm.statusConfig.highlightCurrentRow,"empty-text":_vm.statusConfig.emptyText,"tree-props":_vm.treeProps,"header-cell-style":{ 'text-align': 'left' },"cell-style":{ 'text-align': 'left' },"default-expand-all":_vm.statusConfig.defaultExpandAll === false ? false : true},on:{"selection-change":_vm.onSelectionChange,"select":_vm.handleSelectionChange,"row-click":_vm.onRowClick}},[_c('template',{staticClass:"empty-box",slot:"empty"},[_c('el-empty',{attrs:{"image-size":80,"description":"暂无数据"}})],1),(_vm.statusConfig.isNesting)?_c('el-table-column',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _c('div',{style:({ width: _vm.boxWidth })},[_c('el-table',{key:_vm.element.id + '-child',attrs:{"data":scope.row.children,"row-key":_vm.statusConfig.nestingRowKey,"border":"","header-cell-style":{
            'background-color': '#E8F0FE',
            height: '48px',
            'text-align': 'left',
          },"cell-style":{ 'text-align': 'left' }}},[_c('template',{staticClass:"empty-box",slot:"empty"},[_c('el-empty',{attrs:{"description":"暂无数据"}})],1),_vm._l((_vm.nestingFieldConfig),function(item,index){return (
              item.show &&
              _vm.validArchiType(item.archiType) &&
              _vm.judgingArchiAPermission([], item.permission) &&
              _vm.judgingUILogic(item.UILogic, _vm.subComponentData)
            )?_c('el-table-column',{key:item.uuid,attrs:{"label":item.label,"align":_vm.statusConfig.align,"header-align":_vm.statusConfig.headerAlign,"width":item.width},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return _c('span',{style:({ color: row[item.uuid] ? item.color : '#bbb' })},[(+item.type === 1)?[(item?.textConfig?.useEllipsis)?[(
                      item?.textConfig?.usePopover &&
                      row[item.uuid]?.toString().length >
                        (item?.textConfig?.pCount || 20)
                    )?[_c('el-popover',{attrs:{"placement":"top-start","width":isNaN(+item?.textConfig?.pWidth)
                          ? 150
                          : +item?.textConfig?.pWidth,"trigger":"hover","content":_vm.isNull(row[item.uuid]) ? '/' : row[item.uuid]}},[_c('p',{class:_vm.getEllipsisClass(item?.textConfig?.row),attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" "+_vm._s(_vm.isNull(row[item.uuid]) ? '/' : row[item.uuid])+" ")])])]:_c('p',{class:_vm.getEllipsisClass(item?.textConfig?.row)},[_vm._v(" "+_vm._s(_vm.isNull(row[item.uuid]) ? '/' : row[item.uuid])+" ")])]:[_vm._v(_vm._s(_vm.isNull(row[item.uuid]) ? '/' : row[item.uuid]))]]:(item.type === 2)?[(Array.isArray(row[item.uuid]))?_c('el-image',{staticClass:"image",style:({
                    height: (item.imgConfig.height || 60) + 'px',
                    width: (item.imgConfig.height || 60) + 'px',
                  }),attrs:{"src":row[item.uuid] &&
                    row[item.uuid].length &&
                    (row[item.uuid][0].thumb_path||row[item.uuid][0].path)?.toString()?.indexOf?.('http') > -1
                      ? (row[item.uuid][0].thumb_path||row[item.uuid][0].path)
                      : _vm.defaultImage,"previewSrcList":_vm.getImageList(row[item.uuid]),"fit":"cover","alt":"图片"}}):_c('el-image',{staticClass:"image",style:({
                    height: (item.imgConfig.height || 60) + 'px',
                    width: (item.imgConfig.height || 60) + 'px',
                  }),attrs:{"src":row[item.uuid] && row[item.uuid]?.indexOf?.('http') > -1
                      ? row[item.uuid]
                      : _vm.defaultImage,"previewSrcList":[
                    row[item.uuid]
                      ? row[item.uuid]?.toString().replace('_thumb', '')
                      : _vm.defaultImage,
                  ],"fit":"cover","alt":"图片"}})]:(item.type === 3 && !_vm.isNull(row[item.uuid]))?[(!item.statusStyle)?_c('span',{style:({
                    color:
                      _vm.filterStatus(row[item.uuid], item.statusOptions)
                        .color || item.color,
                  })},[_vm._v(" "+_vm._s(_vm.filterStatus(row[item.uuid], item.statusOptions).label)+" ")]):(item.statusStyle === 'block')?_c('span',{staticClass:"status-block",style:({
                    background:
                      _vm.filterStatus(row[item.uuid], item.statusOptions)
                        .color || item.color,
                  })},[_vm._v(" "+_vm._s(_vm.filterStatus(row[item.uuid], item.statusOptions).label)+" ")]):(item.statusStyle === 'point')?_c('span',{staticClass:"status-point"},[_c('span',{staticClass:"point",style:({
                      background:
                        _vm.filterStatus(row[item.uuid], item.statusOptions)
                          .color || item.color,
                    })}),_c('span',{style:({
                      color:
                        _vm.filterStatus(row[item.uuid], item.statusOptions)
                          .color || item.color,
                    })},[_vm._v(_vm._s(_vm.filterStatus(row[item.uuid], item.statusOptions).label))])]):_vm._e()]:(item.type === 4 && !_vm.isNull(row[item.uuid]))?[_vm._v(_vm._s(_vm.filterTime(row[item.uuid], item.timeFormat, item.connector)))]:(item.type === 5 && !_vm.isNull(row[item.uuid]))?[_c('div',{staticClass:"rich-text",domProps:{"innerHTML":_vm._s(row[item.uuid])}})]:(item.type === 6 && !_vm.isNull(row[item.uuid]))?[_c('a',{staticClass:"link",attrs:{"href":row[item.uuid],"alt":"链接"}},[_vm._v(_vm._s(row[item.uuid]))])]:(item.type === 7)?[_c('el-switch',{on:{"change":function($event){return _vm.onSwitch(item, row[item.uuid], row, true)}},model:{value:(row[item.uuid]),callback:function ($$v) {_vm.$set(row, item.uuid, $$v)},expression:"row[item.uuid]"}})]:(item.type === 8)?[_vm._v(" "+_vm._s(_vm.filterNumber(row[item.uuid], item.numberOptions) || '/')+" ")]:(item.type === 9)?[_vm._v(" "+_vm._s(_vm.getPositionName(row[_vm.field.uuid]))+" ")]:[_vm._v(_vm._s(_vm.isNull(row[item.uuid]) ? '/' : row[item.uuid]))]],2)}}],null,true)}):_vm._e()}),(_vm.nestingActionConfig && _vm.nestingActionConfig.needAction)?_c('el-table-column',{key:"actions",attrs:{"width":_vm.nestingActionConfig.width || '',"align":_vm.statusConfig.align,"header-align":_vm.statusConfig.headerAlign,"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _vm._l((_vm.nestingActionList),function(action,index){return _c('span',{key:index},[(
                    action &&
                    typeof action === 'object' &&
                    action.use &&
                    _vm.logicAction(action, scope.row) &&
                    _vm.judgingArchiAPermission([], action.permission) &&
                    _vm.judgingUILogic(action.UILogic, _vm.subComponentData)
                  )?_c('el-button',{style:({
                    color: action.color,
                    marginLeft: action.margin + 'px',
                  }),attrs:{"type":action.btnType,"size":action.size},on:{"click":function($event){return _vm.onAction(
                      action.type,
                      scope.row,
                      $event,
                      'nesting',
                      _vm.nestingActionConfig
                    )}}},[_vm._v(_vm._s(action.name))]):_vm._e()],1)})}}],null,true)}):_vm._e()],2)],1)}}],null,false,436302442)}):_vm._e(),(_vm.statusConfig.canSelect)?_c('el-table-column',{attrs:{"type":"selection","width":"55"}}):_vm._e(),(_vm.statusConfig.showOrder || _vm.statusConfig.useFeWBS)?_c('el-table-column',{attrs:{"label":"序号","type":"index","width":_vm.statusConfig.orderWidth || 50,"fixed":_vm.fixedIndexRow},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.statusConfig.useFeWBS)?[_vm._v(" "+_vm._s(scope.row.wbsString || '')+" ")]:[_vm._v(_vm._s(scope.$index + 1))]]}}],null,false,1824610845)}):_vm._e(),_vm._l((_vm.fieldConfig),function(item,index){return (
        item.show &&
        _vm.validArchiType(item.archiType) &&
        _vm.judgingArchiAPermission([], item.permission) &&
        _vm.judgingUILogic(item.UILogic, _vm.subComponentData)
      )?_c('el-table-column',{key:item.uuid,attrs:{"label":item.label,"align":_vm.statusConfig.align,"header-align":_vm.statusConfig.headerAlign,"width":item.width,"fixed":!!_vm.fixColumns.find((ele) => +index <= +ele)},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return _c('span',{style:({
          color:
            (item.type !== 4 && !_vm.isNull(row[item.uuid])) ||
            (item.type === 4 &&
              _vm.filterTime(row[item.uuid], item.timeFormat, item.connector))
              ? item.color
              : '#bbb',
        })},[(+item.type === 1)?[(item?.textConfig?.useEllipsis)?[(
                item?.textConfig?.usePopover &&
                row[item.uuid]?.toString().length >
                  (item?.textConfig?.pCount || 20)
              )?[_c('el-popover',{attrs:{"placement":"top-start","width":isNaN(+item?.textConfig?.pWidth)
                    ? 150
                    : +item?.textConfig?.pWidth,"trigger":"hover","content":_vm.isNull(row[item.uuid]) ? '/' : row[item.uuid]}},[_c('p',{class:_vm.getEllipsisClass(item?.textConfig?.row),attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" "+_vm._s(_vm.isNull(row[item.uuid]) ? '/' : row[item.uuid])+" ")])])]:_c('p',{class:_vm.getEllipsisClass(item?.textConfig?.row)},[_vm._v(" "+_vm._s(_vm.isNull(row[item.uuid]) ? '/' : row[item.uuid])+" ")])]:[_vm._v(_vm._s(_vm.isNull(row[item.uuid]) ? '/' : row[item.uuid]))]]:(+item.type === 2)?[(Array.isArray(row[item.uuid]))?_c('el-image',{staticClass:"image",style:({
              height: (item.imgConfig.height || 60) + 'px',
              width: (item.imgConfig.height || 60) + 'px',
            }),attrs:{"src":row[item.uuid] &&
              row[item.uuid].length &&
              (row[item.uuid][0].thumb_path||row[item.uuid][0].path)?.toString()?.indexOf?.('http') > -1
                ? row[item.uuid][0].thumb_path||row[item.uuid][0].path
                : _vm.defaultImage,"previewSrcList":_vm.getImageList(row[item.uuid]),"fit":"cover","alt":"图片"}}):_c('el-image',{staticClass:"image",style:({
              height: (item.imgConfig.height || 60) + 'px',
              width: (item.imgConfig.height || 60) + 'px',
            }),attrs:{"src":row[item.uuid] && row[item.uuid]?.indexOf?.('http') > -1
                ? row[item.uuid]
                : _vm.defaultImage,"previewSrcList":[
              row[item.uuid]
                ? row[item.uuid]?.toString().replace('_thumb', '')
                : _vm.defaultImage,
            ],"fit":"cover","alt":"图片"}})]:(+item.type === 3 && !_vm.isNull(row[item.uuid]))?[(!item.statusStyle)?_c('span',{style:({
              color:
                _vm.filterStatus(row[item.uuid], item.statusOptions).color ||
                item.color,
            })},[_vm._v(" "+_vm._s(_vm.filterStatus(row[item.uuid], item.statusOptions).label)+" ")]):(item.statusStyle === 'block')?_c('span',{staticClass:"status-block",style:({
              background:
                _vm.filterStatus(row[item.uuid], item.statusOptions).color ||
                item.color,
            })},[_vm._v(" "+_vm._s(_vm.filterStatus(row[item.uuid], item.statusOptions).label)+" ")]):(item.statusStyle === 'point')?_c('span',{staticClass:"status-point"},[_c('span',{staticClass:"point",style:({
                background:
                  _vm.filterStatus(row[item.uuid], item.statusOptions).color ||
                  item.color,
              })}),_c('span',{style:({
                color:
                  _vm.filterStatus(row[item.uuid], item.statusOptions).color ||
                  item.color,
              })},[_vm._v(_vm._s(_vm.filterStatus(row[item.uuid], item.statusOptions).label))])]):_vm._e()]:(+item.type === 4 && !_vm.isNull(row[item.uuid]))?[_vm._v(" "+_vm._s(_vm.filterTime(row[item.uuid], item.timeFormat, item.connector) || '/')+" ")]:(+item.type === 5 && !_vm.isNull(row[item.uuid]))?[_c('div',{staticClass:"rich-text",domProps:{"innerHTML":_vm._s(row[item.uuid])}})]:(+item.type === 6 && !_vm.isNull(row[item.uuid]))?[_c('a',{staticClass:"link",attrs:{"href":row[item.uuid],"alt":"链接"}},[_vm._v(" "+_vm._s(row[item.uuid])+" ")])]:(+item.type === 7)?[_c('el-switch',{on:{"change":function($event){return _vm.onSwitch(item, row[item.uuid], row, false)}},model:{value:(row[item.uuid]),callback:function ($$v) {_vm.$set(row, item.uuid, $$v)},expression:"row[item.uuid]"}})]:(+item.type === 8)?[_vm._v(" "+_vm._s(_vm.filterNumber(row[item.uuid], item.numberOptions) || '/')+" ")]:(+item.type === 9)?[_vm._v(" "+_vm._s(_vm.getPositionName(row[item.uuid]))+" ")]:[_vm._v(_vm._s(_vm.isNull(row[item.uuid]) ? '/' : row[item.uuid]))]],2)}}],null,true)}):_vm._e()}),(_vm.actionConfig && _vm.actionConfig.needAction)?_c('el-table-column',{key:"actions",attrs:{"width":_vm.actionConfig.width || '',"align":_vm.statusConfig.align,"header-align":_vm.statusConfig.headerAlign,"label":"操作","fixed":_vm.fixColumns.includes('T') ? 'right' : false},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _vm._l((_vm.actionList),function(action,index){return _c('span',{key:index},[(
              action &&
              typeof action === 'object' &&
              action.use &&
              _vm.logicAction(action, scope.row) &&
              _vm.judgingArchiAPermission(
                action && action.archiLimit,
                action.permission
              ) &&
              _vm.judgingUILogic(action.UILogic, _vm.subComponentData)
            )?_c('el-button',{style:({
              color: action.color,
              marginLeft: action.margin + 'px',
            }),attrs:{"type":action.btnType,"size":action.size},on:{"click":function($event){return _vm.onAction(action.type, scope.row, $event, 'main', _vm.actionConfig)}}},[_vm._v(_vm._s(action.name))]):_vm._e()],1)})}}],null,false,2701069908)}):_vm._e()],2):_vm._e(),(_vm.statusConfig.pagination)?_c('el-pagination',{attrs:{"current-page":+_vm.pager.current_page,"page-sizes":_vm.pageSizes,"page-size":+_vm.pager.per_page,"layout":"total, sizes, prev, pager, next, jumper","total":+_vm.pager.total},on:{"size-change":_vm.onSize,"current-change":_vm.onPage}}):_vm._e(),(_vm.showSheet && _vm.sheetFormData)?_c('Spreadsheet',{attrs:{"type":_vm.spreadsheetType,"excelUuid":_vm.exportConfig.exportTemplate,"objectUuid":_vm.exportConfig.interfaceUrl || _vm.exportConfig.objectUUID,"viewUuid":_vm.exportConfig.viewUUID,"dataIds":_vm.sheetFormData,"exportType":_vm.exportConfig.exportTypeSheet || 'pdf',"viewSearch":_vm.exportViewSearch,"viewQuery":_vm.exportViewParams,"extraParams":_vm.exportParam || {},"solveType":_vm.TYPEMAP[_vm.exportConfig.exportRequest] || 'form'},on:{"exportSuccess":_vm.onExportSuccess,"exportError":_vm.onExportError}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }