<!--
 * @Description: 404页面
 * @Author: luocheng
 * @Date: 2021-10-20 16:39:12
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-12-18 15:47:46
-->
<template>
  <div class="no-page">
    <el-empty :description="description" :image-size="260">
      <el-button type="text" @click="onIndex">返回首页</el-button>
    </el-empty>
  </div>
</template>

<script>
import { Empty } from 'element-ui'
import { getHomePath } from '@/utils/tools';
export default {
  name: 'NoPage',
  components: {
    'el-empty': Empty
  },
  data() {
    return {
      description: '页面丢失或已失效!'
    }
  },
  methods: {
    /**
     * @desc: 返回首页
     */
    onIndex() {
      const routeObj = sessionStorage.getItem('indexPath')
      this.$router.replace(routeObj ? JSON.parse(routeObj) : {
        path: getHomePath()
      });
    }
  }
}
</script>

<style lang="less" scoped>
.no-page{
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .el-empty{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
}
</style>