/*
 * @Description: 登录
 * @Author: luocheng
 * @Date: 2022-03-21 15:41:38
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-12-18 15:50:34
 */
import { dataInterface } from '@/apis/data/index';
import { setToken, setPermissionObj, openUrl, getHomePath } from '@/utils/tools';
import JsEncrypt from 'jsencrypt';

export default {
  props: {
    scaleHeight: {
      type: Number,
      default: 1
    },
    scaleWidth: {
      type: Number,
      default: 1
    },
    ratio: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      systemConfig: {},
      errorTimes: 1
    };
  },
  created() {
    try {
      this.systemConfig = JSON.parse(localStorage.getItem('systemConfig'));
    } catch (err) {
      console.log(err, '----err------');
    }
  },
  methods: {
    /**
     * @desc: 执行登录
     * @param {Object} payload 参数
     */
    doLogin(payload) {
      const key = '-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDAnjv69DQZgDq+S0DqHu8bRF9uwxmJdHuiBqvsZ8dLb2i37+T15JzCfYjRf6rtsXsNZ5MHC5FMeOZnoslrr6iGu6fFdmG8GBp2ZOQln0plFIV/sIxG6KSeXPnYhPYCYaz4Pz5HNh/KJL0VSphV6SEmDc6pl/Vhyhbd85MufUetMQIDAQAB-----END PUBLIC KEY-----';
      const jsonstr = JSON.stringify({payload});
      const Encrypt = new JsEncrypt();
      Encrypt.setPublicKey(key);
      const encryption_str = Encrypt.encrypt(jsonstr);

      dataInterface({
        __method_name__: 'globalFunctionCall',
        typeName: 'LoginFuncClass',
        type: 'behavior',
        funcName: 'LoginBehavior',
        // payload,
        encryption_str
      })
        .then(async res => {
          if (!res || res.status !== 200 || !res.data || res.data.code !== 200 || !res.data.data) {
            this.errorTimes++
            this.$loading().close();
            this.$message.error(res.data.msg || '登录失败!');
            return;
          }
          this.errorTimes = 0
          this.$store.commit('getHistoryArchi', true);
          // 区分页面编辑器和正式环境
          const path = window.location.href;
          if (path.includes('/modify-page?') || path.includes('/page-view?')) {
            this.$nextTick(() => {
              this.$message.info('编辑器环境不做后续操作，请前往dev或线上环境测试效果!');
              this.$loading().close();
            });
            return false;
          }
          const userInfo = res.data.data;
          let roleNames = [];
					if (Array.isArray(userInfo?.roles) && userInfo?.roles.length) {
						userInfo?.roles.forEach(ele => {
							if (ele?.name) {
								roleNames.push(ele.name);
							}
						});
					}
					localStorage.setItem('userInfo', JSON.stringify({
						...userInfo,
						roleName: roleNames.join()
					}));
          setToken(userInfo.token || '');
          // 首页
          const routeObj = {
						path: getHomePath(),
						query: {
							pageId: '',
							pageUUID: ''
						}
					};
          sessionStorage.setItem('indexPath', JSON.stringify(routeObj));
          localStorage.setItem('updatePwd', userInfo.is_update_pwd);
          const systemConfig = JSON.parse(localStorage.getItem('systemConfig'));
          if (userInfo.is_update_pwd === 0 && systemConfig?.force_password) {
            this.$router.replace({
              name: 'PedestalUserCenter',
              query: {
                isForcedPassword: 1
              }
            });
          } else {
            // 应用体系
            if (!systemConfig?.need_second && systemConfig?.custom_route) {
              // 跳转自定义页面
              const toggleResult = await this.getCurrentArchi();
              if (!toggleResult) {
                this.$message.error('获取系统基本信息失败！');
                return false;
              }
              // 跳转到指定页面或首页
              this.setCustomConfig(systemConfig?.custom_route);
            } else {
              const toggleResult = await this.getCurrentArchi();
              if (!toggleResult) {
                this.$message.error('获取系统基本信息失败！');
                return false;
              }
              // 跳转二屏
              this.$router.replace({
                name: 'SecondScreen'
              });
            }
          }
          this.$message.success('登录成功!');
          this.$loading().close();
        })
        .catch(err => {
          console.log(err, '----err');
          this.$loading().close();
          this.$message.error('登录失败!');
        });
    },
    /**
     * @desc: 获取自定义入口配置
     */
    setCustomConfig(customRoute) {
      // 获取需要跳转的应用以及路由参数
      let routeObj = null;
      let targetApp = null;
      const appList = JSON.parse(sessionStorage.getItem('appList'));
      for (let i = 0; i < appList.length; i++) {
        const app = appList[i];
        routeObj = this.getRouteObj(customRoute, app?.app_orign_data);
        if (routeObj) {
          targetApp = app;
          break;
        }
      }
      if (!routeObj || !targetApp) {
        this.$message.error('获取系统配置失败！');
        return;
      }
      // 不同层级的路由需要获取不同的跳转路径
      // 0 编辑器配置页面，1 外链， 2 功能组， 3 (弃用)， 4 跳转路由(一般为微应用或基座内置页面@曾伟)
      const { menu_type } = routeObj;
      if (menu_type === 0 || menu_type === 4) {
        // 配置页面和跳转路由
        this.goRoute(routeObj);
      } else if (menu_type === 1) {
        // 外链
        this.goRoute(routeObj);
      } else if (menu_type === 2) {
        // 功能组
        // 跳转第一个有权限的路由
        const { children_list = [] } = routeObj;
        const indexObj = this.getGroupIndex(children_list);
        if (!indexObj) {
          this.$message.warning('无效路径');
          return;
        }
        this.goRoute(indexObj);
      }
      // 设置当前应用
      this.$store.commit('setCurrentApp', targetApp);
    },
    /**
     * @desc: 跳转路由
     * @param {Object} routeObj 路由对象
     */
    goRoute(routeObj) {
      const { menu_type, menu_path, page_uuid } = routeObj;
      if (menu_type === 0 || menu_type === 4) {
        // 配置页面和跳转路由
        this.$router.push({
          path: menu_path,
          query: {
            menu_type: menu_type || 0,
            pageUUID: page_uuid
          }
        });
      } else if (menu_type === 1) {
        // 外链
        const { link_type, link } = routeObj;
        if (link_type === '_blank') {
          // window.open(link);
          openUrl(link,link)
        } else if (link_type === '_self') {
          window.location.href = link;
        } else {
          // window.open(link);
          openUrl(link,link)
        }
      } else {
        this.$message.warning('无效路径');
      }
    },
    /**
     * @desc: 根据配置的路由地址获取完整路由对象
     * @param {String} path 路径
     * @param {Array} menuList 菜单列表
     */
    getRouteObj(path, menuList) {
      if (!path || !Array.isArray(menuList)) return null;
      let result = menuList.find(ele => ele.menu_path === path);
      if (result) return result;
      for (let i = 0; i < menuList.length; i++) {
        const children = menuList[i]?.children_list || [];
        if (children.length) {
          result = this.getRouteObj(path, children);
          if (result) {
            return result;
          }
        }
      }
      return null;
    },
    /**
     * @desc: 获取可用组织架构
     */
    getCurrentArchi() {
      return new Promise((resolve, reject) => {
        dataInterface({
          __method_name__: 'globalFunctionCall',
          typeName: 'Archi',
          type: 'value',
          funcName: 'AllArchi'
        })
          .then(async res => {
            if (!res || res.status !== 200) {
              this.$message.error('获取组织架构失败！');
              resolve(false);
              return;
            }
            const { company = [], project = [] } = res.data.data;
            if (!company.length && !project.length) {
              // 跳转到页面
              this.$router.push({
                name: 'PageError'
              });
              this.$loading().close();
              return;
            }
            localStorage.setItem('myCompany', JSON.stringify(company));
            localStorage.setItem('myProjects', JSON.stringify(project));
            const archiList = company.concat(project);
            let targetArchi = company?.[0] || project?.[0] || null;
            if (archiList.length) {
              for (let i = 0; i < archiList.length; i++) {
                if (archiList[i].last_login_date) {
                  if (!targetArchi) {
                    targetArchi = archiList[i];
                  } else {
                    try {
                      const beginData = new Date(archiList[i].last_login_date.replace('-', '/'));
                      const endData = new Date(targetArchi.last_login_date.replace('-', '/'));
                      if (beginData - endData > 0) {
                        targetArchi = archiList[i];
                      }
                    } catch (err) {
                      console.log(err);
                    }
                  }
                }
              }
            }
            if (!targetArchi) {
              // 跳转到页面
              this.$router.push({
                name: 'PageError'
              });
              this.$loading().close();
              return;
            }
            targetArchi = {
              ...targetArchi,
              archiId: targetArchi.archi_id,
              archiType: targetArchi.id.indexOf('companys') > -1 ? 'company' : 'project',
              id: targetArchi.id,
              modelApproval: ['https://bim.xwjgpt.com/'].includes(process.env.VUE_APP_V3_HOST) ? true : false,
              company_id: targetArchi.company_id || ''
            };
            // 切换架构操作
            await this.onToggleArchi(targetArchi.id);
            this.$store.commit('setCacheArchi', targetArchi);
            // console.log('setTargetArchi---vvvvvvvvvvvv')
            // 此架构信息有问题（！！！）
            // this.$store.commit('setTargetArchi', {targetArchi, a: 3});
            window.localStorage.setItem('currentProjectInfo', JSON.stringify(targetArchi));
            resolve(true);
          })
          .catch(err => {
            console.log(err);
            reject(false);
          });
      });
    },
    /**
     * @desc: 获取组织架构的信息（请求架构列表的信息有问题，需要用此函数请求架构数据）
     */
    onToggleArchi(id) {
      return new Promise((resolve, reject) => {
        dataInterface({
          __method_name__: 'globalFunctionCall',
          typeName: 'LoginFuncClass',
          type: 'behavior',
          funcName: 'ToggleArchBehavior',
          payload: {
            __external_company_type: localStorage.getItem('userType'),
            archi_id: id,
            archi_table: id.indexOf('companys') > -1 ? 'org_companys' : 'org_projects'
          }
        })
          .then(res => {
            if (!res || res.status !== 200) {
              resolve(false);
              return false;
            }
            const targetArchi = {
              ...res.data.data,
              archiId: res.data.data.archi_id,
              archiType: id.indexOf('companys') > -1 ? 'company' : 'project',
              id: res.data.data.data_id,
              company_id: res.data.data.data.company_id || '',
              modelApproval: ['https://bim.xwjgpt.com/'].includes(process.env.VUE_APP_V3_HOST) ? true : false
            };
            console.log('setTargetArchi---3333', targetArchi, process.env.VUE_APP_V3_HOST);
            this.$store.commit('setTargetArchi', {targetArchi, a: 4});
            window.localStorage.setItem('currentProjectInfo', JSON.stringify(targetArchi));
            localStorage.setItem('userRoles', res.data.data.permissions || '');
            setPermissionObj(res.data.data.permissions || '');
            if (res?.data?.data?.userInfo) {
              const userInfo = res?.data?.data?.userInfo || {};
              let roleNames = [];
              if (Array.isArray(userInfo?.roles) && userInfo?.roles.length) {
                userInfo?.roles.forEach(ele => {
                  if (ele?.name) {
                    roleNames.push(ele.name);
                  }
                });
              }
              localStorage.setItem('userInfo', JSON.stringify({
                ...userInfo,
                roleName: roleNames.join()
              }));
            }
            resolve(true);
          })
          .catch(err => {
            reject(false);
            console.log(err);
          });
      });
    }
  }
};
