<!--root
 * @Author: your name
 * @Date: 2021-12-02 15:32:01
 * @LastEditTime: 2023-03-06 10:42:32
 * @LastEditors: Shiltin 18580045074@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE /
 * @FilePath: \nanhu-micro\src\views\components\bindModel\index.vue
-->
<template>
	<div class="group-cont">
    <div class="top-handle">
      <div>组列表</div>
      <!-- <i class="iconfont iconxinzeng5" @click="onAddGroup"></i> -->
    </div>
    <el-input
      placeholder="请输入内容"
      @input="onFilterData"
      v-model="searchVal">
      <i slot="prefix" class="el-input__icon el-icon-search" @click="onFilterData(searchVal)"></i>
    </el-input>
		<div class="main-list">
      <div v-for="(item,index) in listData" :key="index" @click="onTabDevice(item)" class="list-item" :class="getStatus(item)">
        <i class="iconfont iconduijiang" ></i>
        <div class="group-name">{{ item.device_name || '未命名' }}</div>
      </div>
    </div>
	</div>	
</template>

<script setup>
/* eslint-disable */
import { ref, watch, computed } from 'vue';
const emits = defineEmits(['on-change','on-add-group']);
const props = defineProps({
  groupData:{
    type:Array,
    default: () => [],
  },
  onlineDevices:{
    type:Array,
    default: () => [],
  },
})
const listData = ref([]);
const activeId = ref(0);
const searchVal = ref('');
const getStatus = computed(()=>{
  return function(item){
    const len = props.onlineDevices.filter(v=>v.device_number === item.device_number).length
    const str = len !== 0?'online':'offline'
    return `${str} ${activeId.value === item.id?'active':''}`
  }
  
})
const onFilterData = (str) => {
  listData.value  = props.groupData.filter(v => v.device_name.includes(str));
}
const onTabDevice = (item) => {
  activeId.value = item.id;
  emits('on-change',item)
}
watch(()=>props.groupData,(val)=>{
  listData.value = val;
  },{
    immediate:true
  }
)
</script>
<style scoped lang="less">
	.group-cont{
		width: 240px;
    padding: 16px;
    box-sizing: border-box;
		height: 100%;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		background: #fff;
    .top-handle{
      height: 24px;
      line-height: 24px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 8px;
      color: var(--text-on-surface-primary, #181B22);
      font-family: "Source Han Sans CN";
      font-size: 16px;
      font-weight: 500;
    }
    .main-list{
      flex: 1;
      margin-top: 8px;
      overflow-y: auto;
      .list-item{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 42px;
        box-sizing: border-box;
        padding:5px 8px;
        border-radius: 8px;
        margin-bottom: 8px;
        i{
          line-height: 32px;
          font-size: 20px;
          margin-right: 8px;
          color:#2fa45a;
        }
        .group-name{
          flex: 1;
          text-align: left;
          line-height: 32px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: var(--text-on-surface-primary, #181B22);
          font-family: "Source Han Sans CN";
          font-size: 16px;
          font-weight: 400;
        }
        &:hover{
          background: #f6f6f6;
        }
      }
      .active{
        background: var(--overall-surface-alt-2, #D1E3FF);
        &:hover{
          background: var(--overall-surface-alt-2, #D1E3FF)!important;
        }
      }
      .offline{
          i{
            color:#a4acbd;
          }
        }
    }
	}
</style>
