<!--
 * @Author: your name
 * @Date: 2021-12-17 14:02:47
 * @LastEditTime: 2024-12-27 16:04:51
 * @LastEditors: 冉桂精 156189868@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \dataview-viewer-test\src\components\fileUpload\newFiles\index.vue
-->
<template>
  <div v-loading="uploadLoading" class="file-upload">
    <el-upload
      v-if="!disabled"
      :action="uploadURL"
      :before-upload="beforeUpload"
      :class="{
        'upload-drag-img': drag && isImgUpload,
        'upload-style': true,
      }"
      :data="uploadParams"
      :multiple="false"
      :on-success="onSuccess"
      :on-change="getModelFile"
      :show-file-list="uploading"
      :drag="drag"
      :headers="{
        metadatatoken: '4fb0b638-2c43-4d7e-8acf-f1a7abdee58easdfasdfqwerqwer',
      }"
      :accept="fileAccept"
    >
      <div ref="uploadRef" :style="drag ? '' : 'text-align: left'">
        <template v-if="!drag">
          <div class="btn-container">
            <el-button :icon="icon" type="default">
              <i class="iconfont iconshangchuan1" style="font-size: 16px"></i>
              {{ btnName }}
            </el-button>
            <el-popover
              v-model="showTip"
              v-if="fileTypeListDesc?.length || fileTypeList.length"
            >
              <div>
                <p class="list-title">文件要求</p>
                <ul class="desc-list">
                  <li v-if="fileTypeList.length">
                    仅支持
                    {{ fileTypeList.join(',') }}
                    格式文件上传
                  </li>
                  <template
                    v-if="
                      fileTypeListDesc?.split(',')?.filter((i) => i)?.length
                    "
                  >
                    <li
                      v-for="(item, index) in fileTypeListDesc
                        ?.split(',')
                        ?.filter((i) => i)"
                      :key="index"
                    >
                      {{ item }};
                    </li></template
                  >
                </ul>
              </div>
              <div class="tooltip" slot="reference" @click.stop>
                <i class="iconfont icona-icon4" style="font-size: 16px"></i>
                格式要求
              </div>
            </el-popover>
          </div>
        </template>
        <template v-else>
          <div v-if="!isImgUpload" class="drag-img">
            <div class="drag-area">
              <img
                class="upload-img"
                src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/1d4b6a9db9ddf6e1c91f6566e79ccf34.png"
              />
              <p class="tip">
                拖拽上传或
                <span>点击上传</span>
              </p>
              <p class="desc" style="line-height: 24px">
                <span v-if="fileTypeList.length > 0">
                  &nbsp;仅支持
                  <span>{{ fileTypeList.join('，') }}</span>
                  格式文件上传{{ fileTypeListDesc.length ? '，' : '' }}</span
                >
                <span> {{ fileTypeListDesc }}</span>
              </p>
            </div>
          </div>
          <div v-if="isImgUpload" class="drag-img">
            <div class="drag-area">
              <img
                class="upload-img"
                src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/0ee6bb2393d7b1c8c6e0c6d2b28d15b5.png"
              />
              <div class="tip">拖拽图片或点击上传</div>
            </div>
            <div
              :class="{ 'drag-area': true, copy: true, active: active }"
              ref="copyRef"
              @mouseover="copyImg"
              @mouseout="removeListener"
              @click.stop="preventDefault"
            >
              <img
                class="upload-img"
                src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/abaa36c806dcd3398cfe24a622c6c2d4.png"
              />
              <div class="tip">点击此处粘贴 (Ctrl+V) 截图</div>
              <input class="copy-input" maxlength="0" @click="copyImg" />
            </div>
          </div>
          <div v-if="isImgUpload" class="image-desc">
            &nbsp;仅支持
            <span>{{ fileTypeList.join('，') }}</span>
            格式文件上传{{ fileTypeListDesc.length ? '，' : '' }}
            <span> {{ fileTypeListDesc }}</span>
          </div>
        </template>
      </div>
    </el-upload>
    <div
      v-if="imageArr && imageArr.length"
      :class="{
        'upload-img-area': true,
        'simple-image-area':
          !isImgUpload || (Array.isArray(fileArr) && fileArr.length) || !drag,
      }"
    >
      <div
        class="upload-img-item"
        v-for="(item, index) in imageArr"
        :key="index"
      >
        <div
          class="image-item-left"
          :class="{ 'image-item-left': true, 'only-image': isImgUpload }"
          @click="handleView(index)"
        >
          <el-image
            v-if="onlyImgUrl"
            :src="item"
            :preview-src-list="previewList(index)"
            :style="{
              'pointer-events': canPreview && isImgUpload ? 'auto' : 'none',
              filter: canPreview ? ' blur(0)' : 'blur(80px)',
            }"
            style="width: 100%; height: 100%"
          />
          <el-image
            v-else
            :src="item.thumb_path"
            :preview-src-list="previewList(index)"
            :style="{
              'pointer-events': canPreview && isImgUpload ? 'auto' : 'none',
              filter: canPreview ? ' blur(0)' : 'blur(10px)',
            }"
            style="width: 100%; height: 100%"
          />
          <div
            v-if="
              !isImgUpload ||
              (Array.isArray(fileArr) && fileArr.length) ||
              !drag
            "
            class="text"
          >
            {{ item.filename }}
          </div>
        </div>

        <div v-if="!disabled && isImgUpload" class="del-image">
          <i
            class="iconfont iconshanchu"
            style="color: #fff; font-size: 18"
            @click.stop="handleRemoveImg(index)"
          />
        </div>
        <div v-if="!disabled && !isImgUpload" class="del-image">
          <span
            style="
              color: #ff0000;
              cursor: pointer;
              font-size: 12px;
              margin-right: -6px;
            "
            @click.stop="handleRemoveImg(index)"
            >删除</span
          >
        </div>
        <div
          v-if="
            !isImgUpload || (Array.isArray(fileArr) && fileArr.length) || !drag
          "
          class="image-size"
        >
          {{ getFileSize(item.filesize) }}
        </div>
      </div>
    </div>
    <div
      v-if="Array.isArray(fileArr) && fileArr.length"
      class="upload-files-area"
    >
      <div class="file-show">
        <div
          v-for="(item, index) in fileArr"
          :key="item.filepath"
          class="file-item"
        >
          <div class="file-item-left" @click="openFile(item)">
            <svg aria-hidden="true" class="iconfont-svg">
              <use :xlink:href="geticon(item.fileext)" />
            </svg>
            <div class="name">
              {{ getFileName(item.filename, item).name }}
            </div>
            <div class="type">
              {{ getFileName(item.filename, item).type }}
            </div>
          </div>
          <div class="file-item-right">
            <span
              class="file-item-view"
              v-if="canPreview"
              @click="openFile(item)"
            >
              预览
            </span>
            <span
              v-if="
                (!formMode || formMode !== 'add') &&
                onlyoffice &&
                [
                  'doc',
                  'docx',
                  'pdf',
                  'ppt',
                  'pptx',
                  'xlsx',
                  'xls',
                  'txt',
                ].indexOf(
                  item.path
                    .substring(item.path.lastIndexOf('.') + 1)
                    .toLowerCase()
                )
              "
              class="file-item-view"
              @click="editFile(item)"
            >
              编辑
            </span>
            <span
              v-if="canDownload"
              class="file-item-view"
              @click="downloadFile(item.filepath, item.name ?? item.filename)"
            >
              下载
            </span>
            <span
              v-if="disabled ? false : true"
              class="file-item-del"
              @click="handleRemove(index)"
            >
              删除
            </span>
          </div>
          <div class="file-item-size">
            {{ getFileSize(item.filesize) }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        disabled &&
        (!fileArr || fileArr.length <= 0) &&
        (!imageArr || imageArr.length <= 0)
      "
      class="not-uploaded"
    >
      未上传
    </div>
    <el-image-view
      v-if="dialogVisible && (!isImgUpload || !drag)"
      :onClose="onClose"
      :urlList="previewList(imgIndex)"
    />
    <div v-if="drawingShow" class="drawing-cont">
      <div :id="'viewerDom' + viewerId" style="width: 100%; height: 100%" />
      <i @click="closeDrawing" class="iconfont iconc-close"></i>
    </div>
  </div>
</template>
<script>
import { parseTime, getToken } from '@/utils/tools';
import { baseUrl } from '@/apis/http/request';
import { Image, Upload, Popover } from 'element-ui';
import elImageViewer from 'element-ui/packages/image/src/image-viewer.vue';
import { downloadFileBlob } from '@/utils/tools';
import axios from 'axios';

let viewer;
export default {
  props: {
    // 文件
    files: {
      type: Array,
      default() {
        return [];
      },
    },
    // 禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    // 按钮名
    btnName: {
      type: String,
      default: '上传文件',
    },
    // icon
    icon: {
      type: String,
      default: '',
    },
    // 是否多选
    multiple: {
      type: Boolean,
      default: true,
    },
    // 上传数量
    limit: {
      type: Number,
      default: 999,
    },
    // 允许上传的文件格式
    fileTypeList: {
      type: Array,
      default: () => [],
    },
    // 文件名超出最长长度后中间显示省略号
    nameMaxLength: {
      type: Number,
      default: 20,
    },
    // 拖拽上传
    drag: {
      type: Boolean,
      default: false,
    },
    // 仅图片地址数组(全路径)
    onlyImgUrl: {
      type: Boolean,
      default: false,
    },
    // 限制文件类型
    fileAccept: {
      type: String,
      required: false,
      default: '',
    },
    //上传类型
    goalType: {
      type: String,
      default: 'file',
    },
    // 文件预览编辑
    onlyoffice: {
      type: Boolean,
      default: false,
    },
    // 文件编辑使用uuid
    element: {
      type: Object,
      default: () => ({
        config: {
          __config__: {},
        },
      }),
    },
    // 文件编辑使用uuid
    objectUuid: {
      type: String,
      default: '',
    },
    // 详情id
    dataIds: {
      type: null,
      default: null,
    },
    // 表单类型
    formMode: {
      type: null,
      default: null,
    },
  },
  components: {
    'el-upload': Upload,
    'el-image': Image,
    'el-popover': Popover,
    'el-image-view': elImageViewer,
  },
  // emits: ['extra-files'],
  data() {
    return {
      extra_file: [],
      uploading: false,
      currentUser: '', //当前用户
      imageArr: [], // 图片数组
      fileArr: [], //文件数组
      url: baseUrl,
      dialogVisible: false,
      dialogImageUrl: '',
      uploadURL: `${baseUrl}api/mapi?__method_name__=file&token=${getToken()}`,
      token: getToken(),
      imageType: ['bmp', 'jpg', 'png', 'gif', 'jpeg', 'cdr', 'psd'],
      uploadLoading: false,
      viewerId: '',
      drawingShow: false,
      fileCount: 0, // 文件数量
      showTip: false,
      active: false,
      imgIndex: 0,
    };
  },
  computed: {
    // 上传数量限制
    uploadNumTotal() {
      if (!['image', 'file', 'default'].includes(this.goalType)) return 1;
      const fileNumType = this.element.config.__config__.fileNumType;
      const fileNum = this.element.config.__config__.fileNum;
      if (+fileNumType === 1) {
        // 不限制
        return null;
      } else {
        if (+fileNumType === 5) {
          return +fileNum;
        } else {
          const arr = [
            { label: 1, value: 2 },
            { label: 5, value: 3 },
            { label: 10, value: 4 },
          ];
          const res = arr.find((el) => +el.value === +fileNumType);
          return res?.label;
        }
      }
    },
    fileTypeListDesc() {
      let imageSizeDesc = '';
      let fileDesc = '';
      let fileNumDesc = '';
      let MAX_WIDTH = 0;
      let MAX_HEIGHT = 0;
      if (this.goalType === 'image' && this.fileTypeList.length === 0)
        return '';
      const fileSizeSelect = this.element.config.__config__.fileSizeSelect;
      const fileSize = this.element.config.__config__.fileSize; // 文件大小
      const sizeUnit = this.element.config.__config__.sizeUnit; // 文件大小单位
      const fileNumType = this.element.config.__config__.fileNumType;
      const imageSizeSelect = this.element.config.__config__.imageSizeSelect;
      const restrictionType = this.element.config.__config__.restrictionType;
      if (fileSizeSelect && fileSizeSelect !== 1 && fileSize) {
        // 文件大小
        if (+fileSizeSelect === 6) {
          fileDesc = `大小不超过${fileSize}${sizeUnit}`;
        } else {
          fileDesc = `大小不超过${fileSize}`;
        }
      }
      if (
        fileNumType &&
        fileNumType !== 1 &&
        +this.uploadNumTotal !== 0 &&
        this.element.config.__config__?.fileNum
      ) {
        // 文件数量
        fileNumDesc = `最多上传${this.uploadNumTotal}个文件`;
      }
      if (
        imageSizeSelect &&
        imageSizeSelect !== 1 &&
        this.goalType === 'image'
      ) {
        // 图片尺寸
        if (+imageSizeSelect === 7) {
          MAX_WIDTH = +this.element.config.__config__.widthLimitSize;
          MAX_HEIGHT = +this.element.config.__config__.heightLimitSize;
        } else {
          const imageSize = this.element.config.__config__.imageSize;
          const imageSizeArr = imageSize?.split('*');
          MAX_WIDTH = imageSizeArr?.[0];
          MAX_HEIGHT = imageSizeArr?.[1];
        }
        if (+MAX_WIDTH && +MAX_HEIGHT) {
          if (+imageSizeSelect !== 7 || +restrictionType === 1) {
            imageSizeDesc = `上传的图片尺寸为${MAX_WIDTH}*${MAX_HEIGHT}px, `;
          } else {
            imageSizeDesc = `上传的图片尺寸不超过${MAX_WIDTH}*${MAX_HEIGHT}px, `;
          }
        }
      }
      const arr = [imageSizeDesc, fileDesc, fileNumDesc].filter(
        (str) => str.trim() !== ''
      );
      return arr.join(',');
    },
    isImgUpload() {
      if (this.goalType !== 'image') {
        return false;
      } else {
        let num = 0;
        let otherNum = 0;
        const imgStr = '.bmp,.jpg,.png,.gif,.jpeg,.cdr,.psd';
        this.fileTypeList.forEach((item) => {
          if (imgStr.indexOf(item) !== -1) {
            num += 1;
          } else {
            otherNum += 1;
          }
        });
        if (num <= 7 && otherNum === 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    canPreview() {
      return (
        !('hasPreview' in this.element.config.__config__) ||
        this.element.config.__config__.hasPreview
      );
    },
    canDownload() {
      return (
        !('hasUpload' in this.element.config.__config__) ||
        this.element.config.__config__.hasUpload
      );
    },
    uploadParams() {
      let param = {
        pixelSize: this.canPreview ? 0 : 50,
        token: this.token,
      };
      //添加后端水印背景
      if(this.element?.config?.__config__?.isWatermark){
        const n = this.element.config.__config__.isWatermark;
        if(+n === 1){
          param.isWatermark = true;
        } else if(+n === 2){
          param.isWatermark = false;
        }
      }
      return param
    },
  },
  watch: {
    files: {
      handler(files) {
        if (this.onlyImgUrl) {
          this.imageArr = files;
        } else {
          this.imageArr = [];
          this.fileArr = [];
          if (files?.length) {
            files.map((item) => {
              if (typeof item === 'object') {
                if (
                  (item.filepath &&
                    this.imageType.indexOf(
                      item.filepath
                        .substring(item.filepath.lastIndexOf('.') + 1)
                        .toLowerCase()
                    ) > -1) ||
                  (item.fileext &&
                    this.imageType.indexOf(item.fileext.toLowerCase()) > -1) // TIPS 这里是为了解决视频组件截图路径带参数的问题
                ) {
                  this.imageArr.push(item);
                } else {
                  this.fileArr.push(item);
                }
              } else {
                this.imageArr.push({
                  name: '',
                  upUser: '未知',
                  upTime: parseTime(new Date()),
                  path: item,
                });
              }
            });
            this.extra_file = files;
          }
        }
      },
      immediate: true,
      deep: true,
    },
    extra_file: {
      handler(data) {
        this.loading = false;
        this.$emit('extra-files', data, this.index);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    onClose() {
      this.dialogVisible = false;
    },
    // 预览列表
    previewList(index) {
      if (!this.imageArr || !Array.isArray(this.imageArr)) return [];
      if (this.onlyImgUrl) return this.imageArr;
      const imgArr = this.imageArr.map((ele) => {
        const isThumb = ele.filepath.indexOf('_thumb') !== -1;
        let str = '';
        if (isThumb) {
          let path = ele.filepath?.split('_thumb');
          if (path[1] && path[1] !== 'undefined') {
            str = path[0] + '' + path[1];
          } else {
            str = path[0];
          }
        } else {
          str = this.canPreview
            ? ele.filepath
            : ele.pixelate_path || ele.filepath;
        }
        return str;
      });
      return index || index === 0 ? [imgArr[index]] : imgArr;
    },
    /**
     * @description 上传成功回调
     * @param {*} response
     * @param {*} file
     * @param {*} fileList
     */
    onSuccess(res) {
      this.uploading = false;
      if (!Array.isArray(this.extra_file)) {
        this.extra_file = [];
      }
      /* this.extra_file.push({
          name: file.name,
          upUser: this.currentUser,
          upTime: parseTime(new Date()),
          path: response.data.filepath,
        }) */

      this.uploading = false;
      if (this.extra_file == null) {
        this.extra_file = new Array();
      }
      //判断上传文件是否为图片
      res.data.upTime = parseTime(new Date());
      res.data.name = res.data.filename;
      if (this.imageType.indexOf(res.data.fileext.toLowerCase()) > -1) {
        res.data.path = res.data.thumb_path;
      } else {
        res.data.path = res.data.filepath;
      }
      this.extra_file.push(res.data);
    },
    /**
     * @description 选中文件回调
     */
    getModelFile(file) {
      if (!['image', 'file', 'schedule', 'default'].includes(this.goalType)) {
        this.$emit('extra-files', file);
        return false;
      }
    },
    /**
     * @description 上传前置钩子
     */
    beforeUpload(file) {
      return new Promise((resolve, reject) => {
        //需要单独处理的上传方式
        if (!['image', 'file', 'schedule', 'default'].includes(this.goalType)) {
          return reject();
        }
        this.uploading = true;
        if (this.fileTypeList.length > 0) {
          let arr = [];
          this.fileTypeList.forEach((item) => {
            if (file.name.indexOf(item) > -1) {
              arr.push(file.name);
            }
          });
          if (arr.length == 0) {
            this.$message.error(
              '请上传 ' + this.fileTypeList.join(',') + ' 格式的文件！'
            );
            return reject();
          }
        }
        const flag = this.fileSizeLimit(file.size);
        if (!flag) return reject();
        if (this.goalType === 'image') {
          this.imageSizeLimit(file)
            .then((res) => {
              if (res) {
                if (this.element.config.__config__.fileNumType === 1) {
                  return resolve();
                }
                if (this.files?.length + 1 > this.uploadNumTotal) {
                  this.$message.error(`最多上传${this.uploadNumTotal}个文件`);
                  return reject();
                }
                resolve();
              } else {
                reject();
              }
            })
            .catch(() => {
              reject();
            });
          return;
        }
        if (this.element.config.__config__.fileNumType === 1) {
          return resolve();
        }
        if (this.files?.length + 1 > this.uploadNumTotal) {
          this.$message.error(`最多上传${this.uploadNumTotal}个文件`);
          return reject();
        }
        return resolve();
      });
    },
    /**
     * @desc: 图片 尺寸限制
     */
    async imageSizeLimit(file) {
      const { width, height } = await this.getImageDimensions(file);
      let MAX_WIDTH = 0;
      let MAX_HEIGHT = 0;
      let compareTypes = 'equal'; // equal 相等 contain 小于等于
      const imageSizeSelect = this.element.config.__config__.imageSizeSelect;
      const restrictionType = this.element.config.__config__.restrictionType;
      const widthLimitSize = +this.element.config.__config__.widthLimitSize;
      const heightLimitSize = +this.element.config.__config__.heightLimitSize;
      const imageSize = this.element.config.__config__.imageSize;
      if (+imageSizeSelect !== 1 && imageSizeSelect !== undefined) {
        if (+imageSizeSelect === 7) {
          MAX_WIDTH = widthLimitSize;
          MAX_HEIGHT = heightLimitSize;
          compareTypes = +restrictionType === 1 ? 'equal' : 'contain';
        } else {
          const imageSizeArr = imageSize?.split('*');
          MAX_WIDTH = imageSizeArr?.[0];
          MAX_HEIGHT = imageSizeArr?.[1];
          compareTypes = 'equal';
        }
        if (compareTypes === 'contain') {
          if (+width > +MAX_WIDTH || +height > +MAX_HEIGHT) {
            this.$message.error(
              `上传的图片尺寸不能超过${MAX_WIDTH}*${MAX_HEIGHT}px`
            );
            return false;
          }
        } else {
          if (+width !== +MAX_WIDTH || +height !== +MAX_HEIGHT) {
            this.$message.error(
              `上传的图片尺寸不等于${MAX_WIDTH}*${MAX_HEIGHT}px`
            );
            return false;
          }
        }
      }
      return true;
    },
    getImageDimensions(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            resolve({ width: img.width, height: img.height });
          };
          img.onerror = reject;
          img.src = e.target.result;
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    /**
     * @desc: 文件大小限制
     * @param {*} val
     */
    fileSizeLimit(val) {
      // 转MB
      const size = val / 1024 / 1024;
      const curFileSize = size.toFixed(6);
      let targetFileSize = 0;
      let unit = '';
      const fileSize = this.element.config.__config__.fileSize;
      const fileSizeSelect = this.element.config.__config__.fileSizeSelect;
      const sizeUnit = this.element.config.__config__.sizeUnit;
      if (+fileSizeSelect !== 1) {
        if (+fileSizeSelect === 6) {
          unit = sizeUnit;
          if (unit === 'KB') {
            targetFileSize = fileSize / 1024;
          } else if (unit === 'GB') {
            targetFileSize = fileSize * 1024;
          } else if (unit === 'MB') {
            targetFileSize = fileSize;
          }
        } else {
          if (fileSize && fileSize.length) {
            targetFileSize = fileSize.match(/\d+/g);
            unit = 'MB';
          }
        }
        if (targetFileSize !== 0) {
          if (curFileSize > targetFileSize) {
            let message = '';
            if (+fileSizeSelect === 6) {
              message = `上传文件大小不能超过${fileSize}${sizeUnit}!`;
            } else {
              message = `上传文件大小不能超过${fileSize}!`;
            }
            this.$message.error({ message });
            return false;
          }
        }
      }
      return true;
    },
    /**
     * @description 查看图片
     * @param {Number} index
     */
    handleView(index) {
      const isThumb = this.imageArr[index].filepath.indexOf('_thumb') !== -1;
      this.imgIndex = index;
      if (!this.canPreview) return;
      let str = '';
      if (isThumb) {
        let path = this.imageArr[index].filepath?.split('_thumb');
        if (path[1] && path[1] !== 'undefined') {
          str = path[0] + '' + path[1];
        } else {
          str = path[0];
        }
      } else {
        str = this.imageArr[index].filepath;
      }
      this.dialogImageUrl = str;
      this.dialogVisible = true;
      // 暂时没有实现
      // let arr=[]
      // this.imageArr.map(item=>{
      //     arr.push(item.path)
      // })
      // this.$store.commit('setImageUrls', {data: arr,index :index})
    },
    /**
     * @description 查看文件预览
     * @param {Object} row
     */
    openFile(row) {
      if (!this.canPreview) return;
      if (
        ['bmp', 'jpg', 'png', 'gif', 'jpeg', 'cdr', 'psd'].indexOf(
          row.path.substring(row.path.lastIndexOf('.') + 1).toLowerCase()
        ) >= 0
      ) {
        // this.$store.commit('setImageUrls', { data: [row.path] })
        this.dialogImageUrl = row.path;
        this.dialogVisible = true;
      } else if (
        ['doc', 'docx', 'pdf', 'ppt', 'pptx', 'xlsx', 'xls', 'txt'].indexOf(
          row.path.substring(row.path.lastIndexOf('.') + 1).toLowerCase()
        ) >= 0
      ) {
        let url = 'https://weboffice.bimcc.net?furl=';
        if (row.path.includes('https://')) {
          url = 'https://weboffice.bimcc.net?ssl=1&furl=';
        }
        window.open(url + row.path);
      } else if (
        ['mp4'].indexOf(
          row.path.substring(row.path.lastIndexOf('.') + 1).toLowerCase()
        ) >= 0
      ) {
        window.open(row.path);
      } else if (
        ['dwg'].indexOf(
          row.path.substring(row.path.lastIndexOf('.') + 1).toLowerCase()
        ) >= 0
      ) {
        axios({
          method: 'post',
          url: baseUrl + 'api/model/auth/token',
          headers: {
            Bimcctoken: getToken(),
          },
        }).then((res) => {
          if (res.data?.data?.token) {
            axios({
              method: 'get',
              url:
                'https://models.bimcc.net/api/v3/model/tranlate?file_id=' +
                row.model_file_id,
              headers: {
                authorization: `Bearer ${res.data.data.token}`,
              },
            }).then((re) => {
              if (re.data.data?.progress === 'Finish') {
                let modelId = '';
                if (
                  re.data?.data?.data?.f2d &&
                  re.data.data.data.f2d.length !== 0
                ) {
                  this.viewerId = row.id;
                  modelId = re.data.data.data.f2d[0].model_id;
                  this.drawingShow = true;
                  setTimeout(() => {
                    const appId = 'bdafce12a2d9465d89821ec14214a382';
                    const appSecret = '3EYcdAjbnWxP';
                    viewer = new window.BIMCC.Viewer('viewerDom' + row.id, {});
                    viewer.clearUI();
                    viewer.init(modelId, {
                      appId,
                      appSecret,
                      UIType: 'UI.Base',
                    });
                  }, 100);
                }
              } else {
                this.$message.info('dwg文件转换中,请稍后再试！');
              }
            });
          } else {
            this.$message.info('请稍后再试！');
          }
        });
      } else {
        this.$message.info('无法打开此格式的文件！');
      }
    },
    /**
     * @description 编辑文件
     * @param {row} 文件对象
     */
    editFile(row) {
      console.log(
        `${this.url}onlyOffice/edit?objectUuid=${this.objectUuid}&fieldUuid=${this.element.field}&dataId=${this.dataIds}&fileId=${row.id}`,
        'url'
      );
      window.open(
        `${this.url}onlyOffice/edit?objectUuid=${this.objectUuid}&fieldUuid=${this.element.field}&dataId=${this.dataIds}&fileId=${row.id}`
      );
    },
    /**
     * @description 移除图片
     * @param {Number} index
     */
    handleRemoveImg(index) {
      this.imageArr.splice(index, 1);
      this.extra_file = [].concat(this.imageArr, this.fileArr);
    },
    /**
     * @description 移除文件
     * @param {Number} index
     */
    handleRemove(index) {
      this.fileArr.splice(index, 1);
      this.extra_file = [].concat(this.imageArr, this.fileArr);
    },
    /**
     * @description 文件名字截取
     * @param {String} name
     */
    getFileName(name, item) {
      if (name?.includes('.')) {
        let type = '.' + name?.split('.')[name?.split('.')?.length - 1];
        const title = name.substring(0, name.length - type.length - 1);
        type = name[title.length] + type;
        return {
          type,
          name: title,
        };
      } else {
        return {
          type: '.' + item.filetype,
          name: name,
        };
      }
    },
    /**
     * @description 文件大小显示
     * @param {Number} fileSize
     */
    getFileSize(fileSize) {
      if (fileSize < 1024) {
        return fileSize + 'B';
      } else if (fileSize < 1024 * 1024) {
        let temp = fileSize / 1024;
        temp = temp.toFixed(2);
        return temp + 'KB';
      } else if (fileSize < 1024 * 1024 * 1024) {
        let temp = fileSize / (1024 * 1024);
        temp = temp.toFixed(2);
        return temp + 'MB';
      } else {
        let temp = fileSize / (1024 * 1024 * 1024);
        temp = temp.toFixed(2);
        return temp + 'GB';
      }
    },
    /**
     * @description 文件下载
     * @param {String, String} path, name
     */
    async downloadFile(url, name) {
      this.uploadLoading = true;
      await downloadFileBlob(url, name);
      this.uploadLoading = false;
    },
    /**
     * @description 关闭图纸预览
     */
    closeDrawing() {
      this.drawingShow = false;
      viewer = null;
    },
    preventDefault(e) {
      this.active = true;
      e.preventDefault();
      e.stopPropagation();
    },
    copyImg() {
      this.$refs.copyRef.addEventListener('paste', this.getClipboardFiles);
    },
    removeListener() {
      this.$refs.copyRef.removeEventListener(
        'paste',
        this.getClipboardFiles,
        false
      );
    },

    getClipboardFiles(event) {
      let items = event.clipboardData && event.clipboardData.items;
      let file = null;
      if (items && items.length) {
        // 检索剪切板items
        for (var i = 0; i < items.length; i++) {
          if (items[i].type.indexOf('image') !== -1) {
            file = items[i].getAsFile();
          }
        }
      }
      if (file?.type?.indexOf('image') === -1) {
        this.$message.error('粘贴内容不是图片');
      } else {
        if (this.fileTypeList.indexOf('.' + file?.type?.split('/')[1]) === -1) {
          this.$message.error('限制此类型图片上传！');
          return;
        }
        this.handleFilesAdd(file);
      }
    },
    handleFilesAdd(file) {
      const formData = new FormData();
      formData.append('file', file);
      axios({
        method: 'post',
        url: this.uploadURL,
        data: formData,
      }).then((res) => {
        if (res.status === 200) {
          this.onSuccess(res.data);
        } else {
          this.$message.error('上传截图失败！');
        }
      });
    },
    /**
     * @description: 获取ext对应的icon
     * @param {String} ext 文件ext
     * @return {String} icon
     */
    geticon(ext) {
      const iconHash = {
        icontxt: ['text', 'txt'],
        iconcaogaowenjian: ['draft'],
        'icona-dianzishuwenben': ['epub'],
        iconmoxing3: ['svfzip', 'rvt', 'dwg'],
        iconbiaoge: ['xls', 'xlsx'],
        iconwenjianjia3: ['folder'],
        iconppt: ['ppt', 'pptx'],
        iconpdf: ['pdf'],
        icontupianzhaopian: ['bmp', 'jpg', 'png', 'gif', 'jpeg', 'cdr', 'psd'],
        'icona-wenjianwendang': ['doc', 'docx', 'md'],
        iconweizhiwenjian: [],
        iconyasuobao: ['zip', 'rar'],
        iconyinpin: ['mp3', 'wav'],
        iconshipin1: ['mp4'],
      };
      let icon = 'iconweizhiwenjian';
      for (const key in iconHash) {
        if (Object.hasOwnProperty.call(iconHash, key)) {
          const element = iconHash[key];
          if (element.findIndex((el) => el === ext) > -1) {
            icon = key;
            break;
          }
        }
      }
      return `#${icon}`;
    },
  },
};
</script>

<style lang="less" scoped>
.file-upload {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  .upload-style {
    height: auto;
    .drag-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      p {
        white-space: nowrap;
      }
    }
    .btn-container {
      display: flex;
      gap: 12px;
      .el-button {
        border-radius: 6px;
        height: 32px;
        padding: 0 20px;
      }
      .tooltip {
        border-radius: 6px;
        display: flex;
        height: 32px;
        padding: 0 8px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        &:hover {
          background: #f0f1f4;
        }
      }
    }
    .drag-img {
      display: flex;
      justify-content: flex-start;
      .drag-area {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 92px;
        padding: 12px 24px;
        box-sizing: border-box;
        border-radius: 5px;
        border: 1.5px dashed #e1e4ec;
        &:hover {
          border: 1px dashed #087aff;
        }
        .upload-img {
          width: 20px;
          height: 20px;
        }
        .tip {
          color: #181b22;
          font-family: PingFang SC;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          text-align: center;
          span {
            color: #387ffc;
          }
        }
        .desc {
          color: #707786;
          font-family: PingFang SC;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          text-align: center;
        }
      }
      .drag-img-item {
        width: 104px;
        height: 104px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #d7dae0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 16px;
        position: relative;
        i {
          width: 32px;
          height: 32px;
          font-size: 32px;
          color: #d7dae0;
        }
        p {
          line-height: 18px;
          font-size: 12px;
          color: #6d768f;
        }
      }
    }
    :deep(.el-upload) {
      width: 100%;
      .el-upload-dragger {
        width: 100%;
        height: 92px;
        border: none;
      }
    }
  }
  .upload-drag-img {
    .drag-img {
      display: flex;
      gap: 16px;
      .drag-area {
        display: flex;
        width: 88px;
        height: 88px;
        padding: 16px 13px;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        border: none;
        box-sizing: border-box;
        border: 1px dashed #f0f1f4;
        border-radius: 6px;
        &:hover {
          border: 1px dashed #087aff;
        }
        .tip {
          color: #707786;
          text-align: center;
          font-family: 'PingFang SC';
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0.4px;
        }
      }
      .copy {
        padding: 16px 4px;
        position: relative;
        &:focus {
          border: 1px dashed #087aff;
        }
        .copy-input {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 88px;
          height: 88px;
          outline: none;
          border: none;
          background: transparent;
          text-align: center;
          caret-color: transparent;
          color: transparent;
          text-shadow: 0px0px0px#333;
          cursor: pointer;
        }
      }
    }
    .image-desc {
      color: #707786;
      font-family: 'PingFang SC';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
      letter-spacing: 0.4px;
      text-align: start;
      margin-top: 8px;
    }
    :deep(.el-upload) {
      width: 100%;
      .el-upload-dragger {
        width: 100%;
        height: auto;
        border: none !important;
      }
    }
  }
  .upload-img-area {
    display: flex;
    gap: 8px;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
    .upload-img-item {
      display: inline-block;
      width: 88px;
      height: 88px;
      text-align: center;
      line-height: 48px;
      border: 1px solid transparent;
      border-radius: 5px;
      overflow: hidden;
      background: #fff;
      position: relative;
      border: 1px solid #0000000f;
      cursor: pointer;
      flex-shrink: 0;
      .only-image {
        width: 100%;
        height: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
      }
      &:hover .del-image {
        display: inline-block;
      }
      .del-image {
        display: none;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        background: #d3d4d5;
        color: #fff;
        line-height: 16px;
      }
    }
  }
  .upload-files-area {
    .file-name {
      width: 100%;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: calc(100% - 23px);
      vertical-align: middle;
    }
    .file-show {
      font-size: 12px;
      .file-item {
        display: flex;
        height: 32px;
        line-height: 32px;
        padding: 0 12px 0 8px;
        box-sizing: border-box;
        justify-content: space-between;
        border-radius: 6px;
        &:hover {
          background: #f4f6f9;
          .file-item-size {
            display: none;
          }
          .file-item-right {
            display: flex;
            justify-content: flex-end;
          }
        }
        .file-item-left {
          flex: 1;
          display: flex;
          align-items: center;
          overflow: hidden;
          color: #181b22;
          font-family: 'PingFang SC';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0.5px;
          .iconfont-svg {
            width: 16px;
            height: 16px;
            background-color: transparent;
            margin-right: 5px;
          }
          .name {
            width: auto;
            max-width: calc(100% - 80px);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-left: 8px;
          }
          .type {
            min-width: 60px;
          }
        }
        .file-item-right {
          width: 87px;
          display: none;
          .file-item-view {
            color: #087aff;
            padding-right: 3px;
            cursor: pointer;
            &:hover {
              color: #5da6fa;
            }
          }
          a {
            padding-right: 3px;
            &:hover {
              color: #5da6fa;
            }
          }
          .file-item-del {
            color: #ff0000;
            cursor: pointer;

            &:hover {
              color: #f54f4f;
            }
          }
        }
      }
    }
  }
  .simple-image-area {
    display: flex;
    flex-direction: column;
    .upload-img-item {
      height: 32px;
      border-radius: 6px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 12px 0 8px;
      box-sizing: border-box;
      border: none;
      .image-item-left {
        display: flex;
        align-content: center;
        gap: 8px;
        flex: 1;
        overflow: hidden;
        .text {
          color: #181b22;
          font-family: 'PingFang SC';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0.5px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .el-image {
        width: 18px !important;
        height: 18px !important;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.06);
      }
      .del-image {
        position: unset;
        background: transparent;
      }
      .image-size {
        color: #707786;
        font-family: 'PingFang SC';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.4px;
      }
      &:hover {
        background: #f4f6f9;
        .del-image {
          display: block;
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          .iconshanchu {
            color: rgba(77, 83, 94, 1) !important;
          }
        }
        .image-size {
          display: none;
        }
      }
    }
  }
  .no-data {
    padding: 0 20px;
    color: #909399;
  }
}
.drawing-cont {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 1000;
  i {
    position: absolute;
    top: 0px;
    right: 5px;
    font-size: 25px;
  }
}
.not-uploaded {
  cursor: default;
  padding: 0 12px;
  background: #f2f4f7;
  border-radius: 4px;
}
.upload-style {
  :deep(.el-upload-list) {
    .is-success {
      display: none !important;
    }
    .is-ready {
      margin-bottom: 16px;
    }
  }
}
.list-title {
  position: relative;
  color: #181b22;
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.4px;
}
.desc-list {
  li {
    position: relative;
    padding-left: 8px;
    color: #181b22;
    font-family: 'PingFang SC';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.4px;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 51%;
      transform: translateY(-50%);
      width: 2px;
      height: 2px;
      background-color: #181b22;
      border-radius: 50%;
    }
  }
}
</style>
<style>
.el-image-viewer__wrapper {
  z-index: 9999 !important;
}
</style>
