import { render, staticRenderFns } from "./SendProp.vue?vue&type=template&id=411a6f79&scoped=true"
import script from "./SendProp.vue?vue&type=script&lang=js"
export * from "./SendProp.vue?vue&type=script&lang=js"
import style0 from "./SendProp.vue?vue&type=style&index=0&id=411a6f79&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "411a6f79",
  null
  
)

export default component.exports