
<!--root
 * @Author: your name
 * @Date: 2021-12-02 15:32:01
 * @LastEditTime: 2023-03-06 10:42:32
 * @LastEditors: Shiltin 18580045074@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nanhu-micro\src\views\components\bindModel\index.vue
-->
<template>
	<div class="broadcast-history-cont">
		<div class="top-title">
			<div>历史记录</div>
			<!-- <div class="more">查看更多</div> -->
		</div>
		<el-date-picker
      v-model="dateRange"
      type="daterange"
			size="mini"
			style="width: 100%;"
      range-separator="至"
			value-format="yyyy-MM-dd"
			@change="onFilterHistory"
      start-placeholder="开始日期"
      end-placeholder="结束日期">
    </el-date-picker>
		<div class="bottom-list">
			<template v-if="listData?.length">
				<div v-for="(item,index) in listData || []" :key="index">
					<p class="create-date">{{ item.created_at }}</p>
					<div class="item-info">
						<div class="item-voice-info">
							<div class="item-second">
								<VoiceWave  style="zoom:0.8" size="45" color="#2A2A37" :isPlay="isPlayId === item.id" :showWave="showWave" @on-play="onPlay(item.files,item.id)" />
								<span>{{ item.commun_times }}''</span>
							</div>
							<i v-if="item.text" @click="item.showFont = !item.showFont" class="iconfont right-icon" :class="!item.showFont?'iconjijia_jiantouxiangxia':'iconjijia_jiantouxiangshang'"></i>
						</div>
						<div v-if="item.showFont" class="item-font-cont"> {{ item.text }}</div>
						<p class="device-info" v-if="groupName">终端数量：{{ Object.keys(groupName).length }} 
							<i class="iconfont" @click="item.showDevice = !item.showDevice" :class="!item.showDevice?'iconjijia_sanjiaoxiangxia':'iconjijia_sanjiaoxiangshang'"></i>
						</p>
						<ul v-if="item.showDevice" class="item-device-cont">
							<li :class="getStatus(key)?'is-on-broadcast':'is-off-broadcast'" v-for="(value, key, index) in groupName"
              :key="index">{{value}}</li>
						</ul>
					</div>
				</div>
      </template>
			<Empty
				description="暂无记录"
				v-else
			></Empty>
		</div>
	</div>	
</template>
<script setup>
/* eslint-disable */
import { Empty } from 'element-ui';
import VoiceWave from './voiceWave.vue';
import { ref, watch, computed} from 'vue';
const props = defineProps({
	chooseGroup:{
		type:Object,
    default: () => {},
	},
	groupName:{
    type:Object,
    default: () => {},
  },
	onlineDevices:{
		type:Array,
    default: () => [],
	}
})
const showWave = ref(false);
const audio = ref(null);
const dateRange = ref('');
const isPlayId = ref('');
const listData = ref([]);
const baseListData = ref([]);
const getStatus = computed(()=>{
  return function (item) {
		if(props.onlineDevices?.length){
			return true;
		} else {
			return props.onlineDevices.filter(v=>v.device_number === item).length !== 0;
		}
    
  }
})
watch(()=>props.chooseGroup,(val)=>{
	listData.value = val.children || [];
	baseListData.value = val.children|| [];
},{
	immediate:true
})
const onPlay = (item,id) => {
	if(isPlayId.value){
		audio.value.pause();
		isPlayId.value = '';
		audio.value = null;
	} else {
		if(item?.url){
			audio.value = new Audio(item.url);
			audio.value.play();
			isPlayId.value = id;
			audio.value.addEventListener('ended',()=>{
				isPlayId.value = '';
			})
		}
	}
}
const onFilterHistory = () =>{
	if(dateRange.value?.length){
		listData.value = baseListData.value.filter(item => dateRange.value.includes(item.created_at.slice(0,10)));
	} else {
		listData.value = [...baseListData.value];
	}
}
</script>
<style>
.is-on-broadcast{
  color:#387ffc!important;
}
.is-off-broadcast{
  color:#a4acbd!important;
}
</style>
<style scoped lang="less">
	.broadcast-history-cont{
		width: 290px;
		height: 100%;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		background: #fff;
		padding: 0 16px 16px 16px;
		box-sizing: border-box;
		.top-title{
			height: 24px;
			line-height: 24px;
			padding: 10px 0;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			border-bottom: 1px solid rgba(255, 255, 255, 0.30);
			color: var(--text-on-surface-primary, #181B22);
			font-family: "Source Han Sans CN";
			font-size: 16px;
			font-weight: 500;
			.more{
				color: var(--text-on-surface-link-default, #387FFC);
				font-family: "Source Han Sans CN";
				font-size: 14px;
				font-weight: 400;
			}
		}
		:deep(.el-empty){
			margin:auto;
		}
		:deep(.el-empty__description p){
			color:#707786;
		}
		.bottom-list{
			flex: 1;
			overflow-y: auto;
			display: flex;
			flex-direction: column;
			text-align: left;
			margin-top: 16px;
			.create-date{
				color: var(--text-on-surface-tertiary, #707786);
				font-family: "Source Han Sans CN";
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px; /* 200% */
			}
			.item-info{
				height: auto;
				padding: var(--spacing-6, 12px) var(--spacing-none, 0px);
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;
				gap: var(--spacing-4, 8px);
				align-self: stretch;
				border-radius: var(--radius-4, 8px);
				background: var(--overall-surface-alt-1, #F2F7FF);
				margin-bottom: 16px;
				padding: 12px 12px 12px 0;
				.item-voice-info{
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					height: 24px;
					line-height: 24px;
					color: var(--text-on-surface-primary, #181B22);
					font-family: "Source Han Sans CN";
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					margin-bottom: 8px;
					.item-second{
						display: flex;
						align-items: center;
						.voice-icon{
							width: 22px;
							height: 22px;
						}
					}	
					.right-icon{
						font-size: 20px;
					}
				}
				.item-font-cont, .item-device-cont{
					width: calc(100% - 12px);
					height: auto;
					margin-left: 12px;
					line-height: 18px;
					display: flex;
					padding: var(--spacing-4, 8px);
					flex-direction: column;
					gap: var(--spacing-4, 8px);
					align-self: stretch;
					background: #fff;
					color: var(--text-on-surface-link-default, #387FFC);
					font-family: "Source Han Sans CN";
					font-size: 14px;
					font-weight: 400;
					border-radius: 8px;
					box-sizing: border-box;
					text-align: left;
					li{
						width: 100%;
						overflow: hidden;
						text-overflow: ellipsis;
          	white-space: nowrap;
						text-align: left;
					}
				}
				.device-info{
					height: 24px;
					line-height: 24px;
					color: var(--text-on-surface-tertiary, #707786);
					font-family: "Source Han Sans CN";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					padding-left: 12px;
					i{
						margin-left: 8px;
					}
				}
			}
		}
	}
</style>
