<!--
 * @Description: 循环容器
 * @Author: luocheng
 * @Date: 2021-09-22 10:34:11
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-12-12 11:59:24
-->
<template>
  <div class="loop-container" ref="loopContainer" :id="boxId" v-loading.body="loading" style="align-content: start">
    <template v-if="dataList && dataList.length">
      <ComponentBox
        v-for="(item, index) in dataList"
        :key="item?.[idUUID] || index"
        :isPreview="isPreview"
        :containerData="item"
        :fullData="item"
        :mapData="{
          loopConfig: loopConfig,
          renderData: item,
        }"
        @click.native.stop="onClickBox(getLoopChildren(index, item), item, 'click')"
        @dblclick.native.stop="onDblClickBox(getLoopChildren(index, item), item, 'dblclick')"
        @mouseleave.native.stop="onClickBox(getLoopChildren(index, item), item, 'mouseleave')"
        @mouseenter.native.stop="onClickBox(getLoopChildren(index, item), item, 'mouseenter')"
        :element="getLoopChildren(index, item)"
        :pageId="pageId"
        :isGroup="isGroup"
        :groupComponents="groupComponents"
        :defaultTableSelectData="defaultTableSelectData"
        :componentList="componentList"
      >
        <template v-slot:inner v-if="statusConfig.useCheck">
          <div style="position: absolute;left: 12px;top: 6px;z-index: 100;" v-show="showCheck">
            <el-checkbox v-model="item._front_is_checked" @change="onCheck(item)"></el-checkbox>
          </div>
        </template>
      </ComponentBox>
    </template>
    <el-empty description="暂无数据" v-else></el-empty>
    <!-- 默认应当导出pdf 当 任存在问题，先默认为excel -->
    <Spreadsheet
      v-if="showSheet && sheetFormData"
      :type="spreadsheetType"
      :excelUuid="exportConfig.exportTemplate"
      :objectUuid="exportConfig.interfaceUrl || exportConfig.objectUUID"
      :viewUuid="exportConfig.viewUUID"
      :dataIds="sheetFormData"
      :exportType="exportConfig.exportTypeSheet || 'pdf'"
      @exportSuccess="onExportSuccess"
      :viewSearch="exportViewSearch"
      :viewQuery="exportViewParams"
      @exportError="onExportError"
      :extraParams="exportParam"
    ></Spreadsheet>
  </div>
</template>

<script>
import { dataInterface } from "@/apis/data/index";
import eventBus from "@/plugins/eventBus";
import { throttle,openUrl } from '@/utils/tools';
import { Checkbox, Empty } from "element-ui";
import { mapState } from "vuex";
import Spreadsheet from "@/custom-component/common/Entry";
import {
  getComponentById,
  initParams,
  doEEActionHandle,
  judgingArchiAPermission,
  judgingUILogic,
  getLinkByTemplate,
  judgingEventLogic
} from "@/utils/tools";
import mixin from "./mixins";
import { getStyle } from '@/utils/style';

let that = null;
export default {
  name: "CommonLoopContainer",
  mixins: [mixin],
  props: {
    // 是否为预览
    isPreview: {
      type: Boolean,
      required: false,
      default: false,
    },
    // 弹窗页面配置的ID
    pageId: {
      type: Number,
      required: false,
    },
  },
  components: {
    "el-empty": Empty,
    'el-checkbox': Checkbox,
    Spreadsheet,
  },
  data() {
    return {
      // 当前组件唯一Key
      boxId: `table-container-${new Date().getTime()}`,
      // 分页
      pager: {
        current_page: 1,
        per_page: 15,
        total: 0,
      },
      pageSizes: [15, 30, 50, 100],
      // 表格数据
      dataList: [],
      // 配置数据
      metadata: [],
      loading: true,
      // 缓存的搜索条件
      search: [],
      param: {},
      rowKey: "",
      // 表格弹窗
      showSheet: false,
      // 表格数据
      sheetFormData: null,
      exportConfig: null,
      // 操作类型：add - 新增，edit - 编辑，info - 详情，export - 导出
      spreadsheetType: "export",
      exportViewParams: {},
      exportViewSearch: [],
      // 使用后端返回配置导出的导出配置
      exportWay: "", // 导出配置 eeConfig 后端配置， '' 自定义
      eeExportTemplate: "",
      eeObjectUUID: "",
      eeViewUUID: "",
      // 循环模版
      templateList: [],
      exportParam: {},
      // 单双击事件
      timer: null,
      // 选中
      checkList: [],
      showCheck: false,
      idUUID: 'id'
    };
  },
  created() {
    this.showCheck = this.element?.statusConfig?.defaultCheck;
    that = this;
    // 分页器
    const { defaultPerPage = 15 } = this.statusConfig;
    this.pager.per_page = defaultPerPage;
    // 请求数据
    this.doInterface();
    this.setInterval();
  },
  provide: {
    // TIPS objectData、refreshApi loopContainerMetadata 都是为了循环容器内传递到下拉按钮
    loopContainerDatabase: () => {
      return that?.database;
    },
    loopContainerMetadata: () => {
      return that?.metadata;
    },
    refreshApi: () => {
      return that?.doInterface;
    },
  },
  computed: {
    ...mapState(["componentData"]),
    // 数据仓库配置
    database() {
      return this.element?.database;
    },
    // 操作类型
    actionConfig() {
      return this.element?.actionConfig;
    },
    // 配置
    statusConfig() {
      return this.element?.statusConfig;
    },
    // 字段配置
    fieldConfig() {
      const configs = this.element?.fieldConfig;
      if (!configs) return [];
      if (!configs?.length) return this.metadata || [];
      const result = [];
      configs.forEach((ele) => {
        if (ele?.show) {
          result.push(ele);
        }
      });
      return result;
    },
    // 前端排序
    useSort() {
      return !!this.element?.statusConfig?.useSort;
    },
    // 排序字段类表
    sortConfigs() {
      return this.fieldConfig?.filter((ele) => ele.bySort);
    },
    /**
     * @desc: 循环容器配置
     */
    loopConfig() {
      return this.element?.loopConfig;
    },
  },
  mounted() {
    const exportData = {
      [this.element.id]: (exportConfig) => {
        if (!exportConfig || exportConfig.componentId !== this.element.id)
          return;
        this.exportConfig = exportConfig;
        if(exportConfig.resolveData) this.element.resolveData = exportConfig.resolveData;
        this.doExport(exportConfig);
      },
    };
    // 导出
    eventBus.$on("exportData", exportData[this.element.id]);
    // 监听设备在线状态
    if(this.element.deviceLister?.listenDevice && this.element.deviceLister?.stateField){
      eventBus.$on("deviceStatus",(data)=>{
        if(this.element?.containerData?.length){
          for(let i=0;i<this.element.containerData.length;i++){
            const str = JSON.stringify(this.element.containerData[i])
            if(str.includes(data.device_number)){
              if(+data.status === 2){
                this.element.containerData[i][this.element.deviceLister.stateField] = 3
              } else if(+data.status === 0) {
                this.element.containerData[i][this.element.deviceLister.stateField] = 1
              } else if(+data.status === -1){
                this.element.containerData[i][this.element.deviceLister.stateField] = 2
              }
            }
          }
        }
      });
    }
    
    const databaseTrigger = {
      [this.element.id]: (data) => {
        const { isSearch = false } = data;
        if (data?.originComponent === this.element.id || (data?.componentId === this.element.id && data.action !== 'dataList')) return;
        // if (data?.originComponent === this.element.id || data?.componentId === this.element.id) return;
        if (data.parentId && data?.parentId !== this.element.id) return false;
        // 配置时候触发请求
        if (data.componentId === this.element.id && data.isInit) {
          const {
            search = [],
            param = {},
            canPost,
          } = initParams(
            this.element?.database?.paramsConfig || [],
            this.isGroup,
            this.subComponentData,
            this.groupComponents,
            this.EDITOR_pageUUID
          );
          if (!canPost) {
            return;
          }
          if (isSearch) {
            this.pager.current_page = 1;
          }
          this.getList(this.element.database, search, param);
          return;
        }
        // 点击操作时候不更新数据
        if (data.noUpdate) return;
        const { paramsConfig = [] } = this.element.database;
        let isTarget = false;
        paramsConfig.forEach((ele) => {
          if (ele.componentId === data.componentId) {
            isTarget = true;
          }
        });
        if (!isTarget && !data.isUpdate) return;
        if (!paramsConfig || !paramsConfig.length) {
          if (isSearch) {
            this.pager.current_page = 1;
          }
          this.getList(this.element.database);
          return;
        }
        // 以下步骤是为了避免有多个来源的search需要进行differ 避免检索结果错误情况
        let {
          search = [],
          param = {},
          canPost,
        } = initParams(
          this.element?.database?.paramsConfig || [],
          this.isGroup,
          this.subComponentData,
          this.groupComponents,
          this.EDITOR_pageUUID
        );
        if (!canPost) {
          return;
        }
        this.param = param;
        if (isSearch) {
          this.pager.current_page = 1;
        }
        this.getList(this.element.database, search, param);
      },
    };
    // 配置关联参数的容器才需要监听
    eventBus.$on("databaseTrigger", databaseTrigger[this.element.id]);

    // 触发暴露值
    const EDITORLoopResolve = {
      [this.element.id]: (data) => {
        if (!data) return;
        const { index, isLoop, loopId } = data;
        if (!isLoop) return;
        if (loopId !== this.element.id) return;
        if (!this.dataList?.[index]) return;
        this.element.resolveData = this.dataList?.[index];
        this.$store.commit("updatePageCustomStatus", {
          origin: this.element,
          resolveData: this.dataList?.[index],
        });
      },
    };
    eventBus.$on("EDITOR_loopResolve", EDITORLoopResolve[this.element.id]);
		window.onresize = () => {
			throttle(() => {}, 300)
			this.resizeBox();
		};
    //监听行为
		const doComponentBehavior = {
			[this.element.id]: config => {
				const { component, list = [] } = config;
				if (this.element.id !== component) return false;
				list.forEach(ele => {
					const { behaviors, params, logics = [] } = ele;
					const { param = {}, canPost } = initParams(params, false, this.subComponentData, [], this.EDITOR_pageUUID);
					if (canPost && judgingEventLogic(logics, this.subComponentData)) {
						// 调用行为方法
						behaviors.forEach(funName => {
							try {
								eval(this[funName])(param)
							} catch (err) {
								console.log(err);
							}
						});
					}
				});
			}
		}
		eventBus.$on('doComponentBehavior', doComponentBehavior[this.element.id])
  },
  beforeDestroy() {
    eventBus.$off('deviceStatus');
  },
  methods: {
    // 判断架构及权限
    judgingArchiAPermission,
    judgingUILogic,
    // 行为
    /**
     * @description: 切换
     * @param {Number/ String} isShow 
     */
    _toggleCheckBox({ isShow }) {
      if (isNaN(+isShow)) {
        this.$message.warning('参数错误！');
        return;
      }
      this.showCheck = !!(+isShow);
    },
    /**
     * @description: 清除已选项
     */
    _clearCheckList() {
      this.checkList = [];
      this.onResolveKeys([]);
    },
    // 行为结束
    /**
     * @description: 选中
     */
    onCheck(val) {
      if (!val) return;
      const { _front_id, _front_is_checked } = val;
      // 写入
      if (_front_is_checked) {
        if (!this.checkList.find(ele => ele._front_id === _front_id)) {
          this.checkList.push(val);
        }
      } else {
        // 移除
        const index = this.checkList.findIndex(ele => ele._front_id === _front_id);
        if (index !== -1) {
          this.checkList.splice(index, 1);
        }
      }
      const { resolveFullNode, resolveCodes } = this.statusConfig;
      let resolveKeys = [];
			if (resolveFullNode) {
        if (resolveCodes && typeof resolveCodes === 'string') {
          const codes = resolveCodes.split(';');
          resolveKeys = this.checkList.map(ele => {
            const item = {};
            codes.forEach(key => {
              item[key] = ele[key]
            })
            return item;
          })
        } else {
          resolveKeys = this.checkList;
        }
      } else {
        const id = this.getIdUUID();
        resolveKeys = this.checkList.map(ele => ele[id]);
      }
      console.log(resolveKeys, '----循环容器暴露Array')
      this.onResolveKeys(resolveKeys);
    },
		/**
		 * @desc: 选中框的情况将选中的id暴露并触发更新
		 * @param {Array} resolveData
		 */
		onResolveKeys(resolveData = {}) {
			this.$store.commit('modifyComponent', {
				component: {
					...this.element,
					resolveData
				},
				containerId: null,
				isModify: true,
				pageUUID: this.EDITOR_pageUUID
			});
      this.element.resolveData = resolveData;
      this.$store.commit("updatePageCustomStatus", {
        origin: this.element,
        resolveData
      });
			setTimeout(() => {
				eventBus.$emit('databaseTrigger', {
					componentId: this.element.id,
					isSearch: true,
					action: 'any',
					output: resolveData
				});
			}, 0)
		},
		/**
		 * @description: 计算左对齐问题
		 */
		resizeBox() {
			this.$nextTick(() => {
				const container = this.$refs.loopContainer;
				window.containerBox = container;
				if (!container) return;
				const childNodes = container.childNodes;
				if (!childNodes || !childNodes.length) return;
				const containerWidth = container.offsetWidth;
				const list = [];
				for (let i = 0; i < childNodes.length; i++) {
					if (childNodes[i]?.classList?.value?.indexOf('component-box') > -1) {
						list.push(childNodes[i]);
					}
				}
				if (!list.length) return;
				const lastChild = list[list.length - 1];
				if (!lastChild) return;
				const lastWidth = lastChild.offsetWidth;
				const rowCount = Math.floor(containerWidth / lastWidth);
				const lessCount = this.dataList.length % rowCount;
				const iBoxList = document.querySelectorAll('.i-space-box')
				if (lessCount) {
					const between =  rowCount - lessCount;
					const appendCount = between - iBoxList.length;
					if (!appendCount && !lessCount) return;
					if (appendCount < 0 && iBoxList.length) {
						// 移除多余个数
						const num = appendCount < 0 ? appendCount * -1 : lessCount;
						for (let i = 0; i < num; i++) {
							iBoxList[iBoxList.length - i - 1].remove();
						}
						return;
					}
					// 不足需要补齐
					for (let i = 0; i < appendCount; i++) {
						const iBox = document.createElement('i');
						iBox.setAttribute('class', 'i-space-box');
						const style = this.getBoxStyle(this.getLoopChildren(0, {}))
						if (Object.prototype.toString.call(style) === '[object Object]') {
							for (let key in style) {
								iBox.style[key] = style[key];
							}
						}
						iBox.style.opacity = 0;
						iBox.style.width = `${lastWidth}px`;
						setTimeout(() => {
							container.appendChild(iBox)
						}, 0);
					}
				} else {
					// 移除多余
					iBoxList.forEach(ele => {
						ele.remove()
					});
				}
			});
		},
		/**
		 * @desc: 组件盒型样式
		 * @param {Object} element 组件对象
		 */
		getBoxStyle(element) {
			// if (element.component === 'CommonModel' || element.component==='ConsoleBimModel') return;
			const { style, specialStyle } = element;
			if (!style) return {};
			let excludesArr = [
				'top',
				'left',
				'rotate',
				'overflowY',
				'overflowX'
			];
			let result = getStyle(style, excludesArr);
			// 兼容flex 不居中表格横向滚动失效
			if ((this.element.span !== undefined && +this.element.span !== -1)
				|| (this.element.component === 'CommonTableContainer'
					&& result.width
					&& result.width.toString().indexOf('calc') === -1
				)
			) {
				result.width = '';
			}
			// 特殊样式
			if (specialStyle.hoverConfig?.use) {
				const { type, shadowConfig, borderConfig } = specialStyle?.hoverConfig?.config || {};
				if (type === 'boxShadow' && shadowConfig) {
					const {v, h, blur, spread, color = 'rgba(104, 110, 123, .25)' } = shadowConfig;
					result = {
						...result,
						'--hoverH': `${h}px`,
						'--hoverW': `${v}px`,
						'--hoverBlur': `${blur}px`,
						'--hoverSpread': `${spread}px`,
						'--hoverColor': color,
						// '--hoverInset': `${inset ? 'inset' : ''}`
					}
				} else if (type === 'border' && borderConfig) {
					const { w, style = 'solid', color = '#337EFF'} = borderConfig;
					result = {
						...result,
						'--borderW': `${w}px`,
						'--borderStyle': style,
						'--borderColor': color
					}
				}
			}
			if (specialStyle.isCollector) {
				// 收集者
				result.flex = 1;
			}
			if (specialStyle.isAbsolute) {
				result.position = 'absolute';
				const { absoluteTop = '', absoluteLeft = '', absoluteBottom = '', absoluteRight = '', zIndex = 10 } = specialStyle;
				if (absoluteTop !== '') {
					result.top = isNaN(+absoluteTop) ? absoluteTop : `${absoluteTop}px` ;
				}
				if (absoluteBottom !== '') {
					result.bottom = isNaN(+absoluteBottom) ? absoluteBottom : `${absoluteBottom}px` ;
				}
				if (absoluteLeft !== '') {
					result.left = isNaN(+absoluteLeft) ? absoluteLeft : `${absoluteLeft}px` ;
				}
				if (absoluteRight !== '') {
					result.right = isNaN(+absoluteRight) ? absoluteRight : `${absoluteRight}px` ;
				}
				result.zIndex = isNaN(+zIndex) ? 10 : zIndex;
			}
			return result;
		},
    /**
     * @description: 执行请求
     */
    doInterface() {
      if (!this.interfaceFinished) return;
      this.interfaceFinished = false;
      this.metadata = this.element.metadata || [];
      this.templateId = this.loopConfig?.cardId;
      if (this.database && !this.hasComponentParam()) {
        const {
          search = [],
          param = {},
          canPost,
        } = initParams(
          this.element?.database?.paramsConfig || [],
          this.isGroup,
          this.subComponentData,
          this.groupComponents,
          this.EDITOR_pageUUID
        );
        if (!canPost) {
          return;
        }
        this.getList(this.database, search, param);
      }
    },
    /**
     * @desc: 判断是否存在依赖其他组件的取值
     */
    hasComponentParam() {
      if (!this.database?.paramsConfig?.length) {
        return false;
      }
      for (let i = 0; i < this.database.paramsConfig.length; i++) {
        const {
          componentId = "",
          key = "",
          sourceType = "",
        } = this.database.paramsConfig?.[i] || {};
        if (
          (key !== "search" || !componentId.includes("CommonForm")) &&
          sourceType !== "url"
        ) {
          // 补充不同架构问题
          if (
            !componentId.includes("CommonTableContainer") &&
            this.paramsSameArchi(componentId)
          ) {
            return true;
          }
        }
      }
      return false;
    },
    /**
     * @desc: 判断依赖的参数是否在当前架构下启用(@凌志华树形图架构限制)
     * @param {String} componentId
     * @return {Boolean}
     */
    paramsSameArchi(componentId) {
      let comp = getComponentById(this.subComponentData, componentId);
      if (!comp && this.isGroup && this.groupComponents.length) {
        comp = getComponentById(this.groupComponents, componentId);
      }
      if (!comp) return false;
      const targetArchi = this.$GetTargetArchi("archiType");
      if (comp?.archiLimit?.includes(targetArchi)) {
        return true;
      }
      return false;
    },
    /**
     * @desc: 获取渲染列表
     * @param {Object} database 数据配置对象
     * @param {Array} search 搜索
     */
    getList(database, search = [], params = {}) {
      this.search = Array.isArray(search) ? search : [];
      // 清空选中@蒲亚军
      this._clearCheckList([]);
      // this.dataList = [];
      if (!this.validDatabase(database)) {
        this.interfaceFinished = true;
        return;
      }
      this.loading = true;
      const paramsObj = {
        ...params,
        search,
      };
      // 配置
      let __method_name__ = "dataList";
      const mapping = database.mapping;
      let configObj = null;
      let url = "/api/mapi";
      if (mapping === "interface") {
        url = `/api${database?.interfaceConfig?.url}`;
        configObj = {
          ...paramsObj,
        };
      } else {
        if (mapping === "object") {
          configObj = {
            __method_name__,
            object_uuid: database.objectData.uuid,
            view_uuid: database.viewData.uuid,
            ...paramsObj,
          };
        } else if (mapping === "relation") {
          __method_name__ = "relationList";
          configObj = {
            __method_name__,
            object_uuid: database.objectData.uuid,
            relationship_uuid: database.relationData.uuid,
            ...paramsObj,
          };
        }
      }
      // 获取表格数据
      dataInterface(configObj, url)
        .then((res) => {
          if (res && res.status === 200) {
            let dataList = [];
            if (
              mapping === "interface" ||
              ["dataList", "relationList"].includes(__method_name__)
            ) {
              // 列表数据
              dataList = this.getListFromRes(res, true) || [];
            }
            // 字段列表
            if (!this.metadata || !this.metadata.length) {
              let metadata = {};
              if (mapping === "interface") {
                // 接口
                if (Array.isArray(dataList)) {
                  if (
                    Object.prototype.toString.call(dataList?.[0]) ===
                    "[object Object]"
                  ) {
                    for (let key in dataList?.[0]) {
                      metadata[key] = key;
                    }
                  }
                }
              } else {
                metadata = res.data.metadata;
              }
              this.metadata = metadata;
            }
            for (let i = 0; i < this.fieldConfig.length; i++) {
              const item = this.fieldConfig[i];
              if (item.type === 7) {
                // switch 编辑
                dataList = dataList.map((ele) => {
                  if (item.switchOptions.type === "number") {
                    // 数字
                    ele[item.uuid] =
                      +ele[item.uuid] === +item.switchOptions.trueValue;
                  } else if (item.switchOptions.type === "boolean") {
                    if (ele[item.uuid] === "true") {
                      ele[item.uuid] = true;
                    } else if (ele[item.uuid] === "false") {
                      ele[item.uuid] = false;
                    }
                  } else if (item.switchOptions.type === "string") {
                    ele[item.uuid] =
                      ele[item.uuid] === item.switchOptions.trueValue;
                  }
                  return ele;
                });
              }
            }
            // 排序
            if (this.useSort && this.sortConfigs && this.sortConfigs.length) {
              dataList = this.customSort(dataList);
            }
            const idUUID = this.getIdUUID();
            this.idUUID = idUUID
            this.dataList = dataList.map((ele, _front_id) => {
              return {
                ...ele,
                _front_id,
                _front_is_checked: false
                // 保持缓存@蒲亚军
                // _front_is_checked: Array.isArray(this.element.resolveData) ? !!(this.element.resolveData.find(item => item === ele?.[idUUID] || ele?.[idUUID] === item?.[idUUID])) : false
              }
            });
            // 完全数据 暂时保存
            this.fullData = res.data.data;
            // 保存容器数据
            this.$store.commit("modifyComponent", {
              component: {
                ...this.element,
                containerData: this.dataList,
                fullData: this.fullData,
                metadata: this.metadata,
                database: {
                  ...this.element.database,
                  fieldList: this.getFieldList(this.metadata),
                },
              },
              containerId: null,
              isModify: true,
              pageUUID: this.EDITOR_pageUUID,
            });
          }
          this.interfaceFinished = true;
          this.resizeBox();
          this.loading = false;
          // 获取监听设备在线状态
          if(this.element.deviceLister?.listenDevice && this.element.deviceLister?.stateField){
            setTimeout(()=>{
              eventBus.$emit("getDeviceStatus");
            },0)
          }
        }).catch((err) => {
          console.log(err);
          this.interfaceFinished = true;
          this.loading = false;
        });
    },
    /**
     * @desc: 前端自定义排序
     * @param {Array} arr
     * sortWay:排序顺序 ascending 升序， descending 降序
     */
    customSort(arr) {
      if (!Array.isArray(arr) || !arr.length) return [];
      let dataList = arr;
      try {
        this.sortConfigs.forEach((ele) => {
          const { uuid, sortWay } = ele;
          dataList.sort((a, b) => {
            return a?.[uuid] - b?.[uuid];
          });
          // 降序
          if (sortWay === "descending") {
            dataList.reverse();
          }
        });
      } catch (e) {
        console.log(e, "前端排序故障---表格1");
      }
      return dataList;
    },
    /**
     * @desc: 获取字段列表
     * @param {Object} obj metadata对象
     */
    getFieldList(obj) {
      const arr = [];
      if (obj && typeof obj === "object") {
        for (let key in obj) {
          arr.push({
            name: obj[key],
            uuid: key,
          });
        }
      }
      return arr;
    },
    /**
     * @desc: 验证
     * @param {Object} database 数据仓库的绑定
     */
    validDatabase(database) {
      if (!database || typeof database !== "object") return false;
      if (database.mapping === "interface" && database.interfaceConfig)
        return true;
      if (!database.objectData) return false;
      if (!database.viewData && !database.relationData) return false;
      return true;
    },
    /**
     * @desc: 根据请求返回获取列表结构
     * @param {Object} res 请求返回的数据
     * @param {Boolean} withPager 是否需要重置pager
     */
    getListFromRes(res, withPager = true) {
      if (Array.isArray(res?.data)) {
        if (withPager) {
          this.pager = res;
        }
        return res.data;
      }
      if (res?.data && typeof res === "object") {
        return this.getListFromRes(res.data, withPager);
      }
      return [];
    },
    /**
     * @desc: 触发后端事件
     */
    doEEAction(btnConfig, rowData, type = "main") {
      if (btnConfig?.actionType === "eeAction") {
        const { objectUUID, viewUUID, eventName } = btnConfig;
        if (!objectUUID || !viewUUID || !eventName) {
          this.$message.error("请正确配置事件!");
          return false;
        }
        const idUUID =
          type === "main" ? this.getIdUUID() : this.statusConfig.nestingRowKey;
        const data_id = rowData?.[idUUID];
        if (!data_id) {
          this.$message.error("事件未配置触发对象!");
          return false;
        }
        this.loading = true;
        dataInterface({
          __method_name__: "customEventCall",
          object_uuid: objectUUID,
          view_uuid: viewUUID,
          data_id,
          event: eventName,
        })
          .then((res) => {
            if (res.status === 200 && res.data.code === 200) {
              this.$message.success("操作成功！");
              doEEActionHandle(res.data?.__adds__);
              this.onPage(this.pager.current_page);
            }
            this.loading = false;
          })
          .catch((err) => {
            console.log(err, "00000后端事件错误");
            this.loading = false;
          });
      }
    },
    /**
     * @desc: 切换分页参数
     * @param {Number} per_page 跳转的页码
     */
    onSize(per_page) {
      this.pager = {
        ...this.pager,
        current_page: 1,
        per_page,
      };
      let {
        search = [],
        param = {},
        canPost,
      } = initParams(
        this.element?.database?.paramsConfig || [],
        this.isGroup,
        this.subComponentData,
        this.groupComponents,
        this.EDITOR_pageUUID
      );
      if (canPost) {
        this.getList(this.database, search, param || {});
      }
    },
    /**
     * @desc: 切换页码
     * @param {Number} current_page 当前页码
     */
    onPage(current_page) {
      this.pager = {
        ...this.pager,
        current_page,
      };
      let {
        search = [],
        param = {},
        canPost,
      } = initParams(
        this.element?.database?.paramsConfig || [],
        this.isGroup,
        this.subComponentData,
        this.groupComponents,
        this.EDITOR_pageUUID
      );
      if (canPost) {
        this.getList(this.database, search, param || {});
      }
    },
    /**
     * @desc: 导出
     * @param {Object} config 配置
     */
    async doExport(config, rowData = null, type = "main") {
      this.exportTableType = type;
      const loading = this.$loading({
        text: "导出中....",
      });
      const { exportType = "all" } = config;
      let idUUID =
        type === "main" ? this.getIdUUID() : this.statusConfig.nestingRowKey;

      if (!idUUID) {
        loading.close();
        this.$message.error("导出失败！数据验证错误。");
        return;
      }
      /* eslint-disable */
      // exportType 导出配置 all 全部， targetPage 本页，checked 当前选中
      // 最终导出数据
      // 导出全部
      if (exportType === "targetPage") {
        if (!this.dataList?.length) {
          loading.close();
          this.$message.error("暂无数据可供导出！");
          return;
        }
        // 本页
        this.sheetFormData = [];
        this.dataList.forEach((ele) => {
          this.sheetFormData.push(ele[idUUID]);
        });
      } else if (exportType === "checked") {
        if (!this.element?.cacheData?.length) {
          loading.close();
          this.$message.error("请选择需要导出的数据！");
          return;
        }
        this.sheetFormData = [];
        // 当前选中
        this.element.cacheData.forEach((ele) => {
          this.sheetFormData.push(ele[idUUID]);
        });
      } else if (exportType === "all") {
        this.sheetFormData = "all";
      } else if (exportType === "searchAll") {
        this.sheetFormData = "all";
        const queryData = initParams(
          this.element?.database?.paramsConfig || [],
          this.isGroup,
          this.subComponentData,
          this.groupComponents,
          this.EDITOR_pageUUID
        );
        this.exportViewParams = queryData.param || {};
        this.exportViewSearch = queryData.search;
      } else if (exportType === "targetRow") {
        this.sheetFormData = rowData?.[idUUID];
        if (this.exportWay === "eeConfig") {
          // 后端导出配置
          const { exportTemplateField, objectUUIDField, viewUUIDField } =
            config;
          if (!exportTemplateField || !objectUUIDField || !viewUUIDField) {
            loading.close();
            this.$message.error("导出配置错误！");
            return false;
          }
          this.eeExportTemplate = rowData?.[exportTemplateField];
          this.eeObjectUUID = rowData?.[objectUUIDField];
          this.eeViewUUID = rowData?.[viewUUIDField];
          this.exportConfig = {
            ...this.exportConfig,
            exportTemplate: this.eeExportTemplate,
            objectUUID: this.eeObjectUUID,
            viewUUID: this.eeViewUUID,
          };
          if (
            !this.eeExportTemplate ||
            !this.eeObjectUUID ||
            !this.eeViewUUID
          ) {
            loading.close();
            this.$message.error("导出配置错误！");
            return false;
          }
        }
      }
      if (!this.sheetFormData) {
        loading.close();
        this.$message.error("导出配置错误！");
        return false;
      }
      // 参数
      if (config?.params) {
        const { param = {} } = initParams(
          config.params,
          this.isGroup,
          this.subComponentData,
          this.groupComponents,
          this.EDITOR_pageUUID
        );
        this.exportParam = param;
      }
      this.showSheet = true;
    },
    /**
     * @desc: 导出成功
     */
    onExportSuccess() {
      this.$loading().close();
      this.showSheet = false;
      this.$message.success("导出成功！");
      this.exportParam = {};
    },
    /**
     * @desc: 导出错误
     * @param {String} msg 错误信息
     */
    onExportError(msg) {
      this.$loading().close();
      this.showSheet = false;
      this.$message.error(msg || "导出失败！");
      this.exportParam = {};
    },
    /**
     * @desc: 获取数据主键，ID UUID
     */
    getIdUUID() {
      let idUUID = this.statusConfig.idUUID || "";
      if (!idUUID) {
        let idCount = 0;
        for (let key in this.metadata) {
          if (this.metadata[key] === "id") {
            idCount++;
            idUUID = key;
          }
        }
        // 编辑器提示
        if (["/modify-page", "/page-view"].includes(this.$route.path)) {
          // 编辑器提示ID问题
          if (idCount === 0) {
            setTimeout(() => {
              this.$message.warning(`组件【${this.element.name}】数据接口无【id】字段返回，请检查接口配置！`);
            }, 0);
            return "";
          }
          if (idCount > 1) {
            setTimeout(() => {
							this.$message.warning(`组件【${this.element.name}】数据接口存在【${idCount}】个【id】字段返回，请检查接口配置！以保证准确性！`);
            }, 0);
            return "";
          }
        }
      }
      return idUUID;
    },
    /**
     * @desc: 格式化循环容器子节点
     * @param {Number} index 下标
     */
    getLoopChildren(index, sourceData, level = 0) {
      if (!this.element?.children?.length) return {};
      const template = this.element.children[0];
      return {
        ...template,
        id: `${template.id}-${level}-${index}`,
        loopData: {
          isLoop: true,
          sourceData,
          index,
          loopId: this.element.id,
        },
        children: this.getChildren(
          template.children,
          sourceData,
          level + 1,
          index
        ),
      };
    },
    /**
     * @desc: 获取下级
     * @param {Array} arr 数组
     * @param {Object} sourceData
     * @param {Number} level
     * @param {Number} index 数据源下标
     */
    getChildren(arr = [], sourceData, level = 1, index) {
      return arr.map((ele, cIndex) => {
        return {
          ...ele,
          id: `${ele.id}-${level}-${cIndex}`,
          loopData: {
            isLoop: true,
            sourceData,
            index,
            loopId: this.element.id,
          },
          children: this.getChildren(
            ele.children,
            sourceData,
            level + 1,
            index
          ),
        };
      });
    },
    /**
     * @desc: 获取页面配置数据
     */
    getPageData(page_uuid) {
      return new Promise((resolve, reject) => {
        dataInterface({
          __method_name__: "dataInfo",
          object_uuid: "a4f016d6-c602-4492-8874-f088c3c0b3b9",
          view_uuid: "view61b951c6a8186",
          page_uuid,
          transcode: 0,
        })
          .then((res) => {
            resolve(res?.data?.data);
          })
          .catch((err) => {
            console.log(err);
            reject(null);
          });
      });
    },
    /**
     * @desc: 触发点击事件
     * @param {Object} element 点击的组件(可能需要兼容)
     * 触发方式实质就是在改变指定的组件状态
     * 目前覆盖点击事件
     */
    onClickBox(element, output, eventType) {
      const excludesEvents = ["CommonDateButton"];
      //  || !Array.isArray(item?.events?.click?.effects)
      if (excludesEvents.includes(element.component)) {
        return false;
      }
      // 数组出现原因暂时不明，估计是老代码导致，因此直接排除
      if (!element.events || Array.isArray(element.events)) return;
      if (this.timer) {
        clearTimeout(this.timer);
      }
      if (element?.event?.dblclick) {
        this.timer = setTimeout(() => {
          this._triggerEvent(element?.events?.[eventType], element, output);
        }, 300);
      } else {
        this._triggerEvent(element?.events?.[eventType], element, output);
      }
    },
    /**
     * @description: 双击事件
     * @param {Object} element 配置对象
     */
    onDblClickBox(element, output) {
      const excludesEvents = ["CommonDateButton"];
      //  || !Array.isArray(item?.events?.dblclick?.effects)
      if (excludesEvents.includes(element.component)) {
        return false;
      }
      // 数组出现原因暂时不明，估计是老代码导致，因此直接排除
      if (!element.events || Array.isArray(element.events)) return;
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this._triggerEvent(element?.events?.dblclick, element, output);
    },
    /**
     * @description: 触犯单个事件
     */
    _triggerEvent(eventConfig, element, output) {
      if (!eventConfig) return;
      const {
        actionType,
        effects = [],
        behaviors,
        specialEventList = [],
        pattern,
        linkType,
        queryList = [],
        linkUrl = "",
        pageId,
        objectUUID,
        viewUUID,
        eventName,
        eeType,
        interfaceUrl,
        linkTemplate,
      } = eventConfig;
      if (actionType === "eeAction") {
        // 触发后端事件
        if ((!objectUUID || !viewUUID || !eventName) && !interfaceUrl) {
          this.$message.error("事件配置错误！");
          return false;
        }
        this.loading = true;
        const sourceParams = this.getBindParams(element);
        // 蓝图
        if (eeType === "blueprint") {
          dataInterface(sourceParams, `/api${interfaceUrl}`)
            .then((res) => {
              if (res.status === 200 && res.data.code === 200) {
                this.$message.success("操作成功！");
                doEEActionHandle(res.data?.__adds__);
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
          return true;
        }
        // 数仓
        const data_id = sourceParams?.data_id;
        dataInterface({
          __method_name__: "customEventCall",
          object_uuid: objectUUID,
          view_uuid: viewUUID,
          ...sourceParams,
          data_id, // 参数配置
          event: eventName,
        })
          .then((res) => {
            if (res.status === 200 && res.data.code === 200) {
              this.$message.success("操作成功！");
              doEEActionHandle(res.data?.__adds__);
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
        return true;
      } else if (actionType === "jumpPage") {
        if (linkType === "projectPage") {
          const query = {};
          queryList.forEach((queryItem) => {
            let component = getComponentById(
              this.subComponentData,
              queryItem.componentId
            );
            this.$set(
              query,
              queryItem.key,
              component?.resolveData[queryItem.feild]
            );
          });
          this.$router.push({
            path: pageId,
            query,
          });
          return;
        } else if (linkType === "outPage") {
          
          // window.open(linkUrl);
          openUrl(linkUrl,linkUrl)
        } else if (linkType === "custom") {
          // 自定义拼接
          const customLink = getLinkByTemplate(linkTemplate, output);
          openUrl(customLink,customLink)
          // window.open(customLink);
        }
      } else {
        // 普通事件
        if (!pattern) {
          effects.forEach((ele) => {
            this.$store.commit("triggerEvents", {
              config: ele,
              element,
              EDITOR_pageUUID: this.EDITOR_pageUUID,
            });
          });
        } else if (pattern === "special") {
          specialEventList.forEach((ele) => {
            ele.effects.forEach((effect) => {
              this.$store.commit("triggerEvents", {
                config: effect,
                element,
                EDITOR_pageUUID: this.EDITOR_pageUUID,
              });
            });
            if (ele.behaviors?.length) {
              for (let i = 0; i < ele.behaviors.length; i++) {
                this.$store.commit("triggerEvents", {
                  config: {
                    behavior: ele.behaviors[i],
                    isBehavior: true,
                    EDITOR_pageUUID: this.EDITOR_pageUUID,
                  },
                });
              }
            }
          });
        }
      }
      if (behaviors && Array.isArray(behaviors)) {
        behaviors.forEach((behavior) => {
          this.$store.commit("triggerEvents", {
            config: {
              behavior,
              isBehavior: true,
            },
            element,
            EDITOR_pageUUID: this.EDITOR_pageUUID,
          });
        });
      }
    },
  }
};
</script>

<style lang="less" scoped>
:deep(.loop-container) {
  height: 100%;
  width: 100%;
  align-content: start;
  .loop-container-wrap {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .loop-wrap {
      flex: 1;
      overflow: auto;
      // align-content: flex-start;
      > .el-empty {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .el-pagination {
      box-sizing: border-box;
      padding: 5px 10px;
      text-align: left;
      flex-shrink: 0;
    }
  }
}
:deep(.el-empty) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
