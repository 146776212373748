<!--
    @name: widget-signature
    @description：widget-signature
    @author: ZengWei
    @date: 2022-04-11 18:29
-->
<template>
  <div>
    <template v-if="element.config.__config__.signType === 1">
      <div>
        <el-popover placement="right" width="100">
          <el-button slot="reference" @click="generateQrcode">
            <i class="iconfont iconfenbaoshenhe"></i>&nbsp;&nbsp;&nbsp;手写签名
          </el-button>
          <div style="text-align: center">
            <img v-if="qrCode" :src="qrCode" alt width="100" height="100" />
            <p>请打开手机扫一扫</p>
          </div>
        </el-popover>
        <el-button
          v-if="element.config.__config__.preSign"
          style="margin-left: 15px"
          type="text"
          @click="getUserSignature">
          使用预设签名
        </el-button>
      </div>
      <div class="pre-signature">
        <div class="pre-signature-item" v-for="(item,index) in element.value" :key="index">
          <i v-if="!disabled" class="iconfont icongis_shanchu remove" @click="removeSign(index)"></i>
          <el-image
            style="width: 100px; height: 100px;margin: 15px"
            :src="item.signature"
            fit="contain"
            :preview-src-list="[item.signature]">
          </el-image>
          <p style="text-align: center">{{item.datetime}}</p>
        </div>
      </div>
    </template>

    <template v-else>
      <div>
        <el-button type="primary" @click="viewSign">使用签章</el-button>
        <div class="pre-signature">
          <div class="pre-signature-item" v-for="(item,index) in element.value" :key="index">
            <i v-if="!disabled" class="iconfont icongis_shanchu remove" @click="removeSign(index)"></i>
            <el-image
              style="width: 100px; height: 100px;margin: 5px"
              :src="item.signature"
              :preview-src-list="[item.signature]">
            </el-image>
            <p style="text-align: center">{{item.datetime.substr(0, 15)}}</p>
          </div>
        </div>
      </div>
    </template>

    <el-dialog
      title="我可用的签章"
      :custom-class="'common-dialog'"
      :visible.sync="dialogVisible"
      top="5vh"
      width="790px"
      append-to-body
    >
      <article class="dialog-main">
        <div class="sign-img">
          <div
            class="sign-img-item"
            v-for="(item,index) in sealList"
            :key="index"
            :class="{active: activeIndex === index}"
            @click="activeSign(item,index)">
            <el-image
              style="width: 100px; height: 100px"
              :src="item.picture">
            </el-image>
            <p class="sign-name">{{item.seal_name}}</p>
          </div>
        </div>
      </article>
      <div slot="footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="手机短信验证码验证"
      :custom-class="'common-dialog'"
      :visible.sync="confirmVisible"
      width="378px"
      append-to-body
    >
      <article style="padding: 0 15px;">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="76px">
          <el-form-item label="验证码：" prop="code" style="margin: 20px 0">
            <el-input v-model="ruleForm.code" style="width: 150px;margin-right: 25px"></el-input>
            <el-button>发送验证码</el-button>
          </el-form-item>
        </el-form>
      </article>
      <div slot="footer">
        <el-button @click="confirmVisible = false">取消</el-button>
        <el-button type="primary" @click="confirmSign">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {Image,Popover} from "element-ui";
import { formRequest } from "@/apis/data/form";
import { baseUrl } from '@/apis/http/request';
import {getNowTime} from "@/utils/tools";

export default {
  name: "WidgetSignature",
  components: {
    'el-image': Image,
    'el-popover': Popover,
  },
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {
      qrCode: '',
      timer: null,
      qrToken: "",
      dialogVisible: false,
      confirmVisible: false,
      activeIndex: '',
      activeData: '',
      sealList:[],
      ruleForm:{
        code: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入短信验证码', trigger: 'blur' },
        ]
      }
    }
  },
  mounted() {
  },
  methods: {
    activeSign(item,index){
      this.activeIndex = index
      const data = {
        signature: item.picture,
        datetime: getNowTime(2)
      }
      this.activeData = data
    },
    viewSign(){
      this.dialogVisible = true
      formRequest('get', 'api/useSignSeal/getUserSeal',{})
        .then(res=>{
          if(res.data.code === 200){
            const data = res.data.data
            for (let item of data){
              let seal = JSON.parse(item.picture)
              item.picture =  seal[0].url || seal[0].filepath
            }
            this.sealList = data
          }
        })
    },
    confirm(){
      const validType = this.element.config.__config__.validType
      if(validType === 1) {
        this.element.value.push(this.activeData)
        this.dialogVisible = false
      } else if(validType === 2) {
        this.confirmVisible = true
      } else if(validType === 3) {
        this.$message.error('PC端不支持人脸识别验证，请在移动端操作')
      }
    },
    confirmSign(){

    },
    removeSign(index){
      this.element.value.splice(index,1)
    },
    getUserSignature(){
      const url = '/api/v4/user/signature'
      formRequest('get', url ,{})
        .then(res=>{
          if (res.data.code === 200) {
            if (res.data.data !== null || res.data.data !== '') {
              this.signImg = baseUrl+'/'+res.data.data;
              this.element.value.push(this.signImg);
            } else {
              this.$message.error('您暂未预设签名，请到个人中心设置签名后使用')
            }
          }
        })
    },
    generateQrcode() {
      if(!this.qrCode){
        clearInterval(this.timer);
        const url = '/api/generate/signature'
        formRequest('post', url ,{})
          .then(res=>{
            if (res.data.code === 200) {
              this.qrCode = res.data.data.qrcode;
              this.qrToken = res.data.data.token;
              let that = this;
              this.timer = setInterval(() => {
                that.getAppSignature();
              }, 1000);
            }
          })
      }
    },
    getAppSignature() {
      const data = {qrToken: this.qrToken}
      const url= '/api/generate/signature'
      formRequest('get', url, data)
        .then((res) => {
          if (res.data.code === 200) {
            this.signImg = res.data.data.signature;
            if (this.signImg !== null) {
              const data = {
                signature: this.signImg,
                datetime: getNowTime(2),
              }
              this.element.value.push(data);
              clearInterval(this.timer);
            }
          }
        });
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.common-dialog)  {
  .el-dialog__body{
    padding: 0;
  }
}

.dialog-main{
  padding: 0 15px;
  height: 500px;
  overflow-y: auto;
}

.sign-img{
  display: flex;
  flex-wrap: wrap;

  .sign-img-item{
    width: 100px;
    padding: 15px;
    margin: 10px;
    border: 1px solid #fff;
    border-radius: 5px;

    .sign-name{
      margin-top: 10px;
      text-align: center;
    }
  }

  .active{
    border: 1px solid #eeeeee !important;
    border-radius: 5px;
  }
}
.pre-signature{
  display: flex;
  flex-wrap: wrap;
}
.pre-signature-item{
  position: relative;
  margin: 8px;
}

.remove{
  position: absolute;
  right: 0;
  top: -8px;
  z-index:100;
}

</style>
