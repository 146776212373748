<!--
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2024-12-02 15:58:41
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-12-03 11:51:49
 * @FilePath: \dataview-viewer-test\src\custom-component\broadcast\components\voiceWave.vue
 * @Description: 
-->
<template>
	<div class="voice-wave" @click="onClick" :style="{width:`${size}px`,height:`${size}px`}">
    <div v-if="showWave && isPlay" class="wave" :style="{borderColor:background}"></div>
    <div v-if="showWave && isPlay"  class="wave" :style="{borderColor:background}"></div>
    <div v-if="showWave && isPlay"  class="wave wave1" :style="{borderColor:background}"></div>
    <div v-if="showWave && isPlay"  class="wave wave2" :style="{borderColor:background}"></div>
    <div class="voice-button-cont" :style="{background:showWave?background:''}">
      <div class="voice-bar" :class="isPlay?'is-animation':''" :style="{background:color}" v-for="item in 5" :key="item"></div>
    </div>
	</div>	
</template>

<script setup>
/* eslint-disable */
const emits = defineEmits(['on-play']);
const props = defineProps({
  groupData:{
    type:Array,
    default: () => [],
  },
  size:{
    type:[Number,String],
    default:60
  },
  color:{
    type:String,
    default:'#fff'
  },
  background:{
    type:String,
    default:'#3766F6'
  },
  showWave:{
    type:Boolean,
    default:true
  },
  isPlay:{
    type:Boolean,
    default:true
  }
})
const onClick = () => {
  emits('on-play');
}
</script>
<style scoped lang="less">
  @keyframes scale {
    0% {
      transform: translateY(0) scale(0.8) rotateX(0);
      opacity: 0;
    }
    50% {
      transform: translateY(0) scale(1) rotateX(0);
      opacity: 0.3;
    }
    100% {
      transform: translateY(0) scale(1.2) rotateX(0);
      opacity: 0;
    }
  }
  /* 定义竖条填充动画 */
  @keyframes fillBlue {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .voice-wave{
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
  }
  .wave {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0;
    border-width: 10px;
    border-style: solid;
    box-shadow: 0 0 10px rgba(89, 199, 251, 0.8) inset;
    animation: scale 2s linear infinite;
    position: absolute;
    left: -10px;
    pointer-events:none;
  }
  .wave1 {
    animation-delay: 1s;
  }
  .wave2 {
    animation-delay: 2s;
  }
  

  /* 应用动画到竖条 */
  .voice-bar {
    width: 5%;
    margin: 0 3%;
    border-radius:1px;
  }
  .is-animation{
    animation: fillBlue 1s infinite; /* 动画持续时间，forwards保持最终状态 */
  }

  /* 为每个竖条设置不同的动画延迟 */
  .voice-bar:nth-child(1) { 
    animation-delay: 0s; 
    height: 16%;
  }
  .voice-bar:nth-child(2) { 
    animation-delay: 0.2s; 
    height: 22%;
  }
  .voice-bar:nth-child(3) { 
    animation-delay: 0.4s; 
    height: 40%;
  }
  .voice-bar:nth-child(4) { 
    animation-delay: 0.6s; 
    height: 22%;
  }
  .voice-bar:nth-child(5) {
    animation-delay: 0.8s; 
    height: 16%;
  }

  /* 语音按钮样式 */
  .voice-button-cont {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
	
</style>
