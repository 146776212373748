<!--
 * @Description: 图片组件 v0.0.1
 * @Author: luocheng
 * @Date: 2021-08-09 17:09:06
 * @LastEditors: qinmengyuan 2715025514@qq.com
 * @LastEditTime: 2024-12-18 14:01:32
-->
<template>
	<div class="common-image" @click="onClickImage">
		<!-- 固定 -->
		<template v-if="element.valueOrign === 'fixed'">
			<img :class="hoverClass" :src="imagePath(element.propValue)" alt="图片"  :style="imgStyle(imagePath(element.propValue))" />
		</template>
		<!-- 来源于其他组件 -->
		<template v-if="element.valueOrign === 'component'">
			<!-- 暂未完成 -->
			<img :class="hoverClass" :src="imagePath(element.propValue)" alt="图片" :style="imgStyle(imagePath(element.propValue))" />
		</template>
		<!-- 数据仓库 -->
		<template v-if="element.valueOrign === 'database'">
			<template v-if="dataObj && bindUUID">
				<!-- 绑定值 -->
				<template v-if="databaseType === 'value'">
					<!-- 对传入值为数组仅展示第一条处理 -->
					<img v-if="Array.isArray(dataObj)" :class="hoverClass" :src="imagePath(dataObj?.[0]?.[bindUUID])" alt="图片" :style="imgStyle(imagePath(element.propValue))" />
					<img v-else :class="hoverClass" :src="imagePath(dataObj[bindUUID])" alt="图片" :style="imgStyle(imagePath(element.propValue))" />
				</template>
				<!-- 数据字典(描述文字) -->
				<template v-else-if="databaseType === 'wordbook' && metaData">
					{{ metaData.name }}
				</template>
			</template>
		</template>
		<!-- 循环容器数据映射 -->
		<template v-if="element.valueOrign === 'valueMap'">
			<img :class="hoverClass" :src="imagePath(mapPath)" alt="图片" :style="imgStyle(imagePath(mapPath))" />
		</template>
		<!-- 预览 -->
		<template v-if="showViewer">
			<ImageViewer
			:z-index="5000"
			:initial-index="0"
			:on-close="closeViewer"
			:url-list="previewSrcList">
			</ImageViewer>
		</template>
	</div>
</template>

<script>
import { getComponentById } from '@/utils/tools';
import { mapState } from 'vuex';
import { baseUrl } from '@/apis/http/request';
import ImageViewer from './ImageViewer.vue';

export default {
	name: 'CommonImage',
	components: {
		ImageViewer
	},
	inject: ['EDITOR_pageUUID'],
	props: {
		// 绑定的值
		element: {
			type: Object,
			default: () => {},
			required: true
		},
		// 是否在组合内
		isGroup: {
			type: Boolean
		},
		// 组合内组件列表
		groupComponents: {
			type: Array,
			default: () => []
		},
		componentList: {
			default: null
		},
		// 循环映射值
		mapData: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			defaultImage: require('@/assets/images/empty-light.png'),
			showViewer: false,
			previewSrcList: [],
			prevOverflow: null
		};
	},
	computed: {
		...mapState(['componentData','subsidiaryComponentData']),
		// 数据仓库
		database() {
			return this.element.database;
		},
		subComponentData() {
			if (this.EDITOR_pageUUID) {
				return this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData || this.componentData || [];
			}
			return this.componentData || [];
		},
		// 字段对象
		dataObj() {
			if (!this.database || !this.database.containerKey) return [];
			let container = getComponentById(this.componentList || this.subComponentData, this.database.containerKey);
			if (!container && this.isGroup && this.groupComponents.length) {
				container = getComponentById(this.groupComponents, this.database.containerKey);
			}
			if (!container || !container.containerData) return null;
			return container.containerData;
		},
		// 绑定的uuid
		bindUUID() {
			if (!this.element || !this.element.database) return '';
			return this.element.database.bindUUID;
		},
		// 用于获取绑定的数据字典(描述文字)
		metaData() {
			if (!this.element || !this.element.database) return null;
			const { fieldList, bindUUID } = this.element.database;
			return fieldList.find((ele) => ele.uuid === bindUUID);
		},
		// 数据绑定类型
		databaseType() {
			if (!this.element || !this.element.database) return 'value';
			return this.element.database.databaseType || 'value';
		},
		// 图片样式
		imgStyle() {
			return function (src) {
				if (src.includes('/empty-light')) {
					return { 'object-fit': 'scale-down' };
				} else {
					const { objectFit } = this.element.statusConfig;
					if (objectFit) {
						return { 'object-fit': objectFit };
					}
					const editorType = sessionStorage.getItem('editorType');
					if (editorType === 'dataview' || editorType === 'console') {
						return { 'object-fit': 'fill' };
					}
					return { 'object-fit': 'cover' };
				}
			};
		},
		//数据仓库图片的路径地址
		imagePath() {
			return function (obj,showUrl=false) {
				if (this.element.valueOrign === 'database') {
					if(typeof obj === 'string') {
						return obj;
					} else if (obj && obj.length && obj[0].url) {
						if (obj[0].url.includes('http')) {
							if(this.element?.statusConfig.isThumb && !showUrl){
								return obj[0].thumb_path;
							}else{
								return obj[0].url;
							}
							
						} else {
							return `${baseUrl}${obj[0].url}`;
						}
					} else {
						return this.defaultImage;
					}
				} else {
					if (obj) {
						if (Array.isArray(obj)) {
							return obj[0]?.url || this.defaultImage;
						}
						return obj;
					} else {
						return this.defaultImage;
					}
				}
			};
		},
		/**
		 * @desc: 映射的路径
		 */
		mapPath() {
			const { bindField } = this.element?.loopMapConfig || {};
			if (!bindField) return '';
			const { renderData } = this.mapData || {};
			if (!renderData) return '';
			const urlObj = renderData?.[bindField];
			if (Array.isArray(urlObj)) {
				const item = urlObj?.[0];
				if (typeof item === 'string') {
					return item;
				}
				return urlObj?.[0]?.url || '';
			}
			return urlObj || '';
		},
		/**       
		 * @desc 动画效果
		 */
		hoverClass() {
			const hoverEffect = this.element?.statusConfig?.hoverEffect;
			if(hoverEffect) {
				return hoverEffect;
			} else {
				return '';
			}
		}
	},
	methods: {
		/**
		 * @description: 点击图片查看大图
		 */
		onClickImage() {
			if (!this.element?.statusConfig.hasPreview) return;
			const { valueOrign, propValue } = this.element;
			this.previewSrcList = [];
			if (valueOrign === 'fixed') {
				this.previewSrcList.push(this.imagePath(propValue));
			} else if (valueOrign === 'component') {
				this.previewSrcList.push(this.imagePath(propValue));
			} else if (valueOrign === 'database') {
				if (this.dataObj && this.bindUUID) {
					if (this.databaseType === 'value') {
						if (Array.isArray(this.dataObj)) {
							this.previewSrcList.push(this.imagePath(this.dataObj?.[0]?.[this.bindUUID],true));
						} else {
							this.previewSrcList.push(this.imagePath(this.dataObj[this.bindUUID],true));
						}
					} else if (this.databaseType === 'wordbook' && this.metaData) {
						this.previewSrcList.push(this.defaultImage);
					}
				} else {
					this.previewSrcList.push(this.defaultImage);
				}
			} else if (valueOrign === 'valueMap') {
				this.previewSrcList.push(this.imagePath(this.mapPath));
			} else {
				this.previewSrcList.push(this.defaultImage);
			}
			this.prevOverflow = document.body.style.overflow;
			document.body.style.overflow = 'hidden';
			this.showViewer = true;
		},
		/**
		 * @description: 关闭
		 */
		closeViewer() {
			document.body.style.overflow = this.prevOverflow;
			this.showViewer = false;
		}
	}
};
</script>

<style lang="less" scoped>
.common-image {
	height: 100%;
	width: 100%;
	overflow: hidden;
	img, .el-image {
		display: block;
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
	.image-scale {
		transition: transform .5s;
		&:hover{
			transform: scale(1.2);
		}
	}
	.image-fillet{
		transition: border-radius .5s;
		&:hover{
			border-radius: 50%;
		}
	}
	.image-jello {
		&:hover{
			animation: jello 1s linear;
		}
		@keyframes jello {
			from,
			11.1%,
			to {
				transform: translate3d(0, 0, 0);
			}
			22.2% {
				transform: skewX(-12.5deg) skewY(-12.5deg);
			}
			33.3% {
				transform: skewX(6.25deg) skewY(6.25deg);
			}
			44.4% {
				transform: skewX(-3.125deg) skewY(-3.125deg);
			}
			55.5% {
				transform: skewX(1.5625deg) skewY(1.5625deg);
			}
			66.6% {
				transform: skewX(-0.78125deg) skewY(-0.78125deg);
			}
			77.7% {
				transform: skewX(0.390625deg) skewY(0.390625deg);
			}
			88.8% {
				transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
			}
		}
	}
	.image-bounce {
		&:hover{
			animation: bounce 1s linear;
		}
		@keyframes bounce {
			from,
			20%,
			53%,
			to {
				animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
				transform: translate3d(0, 0, 0);
			}
			40%,
			43% {
				animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
				transform: translate3d(0, -30px, 0) scaleY(1.1);
			}
			70% {
				animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
				transform: translate3d(0, -15px, 0) scaleY(1.05);
			}
			80% {
				transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
				transform: translate3d(0, 0, 0) scaleY(0.95);
			}
			90% {
				transform: translate3d(0, -4px, 0) scaleY(1.02);
			}
		}
	}
	.image-flash{
		&:hover{
			animation: flash 1s linear;
		}
		@keyframes flash {
			from,
			50%,
			to {
				opacity: 1;
			}
			25%,
			75% {
				opacity: 0;
			}
		}
	}
	.image-rubberBand{
		&:hover{
			animation: rubberBand 1s linear;
		}
		@keyframes rubberBand {
			from {
				transform: scale3d(1, 1, 1);
			}
			30% {
				transform: scale3d(1.25, 0.75, 1);
			}
			40% {
				transform: scale3d(0.75, 1.25, 1);
			}
			50% {
				transform: scale3d(1.15, 0.85, 1);
			}
			65% {
				transform: scale3d(0.95, 1.05, 1);
			}
			75% {
				transform: scale3d(1.05, 0.95, 1);
			}
			to {
				transform: scale3d(1, 1, 1);
			}
		}
	}
	.image-heart-beat {
		&:hover{
			animation: heartBeat 1s linear;
		}
		@keyframes heartBeat {
			0% {
				transform: scale(1);
			}
			14% {
				transform: scale(1.3);
			}
			28% {
				transform: scale(1);
			}
			42% {
				transform: scale(1.3);
			}
			70% {
				transform: scale(1);
			}
		}
	}
}
</style>
