<!--
 * @Description: CommonTableContainer 表格容器（简化配置）
 * @Author: luocheng
 * @Date: 2021-09-22 10:34:11
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-12-04 15:53:26
-->
<template>
  <div
    class="table-container"
    :id="boxId"
    style="flex-direction: column"
    v-loading="loading"
  >
    <el-table
      v-if="
      showTable && 
        statusConfig &&
        ((!statusConfig.isTreeTable && !statusConfig.feTreeKey) ||
          (statusConfig.isTreeTable && rowKey) ||
          (statusConfig.feTreeKey && rowKey)) &&
        fieldConfig
      "
      :data="tableData"
      :height="height"
      :style="{ width: mainWidth, border: '1px solid #f2f3f5', height: height }"
      class="table-box"
      :class="{
        'mounted-class': tableMounted,
        'transparent-table': statusConfig?.transparent,
      }"
      :row-key="rowKey"
      :key="element.id"
      v-loading="statusConfig.loading"
      :stripe="statusConfig.stripe"
      :border="statusConfig.border"
      :size="statusConfig.size"
      :fit="statusConfig.fit"
      :show-header="statusConfig.showHeader"
      :highlight-current-row="statusConfig.highlightCurrentRow"
      :empty-text="statusConfig.emptyText"
      @selection-change="onSelectionChange"
      @select="handleSelectionChange"
      :tree-props="treeProps"
      @row-click="onRowClick"
      :header-cell-style="{ 'text-align': 'left' }"
      :cell-style="{ 'text-align': 'left' }"
      :default-expand-all="
        statusConfig.defaultExpandAll === false ? false : true
      "
      :ref="element.id"
    >
      <template class="empty-box" slot="empty">
        <el-empty :image-size="80" description="暂无数据"></el-empty>
      </template>
      <!-- eslint-disable -->
      <!-- 嵌套表格——子表格 -->
      <el-table-column type="expand" v-if="statusConfig.isNesting">
        <!-- E8F0FE -->
        <div :style="{ width: boxWidth }" slot-scope="scope">
          <el-table
            :data="scope.row.children"
            :row-key="statusConfig.nestingRowKey"
            :key="element.id + '-child'"
            border
            :header-cell-style="{
              'background-color': '#E8F0FE',
              height: '48px',
              'text-align': 'left',
            }"
            :cell-style="{ 'text-align': 'left' }"
          >
            <template class="empty-box" slot="empty">
              <el-empty description="暂无数据"></el-empty>
            </template>
            <el-table-column
              :label="item.label"
              v-for="(item, index) in nestingFieldConfig"
              :key="item.uuid"
              :align="statusConfig.align"
              :header-align="statusConfig.headerAlign"
              v-if="
                item.show &&
                validArchiType(item.archiType) &&
                judgingArchiAPermission([], item.permission) &&
                judgingUILogic(item.UILogic, subComponentData)
              "
              :width="item.width"
            >
              <span
                slot-scope="{ row }"
                :style="{ color: row[item.uuid] ? item.color : '#bbb' }"
              >
                <!-- 文本 -->
                <template v-if="+item.type === 1">
                  <template v-if="item?.textConfig?.useEllipsis">
                    <!-- 低于20个字不适用弹出显示 -->
                    <template
                      v-if="
                        item?.textConfig?.usePopover &&
                        row[item.uuid]?.toString().length >
                          (item?.textConfig?.pCount || 20)
                      "
                    >
                      <el-popover
                        placement="top-start"
                        :width="
                          isNaN(+item?.textConfig?.pWidth)
                            ? 150
                            : +item?.textConfig?.pWidth
                        "
                        trigger="hover"
                        :content="isNull(row[item.uuid]) ? '/' : row[item.uuid]"
                      >
                        <p
                          slot="reference"
                          :class="getEllipsisClass(item?.textConfig?.row)"
                        >
                          {{ isNull(row[item.uuid]) ? '/' : row[item.uuid] }}
                        </p>
                      </el-popover>
                    </template>
                    <p :class="getEllipsisClass(item?.textConfig?.row)" v-else>
                      {{ isNull(row[item.uuid]) ? '/' : row[item.uuid] }}
                    </p>
                  </template>
                  <template v-else>{{
                    isNull(row[item.uuid]) ? '/' : row[item.uuid]
                  }}</template>
                </template>
                <!-- 图片 -->
                <template v-else-if="item.type === 2">
                  <!-- 多图片 -->
                  <!-- :previewSrcList="[row[item.uuid].length ? (row[item.uuid][0].url.toString().replace('_thumb', '')) : defaultImage ]" -->
                  <el-image
                    v-if="Array.isArray(row[item.uuid])"
                    :src="
                      row[item.uuid] &&
                      row[item.uuid].length &&
                      (row[item.uuid][0].thumb_path||row[item.uuid][0].path)?.toString()?.indexOf?.('http') > -1
                        ? (row[item.uuid][0].thumb_path||row[item.uuid][0].path)
                        : defaultImage
                    "
                    :previewSrcList="getImageList(row[item.uuid])"
                    fit="cover"
                    alt="图片"
                    class="image"
                    :style="{
                      height: (item.imgConfig.height || 60) + 'px',
                      width: (item.imgConfig.height || 60) + 'px',
                    }"
                  ></el-image>
                  <!-- 链接 -->
                  <el-image
                    v-else
                    :src="
                      row[item.uuid] && row[item.uuid]?.indexOf?.('http') > -1
                        ? row[item.uuid]
                        : defaultImage
                    "
                    :previewSrcList="[
                      row[item.uuid]
                        ? row[item.uuid]?.toString().replace('_thumb', '')
                        : defaultImage,
                    ]"
                    fit="cover"
                    alt="图片"
                    class="image"
                    :style="{
                      height: (item.imgConfig.height || 60) + 'px',
                      width: (item.imgConfig.height || 60) + 'px',
                    }"
                  ></el-image>
                </template>
                <!-- 状态 -->
                <template
                  v-else-if="item.type === 3 && !isNull(row[item.uuid])"
                >
                  <!-- 默认 -->
                  <span
                    v-if="!item.statusStyle"
                    :style="{
                      color:
                        filterStatus(row[item.uuid], item.statusOptions)
                          .color || item.color,
                    }"
                  >
                    {{ filterStatus(row[item.uuid], item.statusOptions).label }}
                  </span>
                  <!-- 块 -->
                  <span
                    v-else-if="item.statusStyle === 'block'"
                    class="status-block"
                    :style="{
                      background:
                        filterStatus(row[item.uuid], item.statusOptions)
                          .color || item.color,
                    }"
                  >
                    {{ filterStatus(row[item.uuid], item.statusOptions).label }}
                  </span>
                  <!-- 点 -->
                  <span
                    v-else-if="item.statusStyle === 'point'"
                    class="status-point"
                  >
                    <span
                      class="point"
                      :style="{
                        background:
                          filterStatus(row[item.uuid], item.statusOptions)
                            .color || item.color,
                      }"
                    ></span>
                    <span
                      :style="{
                        color:
                          filterStatus(row[item.uuid], item.statusOptions)
                            .color || item.color,
                      }"
                      >{{
                        filterStatus(row[item.uuid], item.statusOptions).label
                      }}</span
                    >
                  </span>
                </template>
                <!-- 时间 -->
                <template
                  v-else-if="item.type === 4 && !isNull(row[item.uuid])"
                  >{{
                    filterTime(row[item.uuid], item.timeFormat, item.connector)
                  }}</template
                >
                <!-- 富文本 -->
                <template
                  v-else-if="item.type === 5 && !isNull(row[item.uuid])"
                >
                  <div class="rich-text" v-html="row[item.uuid]"></div>
                </template>
                <!-- 链接 -->
                <template
                  v-else-if="item.type === 6 && !isNull(row[item.uuid])"
                >
                  <a :href="row[item.uuid]" class="link" alt="链接">{{
                    row[item.uuid]
                  }}</a>
                </template>
                <!-- switch 开关 -->
                <template v-else-if="item.type === 7">
                  <el-switch
                    v-model="row[item.uuid]"
                    @change="onSwitch(item, row[item.uuid], row, true)"
                  ></el-switch>
                </template>
                <!-- 数字 -->
                <template v-else-if="item.type === 8">
                  {{ filterNumber(row[item.uuid], item.numberOptions) || '/' }}
                </template>
                <!-- 部位名称 -->
                <template v-else-if="item.type === 9">
                  {{ getPositionName(row[field.uuid]) }}
                </template>
                <!-- 常规显示 -->
                <template v-else>{{
                  isNull(row[item.uuid]) ? '/' : row[item.uuid]
                }}</template>
              </span>
            </el-table-column>
            <el-table-column
              v-if="nestingActionConfig && nestingActionConfig.needAction"
              :width="nestingActionConfig.width || ''"
              :align="statusConfig.align"
              :header-align="statusConfig.headerAlign"
              key="actions"
              label="操作"
            >
              <template slot-scope="scope">
                <span v-for="(action, index) in nestingActionList" :key="index">
                  <el-button
                    v-if="
                      action &&
                      typeof action === 'object' &&
                      action.use &&
                      logicAction(action, scope.row) &&
                      judgingArchiAPermission([], action.permission) &&
                      judgingUILogic(action.UILogic, subComponentData)
                    "
                    :type="action.btnType"
                    :size="action.size"
                    :style="{
                      color: action.color,
                      marginLeft: action.margin + 'px',
                    }"
                    @click="
                      onAction(
                        action.type,
                        scope.row,
                        $event,
                        'nesting',
                        nestingActionConfig
                      )
                    "
                    >{{ action.name }}</el-button
                  >
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-table-column>
      <!-- 主表格 -->
      <el-table-column
        type="selection"
        v-if="statusConfig.canSelect"
        width="55"
      >
      </el-table-column>
      <el-table-column
        v-if="statusConfig.showOrder || statusConfig.useFeWBS"
        label="序号"
        type="index"
        :width="statusConfig.orderWidth || 50"
        :fixed="fixedIndexRow"
      >
        <template slot-scope="scope">
          <template v-if="statusConfig.useFeWBS">
            {{ scope.row.wbsString || '' }}
          </template>
          <template v-else>{{ scope.$index + 1 }}</template>
        </template>
      </el-table-column>
      <!-- 属性 fieldConfig 样式正确-->
      <el-table-column
        :label="item.label"
        v-for="(item, index) in fieldConfig"
        :key="item.uuid"
        :align="statusConfig.align"
        :header-align="statusConfig.headerAlign"
        v-if="
          item.show &&
          validArchiType(item.archiType) &&
          judgingArchiAPermission([], item.permission) &&
          judgingUILogic(item.UILogic, subComponentData)
        "
        :width="item.width"
        :fixed="!!fixColumns.find((ele) => +index <= +ele)"
      >
        <span
          slot-scope="{ row }"
          :style="{
            color:
              (item.type !== 4 && !isNull(row[item.uuid])) ||
              (item.type === 4 &&
                filterTime(row[item.uuid], item.timeFormat, item.connector))
                ? item.color
                : '#bbb',
          }"
        >
          <!-- 文本 -->
          <template v-if="+item.type === 1">
            <template v-if="item?.textConfig?.useEllipsis">
              <!-- 低于20个字不适用弹出显示 -->
              <template
                v-if="
                  item?.textConfig?.usePopover &&
                  row[item.uuid]?.toString().length >
                    (item?.textConfig?.pCount || 20)
                "
              >
                <el-popover
                  placement="top-start"
                  :width="
                    isNaN(+item?.textConfig?.pWidth)
                      ? 150
                      : +item?.textConfig?.pWidth
                  "
                  trigger="hover"
                  :content="isNull(row[item.uuid]) ? '/' : row[item.uuid]"
                >
                  <p
                    slot="reference"
                    :class="getEllipsisClass(item?.textConfig?.row)"
                  >
                    {{ isNull(row[item.uuid]) ? '/' : row[item.uuid] }}
                  </p>
                </el-popover>
              </template>
              <p :class="getEllipsisClass(item?.textConfig?.row)" v-else>
                {{ isNull(row[item.uuid]) ? '/' : row[item.uuid] }}
              </p>
            </template>
            <template v-else>{{
              isNull(row[item.uuid]) ? '/' : row[item.uuid]
            }}</template>
          </template>
          <!-- 图片 -->
          <template v-else-if="+item.type === 2">
            <!-- 图片列表 -->
            <!-- :previewSrcList="[row[item.uuid].length ? (row[item.uuid][0].url.toString().replace('_thumb', '')) : defaultImage ]" -->
            <el-image
              v-if="Array.isArray(row[item.uuid])"
              :src="
                row[item.uuid] &&
                row[item.uuid].length &&
                (row[item.uuid][0].thumb_path||row[item.uuid][0].path)?.toString()?.indexOf?.('http') > -1
                  ? row[item.uuid][0].thumb_path||row[item.uuid][0].path
                  : defaultImage
              "
              :previewSrcList="getImageList(row[item.uuid])"
              fit="cover"
              alt="图片"
              class="image"
              :style="{
                height: (item.imgConfig.height || 60) + 'px',
                width: (item.imgConfig.height || 60) + 'px',
              }"
            ></el-image>
            <!-- 单图 -->
            <el-image
              v-else
              :src="
                row[item.uuid] && row[item.uuid]?.indexOf?.('http') > -1
                  ? row[item.uuid]
                  : defaultImage
              "
              :previewSrcList="[
                row[item.uuid]
                  ? row[item.uuid]?.toString().replace('_thumb', '')
                  : defaultImage,
              ]"
              fit="cover"
              alt="图片"
              class="image"
              :style="{
                height: (item.imgConfig.height || 60) + 'px',
                width: (item.imgConfig.height || 60) + 'px',
              }"
            ></el-image>
          </template>
          <!-- 状态 -->
          <template v-else-if="+item.type === 3 && !isNull(row[item.uuid])">
            <!-- 默认 -->
            <span
              v-if="!item.statusStyle"
              :style="{
                color:
                  filterStatus(row[item.uuid], item.statusOptions).color ||
                  item.color,
              }"
            >
              {{ filterStatus(row[item.uuid], item.statusOptions).label }}
            </span>
            <!-- 块 -->
            <span
              v-else-if="item.statusStyle === 'block'"
              class="status-block"
              :style="{
                background:
                  filterStatus(row[item.uuid], item.statusOptions).color ||
                  item.color,
              }"
            >
              {{ filterStatus(row[item.uuid], item.statusOptions).label }}
            </span>
            <!-- 点 -->
            <span v-else-if="item.statusStyle === 'point'" class="status-point">
              <span
                class="point"
                :style="{
                  background:
                    filterStatus(row[item.uuid], item.statusOptions).color ||
                    item.color,
                }"
              ></span>
              <span
                :style="{
                  color:
                    filterStatus(row[item.uuid], item.statusOptions).color ||
                    item.color,
                }"
                >{{
                  filterStatus(row[item.uuid], item.statusOptions).label
                }}</span
              >
            </span>
          </template>
          <!-- 时间 -->
          <template v-else-if="+item.type === 4 && !isNull(row[item.uuid])">
            {{
              filterTime(row[item.uuid], item.timeFormat, item.connector) || '/'
            }}
          </template>
          <!-- 富文本 -->
          <template v-else-if="+item.type === 5 && !isNull(row[item.uuid])">
            <div class="rich-text" v-html="row[item.uuid]"></div>
          </template>
          <!-- 链接 -->
          <template v-else-if="+item.type === 6 && !isNull(row[item.uuid])">
            <a :href="row[item.uuid]" class="link" alt="链接">
              {{ row[item.uuid] }}
            </a>
          </template>
          <!-- switch 开关 -->
          <template v-else-if="+item.type === 7">
            <el-switch
              v-model="row[item.uuid]"
              @change="onSwitch(item, row[item.uuid], row, false)"
            ></el-switch>
          </template>
          <!-- 数字 -->
          <template v-else-if="+item.type === 8">
            {{ filterNumber(row[item.uuid], item.numberOptions) || '/' }}
          </template>
          <!-- 部位名称 -->
          <template v-else-if="+item.type === 9">
            {{ getPositionName(row[item.uuid]) }}
          </template>
          <!-- 常规显示 -->
          <template v-else>{{
            isNull(row[item.uuid]) ? '/' : row[item.uuid]
          }}</template>
        </span>
      </el-table-column>
      <el-table-column
        v-if="actionConfig && actionConfig.needAction"
        :width="actionConfig.width || ''"
        :align="statusConfig.align"
        :header-align="statusConfig.headerAlign"
        key="actions"
        label="操作"
        :fixed="fixColumns.includes('T') ? 'right' : false"
      >
        <template slot-scope="scope">
          <span v-for="(action, index) in actionList" :key="index">
            <el-button
              v-if="
                action &&
                typeof action === 'object' &&
                action.use &&
                logicAction(action, scope.row) &&
                judgingArchiAPermission(
                  action && action.archiLimit,
                  action.permission
                ) &&
                judgingUILogic(action.UILogic, subComponentData)
              "
              :type="action.btnType"
              :size="action.size"
              :style="{
                color: action.color,
                marginLeft: action.margin + 'px',
              }"
              @click="
                onAction(action.type, scope.row, $event, 'main', actionConfig)
              "
              >{{ action.name }}</el-button
            >
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="statusConfig.pagination"
      @size-change="onSize"
      @current-change="onPage"
      :current-page="+pager.current_page"
      :page-sizes="pageSizes"
      :page-size="+pager.per_page"
      layout="total, sizes, prev, pager, next, jumper"
      :total="+pager.total"
    ></el-pagination>
    <!-- 新版导出组件 -->
    <!-- 默认应当导出pdf 当 任存在问题，先默认为excel -->
    <Spreadsheet
      v-if="showSheet && sheetFormData"
      :type="spreadsheetType"
      :excelUuid="exportConfig.exportTemplate"
      :objectUuid="exportConfig.interfaceUrl || exportConfig.objectUUID"
      :viewUuid="exportConfig.viewUUID"
      :dataIds="sheetFormData"
      :exportType="exportConfig.exportTypeSheet || 'pdf'"
      @exportSuccess="onExportSuccess"
      :viewSearch="exportViewSearch"
      :viewQuery="exportViewParams"
      @exportError="onExportError"
      :extraParams="exportParam || {}"
      :solveType="TYPEMAP[exportConfig.exportRequest] || 'form'"
    ></Spreadsheet>
  </div>
</template>

<script>
import { dataInterface } from '@/apis/data/index';
import eventBus from '@/plugins/eventBus';
import {
  $,
  getComponentById,
  getQueryValue,
  getLinkByTemplate,
  initParams,
  doEEActionHandle,
  unique,
  doFrontOperation,
  openUrl,
} from '@/utils/tools';
import {
  Empty,
  Image,
  Pagination,
  Popover,
  Switch,
  Table,
  TableColumn,
} from 'element-ui';
import { mapState } from 'vuex';
import Spreadsheet from '@/custom-component/common/Entry';
import { judgingArchiAPermission, judgingUILogic } from '@/utils/tools';
import mixin from './mixins';
import databaseTriggerDebug from '@/custom-component/mixins/databaseTriggerDebug.js';

export default {
  name: 'CommonTableContainer',
  inject: ['EDITOR_pageUUID', 'repeat'],
  mixins: [mixin, databaseTriggerDebug],
  components: {
    'el-table': Table,
    'el-table-column': TableColumn,
    'el-pagination': Pagination,
    'el-image': Image,
    'el-switch': Switch,
    'el-empty': Empty,
    'el-popover': Popover,
    Spreadsheet,
  },
  data() {
    return {
      TYPEMAP: {
        dataList: 'table',
        dataInfo: 'form',
      },
      // 当前组件唯一Key
      boxId: `table-container-${new Date().getTime()}`,
      // 分页
      pager: {
        current_page: 1,
        per_page: 15,
        total: 0,
      },
      pageSizes: [15, 30, 50, 100],
      // 表格数据
      tableData: [],
      // 表格高度
      height: 250,
      // 配置数据
      metadata: [],
      loading: false,
      // 缓存的搜索条件
      search: [],
      // 周几
      weekDays: ['周天', '周一', '周二', '周三', '周四', '周五', '周六'],
      // 默认图片
      defaultImage: require('@/assets/images/defaultImage.png'),
      param: {},
      // 树形图
      treeProps: {},
      rowKey: '',
      // 表格弹窗
      showSheet: false,
      // 表格数据
      sheetFormData: null,
      exportConfig: null,
      // 操作类型：add - 新增，edit - 编辑，info - 详情，export - 导出
      spreadsheetType: 'export',
      exportViewParams: {},
      exportViewSearch: [],
      // 使用后端返回配置导出的导出配置
      exportWay: '', // 导出配置 eeConfig 后端配置， '' 自定义
      eeExportTemplate: '',
      eeObjectUUID: '',
      eeViewUUID: '',
      fieldForView: [],
      boxWidth: '100%',
      mainWidth: 'calc(100% - 0px)',
      // 用于表单的暂存选中
      selectCacheData: [],
      getDefaultSelectEd: false, // 是否已经获取过默认
      // 导出附加参数
      exportParam: {},
      // 加载完毕
      tableMounted: false,
      // 自动滚动
      rollTimer: '', // 自动滚动的定时任务
      rollPx: 1, //每次滚动距离px
      showTable: true
    };
  },
  created() {
    // 分页器
    const {
      defaultPerPage = 15,
      customPagination = false,
      perList = '15,30, 50, 100',
    } = this.statusConfig;
    this.pager.per_page = defaultPerPage;
    let pageSizes = customPagination ? perList.split(',') : this.pageSizes;
    if (
      !pageSizes.includes(+defaultPerPage) &&
      !isNaN(+defaultPerPage) &&
      defaultPerPage !== ''
    ) {
      pageSizes.push(+defaultPerPage);
    }
    const result = [];
    pageSizes.forEach((ele) => {
      if (!isNaN(+ele)) {
        result.push(+ele);
      }
    });
    result.sort((a, b) => a - b);
    this.pageSizes = Array.from(new Set(result));
    // 请求数据
    this.doInterface();
    this.metadata = this.element.metadata || [];
    this.setInterval();
  },
  computed: {
    ...mapState(['componentData']),
    // 数据准备完毕
    dataYet() {
      if (
        this.statusConfig &&
        ((!this.statusConfig.isTreeTable && !this.statusConfig.feTreeKey) ||
          (this.statusConfig.isTreeTable && this.rowKey) ||
          (this.statusConfig.feTreeKey && this.rowKey)) &&
        this.fieldConfig
      ) {
        return true;
      }
      return false;
    },
    // 数据仓库配置
    database() {
      return this.element && this.element.database;
    },
    // 操作类型
    actionConfig() {
      return this.element && this.element.actionConfig;
    },
    actionList() {
      let result = [];
      for (let key in this.actionConfig) {
        if (
          Object.prototype.toString.call(this.actionConfig[key]) ===
          '[object Object]'
        ) {
          result.push(this.actionConfig[key]);
        }
      }
      result.sort((a, b) => {
        return a.order - b.order;
      });
      return result;
    },
    // 子表操作配置
    nestingActionConfig() {
      return this.element && this.element.nestingActionConfig;
    },
    nestingActionList() {
      let result = [];
      for (let key in this.nestingActionConfig) {
        if (
          Object.prototype.toString.call(this.nestingActionConfig[key]) ===
          '[object Object]'
        ) {
          result.push(this.nestingActionConfig[key]);
        }
      }
      result.sort((a, b) => {
        return a.order - b.order;
      });
      return result;
    },
    // 配置
    statusConfig() {
      return this.element && this.element.statusConfig;
    },
    // 字段配置
    fieldConfig() {
      const configs = this.element && this.element.fieldConfig;
      if (!configs) return [];
      if (!configs && !configs.length) return this.metadata || [];
      const result = [];
      configs.forEach((ele) => {
        if (ele && ele.show) {
          result.push(ele);
        }
      });
      return result;
    },
    // 字表字段配置
    nestingFieldConfig() {
      const configs = this.element && this.element.nestingFieldConfig;
      if (!configs) return [];
      if (!configs && !configs.length) return this.metadata || [];
      const result = [];
      configs.forEach((ele) => {
        if (ele && ele.show) {
          result.push(ele);
        }
      });
      return result;
    },
    // 前端排序
    useSort() {
      return !!this.element?.statusConfig?.useSort;
    },
    // 排序字段类表
    sortConfigs() {
      return this.fieldConfig?.filter((ele) => ele.bySort);
    },
    // 固定列
    fixColumns() {
      const { fixColumn = '' } = this.statusConfig;
      if (!fixColumn) return [];
      let fixStr = fixColumn.replace('F', 1);
      const arr = fixStr.split(',');
      const result = arr.filter((ele) => !!ele);
      return result;
    },
    fixedIndexRow() {
      if (!this.fixColumns.length) return false;
      if (this.fixColumns.length === 1 && this.fixColumns[0] !== 'T')
        return true;
      for (let i = 0; i < this.fixColumns.length; i++) {
        if (!isNaN(+this.fixColumns[i])) {
          return true;
        }
      }
      return false;
    },
  },
  watch: {
    // 是否设置头部的样式
    dataYet: {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            setTimeout(() => {
              this.tableMounted = true;
            }, 1000);
          });
        }
      },
      deep: true,
      immediate: true,
    },
    // 属性是浅拷贝？
    statusConfig: {
      handler(val) {
        if (val?.autoRoll) {
          this.autoRoll();
          const content = document.querySelector(`#${this.boxId}`);
          content?.childNodes[0]?.childNodes[2]?.classList.add(
            'hidden-scrollbar'
          );
          if (!content) return;
          content.addEventListener(
            'mouseenter',
            () => {
              this.mouseEnter();
            },
            true
          );
          content.addEventListener(
            'mouseleave',
            () => {
              this.mouseLeave();
            },
            true
          );
        } else {
          const content = document.querySelector(`#${this.boxId}`);
          content?.childNodes[0]?.childNodes[2]?.classList.remove(
            'hidden-scrollbar'
          );
          this.autoRoll(true);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.$store.commit('modifyComponent', {
      component: {
        ...this.element,
        resolveData: [],
      },
      containerId: null,
      isModify: true,
      pageUUID: this.EDITOR_pageUUID,
    });
    setTimeout(() => {
      this.getTableSize();
    }, 0);
    window.onresize = () => {
      this.getTableSize();
    };
    // 导出
    const exportData = {
      [this.element.id]: (exportConfig) => {
        if (!exportConfig || exportConfig.componentId !== this.element.id)
          return;
        this.exportConfig = exportConfig;
        this.doExport(exportConfig);
      },
    };
    // 监听请求
    // 配置关联参数的容器才需要监听
    const databaseTrigger = {
      [this.element.id]: (data) => {
        // console.log('配置关联参数的容器才需要监听', data, '-----', this.element.name);
        const { isSearch = false, isUpdate = false } = data;
        if (
          (data.parentId && data.parentId !== this.element.id) ||
          data?.componentId.includes('-page')
        )
          return false;
        // 配置时候触发请求
        if (data.componentId === this.element.id && data.isInit) {
          this._startDataDebug();
          const {
            search = [],
            param = {},
            canPost,
          } = initParams(
            this.element?.database?.paramsConfig || [],
            this.isGroup,
            this.subComponentData,
            this.groupComponents,
            this.EDITOR_pageUUID
          );
          // console.log("配置时候触发请求-------", canPost, search, param);
          if (!canPost) {
            this._failDataDebug('参数必填校验未通过');
            return;
          }
          if (isSearch) {
            this.pager.current_page = 1;
          }
          this.getList(this.element.database, search, param);
          return;
        }
        // 点击操作时候不更新数据
        if (data.noUpdate) return;
        const { paramsConfig } = this.element.database;
        if (!paramsConfig?.length) {
          this._startDataDebug();
          if (isSearch) {
            this.pager.current_page = 1;
          }
          this.getList(this.element.database);
          return;
        }
        let isTarget = false;
        paramsConfig.forEach((ele) => {
          if (ele.componentId === data.componentId) {
            isTarget = true;
          }
        });
        if (!isTarget && !data.isInit && !data.isUpdate) return;
        this._startDataDebug();
        // 以下步骤是为了避免有多个来源的search需要进行differ 避免检索结果错误情况
        let {
          search = [],
          param = {},
          canPost,
        } = initParams(
          this.element?.database?.paramsConfig || [],
          this.isGroup,
          this.subComponentData,
          this.groupComponents,
          this.EDITOR_pageUUID
        );
        // console.log("以下步骤是为了避免有多个来源的search需要进行differ-------", canPost, search, param);
        if (!canPost) {
          this._failDataDebug('参数必填校验未通过');
          return;
        }
        this.param = param;
        if (isSearch && isUpdate) {
          this.pager.current_page = 1;
        }
        this.getList(this.element.database, search, param);
      },
    };
    eventBus.$on('databaseTrigger', databaseTrigger[this.element.id]);
    eventBus.$on('exportData', exportData[this.element.id]);
  },
  methods: {
    // 判断架构及权限
    judgingArchiAPermission,
    judgingUILogic,
    /**
     * @description: 获取图片列表
     * @param {Array} imgs 图片列表
     * @return {Array} 预览了列表
     */
    getImageList(imgs) {
      if (!Array.isArray(imgs) || !imgs.length) {
        return [this.defaultImage];
      }
      return imgs.map((ele) => {
        return ele.url?.toString().replace('_thumb', '');
      });
    },
    /**
     * @description: 获取省略号行数
     * @param {null/1} row
     * @return {*}
     */
    getEllipsisClass(row = 1) {
      let count = +row;
      if (isNaN(count) || count < 1 || count > 9) {
        count = 1;
      }
      if (!count) return '';
      if (+count === 1) {
        return 'ellipsis-1';
      }
      return `ellipsis ellipsis-${count}`;
    },
    /**
     * @desc 自动滚动
     * @stop {boolean}
     */
    autoRoll(stop = false) {
      if (stop || !this.tableData.length) {
        if (this.rollTimer) {
          clearInterval(this.rollTimer);
          this.rollTimer = '';
        }
        return;
      }
      if (!this.statusConfig.autoRoll) return;
      const table = this.$refs?.[this.element?.id];
      if (!table) return;
      const divData = table?.bodyWrapper;
      if (!this.rollTimer) {
        this.rollTimer = setInterval(() => {
          if (
            Math.abs(
              divData.childNodes[0].clientHeight - divData.clientHeight
            ) < 20
          ) {
            clearInterval(this.rollTimer);
            return;
          }
          divData.scrollTop += this.rollPx;
          if (
            divData.clientHeight + divData.scrollTop >=
            divData.scrollHeight
          ) {
            divData.scrollTop = 0;
          }
        }, +this.statusConfig.rollTime);
      }
    },
    mouseEnter() {
      this.autoRoll(true);
    },
    mouseLeave() {
      this.autoRoll();
    },
    /**
     * @description: 执行请求
     */
    doInterface() {
      if (!this.interfaceFinished) return;
      this.interfaceFinished = false;
      if (this.database && !this.hasComponentParam()) {
        this._startDataDebug();
        const {
          search = [],
          param = {},
          canPost,
        } = initParams(
          this.element?.database?.paramsConfig || [],
          this.isGroup,
          this.subComponentData,
          this.groupComponents,
          this.EDITOR_pageUUID
        );
        if (!canPost) {
          this._failDataDebug('参数必填校验未通过');
          return;
        }
        this.getList(this.database, search, param);
      }
    },
    /**
     * @desc: 判断是否为逻辑空(空对象，空数组等)
     * @param {Number} val
     */
    isNull(val) {
      if (val === 0) return false;
      if (val === '00000-00-00 00:00:00') return true;
      if (Array.isArray(val) && !val.length) return true;
      if (
        Object.prototype.toString.call(val) === '[object Object]' &&
        JSON.stringify(val) === '{}'
      )
        return true;
      if (!val) return true;
      return false;
    },
    /**
     * @desc: 根据配置逻辑判断操作是否显示
     * @param {Object} actionConfig
     */
    logicAction(actionConfig, row) {
      if (
        !actionConfig.useDepend ||
        !actionConfig ||
        !actionConfig.dependLogic ||
        !actionConfig.dependLogic.length
      ) {
        return true;
      }
      const dependLogic = actionConfig.dependLogic;
      const logicList = [];
      for (let i = 0, len = dependLogic.length; i < len; i++) {
        const { field, fieldAttr, fieldLogic, itemLogic, value, valueType } =
          dependLogic[i];
        let logicValue = null;
        if (fieldAttr === 'length') {
          logicValue = row[field].length;
        } else {
          logicValue = row[field];
        }
        // 当前项判断条件
        // 字段关系 大于>  小于 < 等于 === 大于等于>=  小于等于 <= 不等于 !=
        let itemBoolean = true;
        if (fieldLogic === '===') {
          if (valueType === 'string') {
            itemBoolean = `${logicValue}` === `${value}`;
          } else if (valueType === 'number') {
            itemBoolean = +logicValue === +value;
          } else if (valueType === 'date') {
            itemBoolean = new Date(logicValue).getTime() === value;
          }
        } else if (fieldLogic === '!=') {
          if (valueType === 'string') {
            itemBoolean = `${logicValue}` !== `${value}`;
          } else if (valueType === 'number') {
            itemBoolean = +logicValue !== +value;
          } else if (valueType === 'date') {
            itemBoolean = new Date(logicValue).getTime() !== value;
          }
        } else if (fieldLogic === '>') {
          if (valueType === 'number') {
            itemBoolean = +logicValue > +value;
          } else if (valueType === 'date') {
            itemBoolean = new Date(logicValue).getTime() > value;
          }
        } else if (fieldLogic === '<') {
          if (valueType === 'number') {
            itemBoolean = +logicValue < +value;
          } else if (valueType === 'date') {
            itemBoolean = new Date(logicValue).getTime() < value;
          }
        } else if (fieldLogic === '<=') {
          if (valueType === 'number') {
            itemBoolean = +logicValue <= +value;
          } else if (valueType === 'date') {
            itemBoolean = new Date(logicValue).getTime() <= value;
          }
        } else if (fieldLogic === '>=') {
          if (valueType === 'number') {
            itemBoolean = +logicValue >= +value;
          } else if (valueType === 'date') {
            itemBoolean = new Date(logicValue).getTime() >= value;
          }
        } else if (fieldLogic === 'like') {
          if (valueType === 'string') {
            itemBoolean = logicValue.toString()?.includes(value.toString());
          }
        } else if (fieldLogic === 'whereIn') {
          // 包含
          try {
            let valArr = [];
            if (Array.isArray(logicValue)) {
              valArr = logicValue;
              itemBoolean = valArr.includes(value) || valArr.includes(+value);
            } else if (
              logicValue.toString()?.startsWith('[') &&
              logicValue.toString()?.endsWith(']')
            ) {
              valArr = JSON.parse(logicValue);
              itemBoolean = valArr.includes(value) || valArr.includes(+value);
            } else if (
              value.toString()?.startsWith('[') &&
              value.toString()?.endsWith(']')
            ) {
              valArr = JSON.parse(value);
              itemBoolean =
                valArr.includes(logicValue) || valArr.includes(+logicValue);
            } else {
              itemBoolean = valArr.includes(value) || valArr.includes(+value);
            }
          } catch (err) {
            console.log(err);
          }
        }
        logicList.push({ itemLogic, itemBoolean });
      }
      if (logicList.length === 1) {
        return logicList[0].itemBoolean;
      }
      // 循环逻辑关系
      let prevLogicBoolean = null;
      for (let j = 1; j < logicList.length; j++) {
        const prev = logicList[j - 1];
        const prevItemLogic = prev.itemLogic;
        if (prevItemLogic === 'and') {
          if (j === 1) {
            prevLogicBoolean = prev.itemBoolean && logicList[j].itemBoolean;
          } else {
            prevLogicBoolean = prevLogicBoolean && logicList[j].itemBoolean;
          }
          // 此处判断有问题 但暂不能动，否则老配置有问题
          if (!prevLogicBoolean) {
            return false;
          }
        } else if (prevItemLogic === 'or') {
          if (j === 1) {
            prevLogicBoolean = prev.itemBoolean || logicList[j].itemBoolean;
          } else {
            prevLogicBoolean = prevLogicBoolean || logicList[j].itemBoolean;
          }
          // 此处判断有问题 但暂不能动，否则老配置有问题
          if (!prevLogicBoolean) {
            return false;
          }
        }
      }
      return true;
    },
    /**
     * @desc: 判断是否存在依赖其他组件的取值
     */
    hasComponentParam() {
      if (!this.database.paramsConfig || !this.database.paramsConfig.length) {
        return false;
      }
      for (let i = 0; i < this.database.paramsConfig.length; i++) {
        const {
          componentId = '',
          key = '',
          sourceType = '',
        } = this.database.paramsConfig[i];
        if (
          (key !== 'search' || !componentId.includes('CommonForm')) &&
          sourceType !== 'url'
        ) {
          // componentId.includes('CommonTableContainer')兼容跨页请求的动态判定
          // 补充不同架构问题
          if (
            !componentId.includes('CommonTableContainer') &&
            this.paramsSameArchi(componentId)
          ) {
            return true;
          }
        }
      }
      return false;
    },
    /**
     * @desc: 判断依赖的参数是否在当前架构下启用(@凌志华树形图架构限制)
     * @param {String} componentId
     * @return {Boolean}
     */
    paramsSameArchi(componentId) {
      let comp = getComponentById(this.subComponentData, componentId);
      if (!comp && this.isGroup && this.groupComponents.length) {
        comp = getComponentById(this.groupComponents, componentId);
      }
      if (!comp) return false;
      const targetArchi = this.$GetTargetArchi('archiType');
      if (comp?.archiLimit?.length && comp.archiLimit.includes(targetArchi)) {
        return true;
      }
      return false;
    },
    /**
     * @desc: 获取渲染列表
     * @param {Object} database 数据配置对象
     * @param {Array} search 搜索
     */
    getList(database, search = [], params = {}) {
      this.search = Array.isArray(search) ? search : [];
      this.tableData = [];
      if (!this.validDatabase(database)) {
        this.interfaceFinished = true;
        this._failDataDebug('请求配置错误');
        return;
      }
      // 外部参数
      // 注入的参数
      let outParams = {};
      if (this.element.database.userOutParams) {
        outParams = sessionStorage.getItem(
          `dialogRelationParams_${
            this.EDITOR_pageUUID || this.$route.query.pageUUID
          }`
        );
        outParams = outParams ? JSON.parse(outParams) : {};
      }
      this.loading = true;
      console.log(this.pager);
      const paramsObj = {
        ...params,
        ...outParams,
        page: this.pager.current_page || 1,
        size: this.statusConfig.pagination ? this.pager.per_page || 15 : 15,
        search,
      };
      // 配置
      let __method_name__ = 'dataList';
      const mapping = database.mapping;
      let configObj = null;
      let url = '/api/mapi';
      if (mapping === 'interface') {
        configObj = {
          ...paramsObj,
        };
        if (database?.interfaceConfig?.url?.indexOf?.('http') === -1) {
          url = `/api${database?.interfaceConfig?.url}`;
        } else {
          url = database?.interfaceConfig?.url;
        }
      } else if (mapping === 'object') {
        configObj = {
          __method_name__,
          object_uuid: database.objectData.uuid,
          view_uuid: database.viewData.uuid,
          ...paramsObj,
        };
      } else if (mapping === 'relation') {
        __method_name__ = 'relationList';
        configObj = {
          __method_name__: 'relationList',
          object_uuid: database.objectData.uuid,
          relationship_uuid: database.relationData.uuid,
          ...paramsObj,
        };
      }
      // 将使用的字段添加到请求用于后端请求优化(隐式使用的字段分析问题？暂时注释)
      // configObj = {
      // 	...configObj,
      // 	__page_uuid__ : this.getPageUUID(), // 页面UUID
      // 	__component_id__: this.element?.id, // 组件ID
      // 	__selects__: this.getTableFields() // 使用的数组
      // }
      // 获取表格数据
      dataInterface(configObj, url)
        .then((res) => {
          if (res && res.status === 200) {
            let tableData = [];
            if (
              mapping === 'interface' ||
              ['dataList', 'relationList'].includes(__method_name__)
            ) {
              // 列表数据
              tableData = this.getListFromRes(res, true) || [];
            }
            for (let i = 0; i < this.fieldConfig.length; i++) {
              const item = this.fieldConfig[i];
              if (item.type === 7) {
                // switch 编辑
                tableData = tableData.map((ele) => {
                  if (item.switchOptions.type === 'number') {
                    // 数字
                    ele[item.uuid] =
                      +ele[item.uuid] === +item.switchOptions.trueValue;
                  } else if (item.switchOptions.type === 'boolean') {
                    if (ele[item.uuid] === 'true') {
                      ele[item.uuid] = true;
                    } else if (ele[item.uuid] === 'false') {
                      ele[item.uuid] = false;
                    }
                  } else if (item.switchOptions.type === 'string') {
                    ele[item.uuid] =
                      ele[item.uuid] === item.switchOptions.trueValue;
                  }
                  return ele;
                });
              }
            }
            // 字段列表
            if (!this.metadata || !this.metadata.length) {
              let metadata = {};
              if (mapping === 'interface') {
                // 接口
                if (Array.isArray(tableData)) {
                  if (
                    Object.prototype.toString.call(tableData?.[0]) ===
                    '[object Object]'
                  ) {
                    for (let key in tableData?.[0]) {
                      metadata[key] = key;
                    }
                  }
                }
              } else {
                metadata = res.data.metadata;
              }
              this.metadata = metadata;
            }
            // 树形表格
            if (this.statusConfig.isTreeTable) {
              let children = '';
              for (let key in this.metadata) {
                if (this.metadata[key] === this.statusConfig.children) {
                  children = key;
                }
                this.rowKey = this.getIdUUID();
              }
              tableData = this.formatTreeData(tableData, children);
              this.treeProps = {
                children: 'children',
                hasChildren: 'hasChildren',
              };
            } else if (this.statusConfig.useFeWBS) {
              // 非树形WBS
              tableData = this.formatTreeData(tableData, '');
            }
            // 非树形表格
            if (!this.statusConfig.isTreeTable && this.statusConfig.feTreeKey) {
              const { feTreeKey } = this.statusConfig;
              this.rowKey = this.getIdUUID();
              this.treeProps = {
                children: 'children',
                hasChildren: 'hasChildren',
              };
              tableData = this.listToTree(
                tableData,
                feTreeKey,
                this.getIdUUID()
              );
              // console.log(this.rowKey, '---123123----', this.treeProps);
            }
            // 嵌套表格
            if (this.statusConfig.isNesting) {
              this.rowKey = '';
              let children = '';
              for (let key in this.metadata) {
                if (this.metadata[key] === this.statusConfig.nestingKey) {
                  children = key;
                }
                this.rowKey = this.getIdUUID();
              }
              tableData = tableData.map((dataItem) => {
                return {
                  ...dataItem,
                  children: dataItem[children] || [],
                };
              });
              // 字段处理
              for (let i = 0; i < this.nestingFieldConfig.length; i++) {
                const item = this.nestingFieldConfig[i];
                if (item.type === 7) {
                  // switch 编辑
                  tableData = tableData.map((ele) => {
                    const childrenTable = ele[children] || [];
                    return {
                      ...ele,
                      [children]: childrenTable.map((child) => {
                        if (item.switchOptions.type === 'number') {
                          // 数字
                          child[item.uuid] =
                            +child[item.uuid] === +item.switchOptions.trueValue;
                        } else if (item.switchOptions.type === 'boolean') {
                          if (child[item.uuid] === 'true') {
                            child[item.uuid] = true;
                          } else if (child[item.uuid] === 'false') {
                            child[item.uuid] = false;
                          }
                        } else if (item.switchOptions.type === 'string') {
                          child[item.uuid] =
                            child[item.uuid] === item.switchOptions.trueValue;
                        }
                        return {
                          ...child,
                        };
                      }),
                    };
                  });
                }
              }
            }
            // 排序
            if (this.useSort && this.sortConfigs && this.sortConfigs.length) {
              tableData = this.customSort(tableData);
            }
            // console.log(tableData, '----tableData----');
            this.tableData = tableData;
            if (this.statusConfig?.canSelect) {
              // 获取默认选中
              if (
                this.defaultTableSelectData &&
                this.defaultTableSelectData.length &&
                !this.repeat
              ) {
                this.getDefaultSelect();
              }
            }
            // 完全数据 暂时保存
            this.fullData = res.data.data;
            // 保存容器数据
            this.$store.commit('modifyComponent', {
              component: {
                ...this.element,
                containerData: this.tableData,
                fullData: this.fullData,
                metadata: this.metadata,
                database: {
                  ...this.element.database,
                  fieldList: this.getFieldList(this.metadata),
                },
              },
              containerId: null,
              isModify: true,
              pageUUID: this.EDITOR_pageUUID,
            });
            this.$store.commit('updatePageCustomStatus', {
              origin: {
                ...this.element,
                containerData: this.tableData,
                fullData: this.fullData,
                metadata: this.metadata,
                database: {
                  ...this.element.database,
                  fieldList: this.getFieldList(this.metadata),
                },
              },
              resolveData: null,
            });
          }
          this._successDataDebug({
            url,
            content: configObj,
            res,
          });
          this.interfaceFinished = true;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.interfaceFinished = true;
          this.loading = false;
          this._errorDataDebug({
            url,
            content: configObj,
            err,
          });
        });
    },
    /**
     * @desc: 前端树形图格式化@蒲亚军
     * @param {Array} arr 平铺列表
     * @param {String} parentUUID 上级字段UUID
     * 首级应当是不存在parentUUID 或 parentId 为 0
     */
    listToTree(arr = [], parentUUID = '', idUUID) {
      let result = [];
      // result = arr.filter(ele => !ele?.[parentUUID]); 弃用
      arr.forEach((ele) => {
        const parentId = ele?.[parentUUID];
        if (!parentId || !arr.find((item) => item?.[idUUID] === parentId)) {
          result.push(ele);
        }
      });
      return this.getCustomChildren(result, parentUUID, idUUID, arr);
    },
    /**
     * @desc: 获取自定义下级
     */
    getCustomChildren(result = [], parentUUID, idUUID, allData, level = 0) {
      return result.map((item) => {
        const parentId = item?.[idUUID];
        let itemResult = {
          ...item,
          children: allData.filter((ele) => ele?.[parentUUID] === parentId),
        };
        if (itemResult?.children?.length) {
          itemResult = {
            ...itemResult,
            children: this.getCustomChildren(
              itemResult.children || [],
              parentUUID,
              idUUID,
              allData,
              level + 1
            ),
          };
        }
        return itemResult;
      });
    },
    /**
     * @desc: 格式化树形图
     * @param {Array} arr
     */
    formatTreeData(arr = [], key, level = 0, parent = '') {
      return arr.map((ele, index) => {
        const wbsString = this.getTreeWBS(level, index, parent);
        return {
          ...ele,
          wbsString,
          children: this.formatTreeData(
            ele[key] || [],
            key,
            level + 1,
            wbsString
          ),
        };
      });
    },
    /**
     * @desc: 获取树形WBS
     * @param {Number} level 层级
     * @param {Number} index 层级
     */
    getTreeWBS(level, index, parent = '') {
      const { useFeWBS } = this.statusConfig;
      if (!useFeWBS) return '';
      if (level === 0) {
        return `${index + 1}`;
      }
      return `${parent}.${index + 1}`;
    },
    /**
     * @desc: 前端自定义排序
     * @param {Array} arr
     * sortWay:排序顺序 ascending 升序， descending 降序
     */
    customSort(arr) {
      if (!arr || !Array.isArray(arr) || !arr.length) return [];
      let tableData = arr;
      try {
        this.sortConfigs.forEach((ele) => {
          const { uuid, sortWay } = ele;
          tableData.sort((a, b) => {
            return a?.[uuid] - b?.[uuid];
          });
          // 降序
          if (sortWay === 'descending') {
            tableData.reverse();
          }
        });
      } catch (e) {
        console.log(e, '前端排序故障---表格1');
      }
      return tableData;
    },
    /**
     * @desc: 获取页面UUID
     */
    getPageUUID() {
      return getQueryValue('pageUUID') || getQueryValue('origin_list_uuid');
    },
    /**
     * @desc: 获取页面使用的字段
     */
    getTableFields() {
      if (!this.fieldConfig || !Array.isArray(this.fieldConfig)) return [];
      const result = [];
      this.fieldConfig.forEach((item) => {
        if (item.show) {
          result.push(item.uuid);
        }
      });
      if (
        this.statusConfig.isNesting &&
        Array.isArray(this.nestingFieldConfig)
      ) {
        this.nestingFieldConfig.forEach((ele) => {
          if (ele.show) {
            result.push(ele.uuid);
          }
        });
      }
      return result || [];
    },
    /**
     * @desc: 获取字段列表
     * @param {Object} obj metadata对象
     */
    getFieldList(obj) {
      const arr = [];
      if (obj && typeof obj === 'object') {
        for (let key in obj) {
          arr.push({
            name: obj[key],
            uuid: key,
          });
        }
      }
      return arr;
    },
    /**
     * @desc: 切换switch操作状态
     * @param {Object} item 配置
     * @param {Boolean} data 当前切换的布尔值
     * @param {Object} row 当前行
     * @param {Boolean} isNesting 是否为子表操作
     */
    onSwitch(item, data, row, isNesting) {
      this.loading = true;
      const idUUID = !isNesting
        ? this.getIdUUID()
        : this.statusConfig.nestingRowKey;
      // 需要将boolean还原为后端允许的值
      let object_uuid = this.element?.database?.objectData?.uuid;
      if (isNesting && this.statusConfig?.nestingObjectUUID) {
        object_uuid = this.statusConfig?.nestingObjectUUID;
      }
      if (!object_uuid) {
        this.loading = false;
        this.$message.error('操作失败！');
        return false;
      }
      const params = {
        __method_name__: 'updateData',
        object_uuid,
        data_id: row[idUUID],
        ...row,
      };
      const value = data
        ? item.switchOptions.trueValue
        : item.switchOptions.falseValue;
      this.$set(params, item.uuid, value);
      dataInterface(params)
        .then(() => {
          this.loading = false;
          this.onPage(this.pager.current_page);
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    /**
     * @desc: 选择表格（全选）
     */
    onSelectionChange(selectList) {
      this.handleSelectionChange(selectList);
    },
    /**
     * @desc: 点击行内复选框选择表格
     * @param {Array} selectList
     */
    handleSelectionChange(selectList) {
      this.element.cacheData = selectList || [];
      // 去除当前页未选中
      this.selectCacheData = unique(this.selectCacheData.concat(selectList));
      this.$store.commit('modifyComponent', {
        component: {
          ...this.element,
          resolveData: this.selectCacheData || [],
        },
        containerId: null,
        isModify: true,
        pageUUID: this.EDITOR_pageUUID,
      });
      this.removeTargetLess(selectList);
      // 透传选中数据
      eventBus.$emit('EDITOR_setTableSelectData', {
        idUUID: this.getIdUUID(),
        data: this.selectCacheData,
      });
    },
    /**
     * @desc: 获取默认选中
     */
    async getDefaultSelect() {
      if (!this.getDefaultSelectEd) {
        this.selectCacheData = await this.getDataList(
          this.defaultTableSelectData,
          this.element?.database
        );
        this.getDefaultSelectEd = true;
      }
      // 设置当前选中
      this.$nextTick(() => {
        this.setDefaultSelect(this.tableData);
      });
    },
    /**
     * @desc: 获取数据列表
     */
    getDataList(value = [], database) {
      const idUUID = this.getIdUUID();
      return new Promise((resolve, reject) => {
        const { param = {} } = initParams(
          this.element?.database?.paramsConfig || [],
          this.isGroup,
          this.subComponentData,
          this.groupComponents,
          this.EDITOR_pageUUID
        );
        dataInterface({
          __method_name__: 'dataList',
          object_uuid: database?.objectData?.uuid,
          view_uuid: database?.viewData?.uuid,
          ...param,
          search: [
            {
              field_uuid: idUUID,
              ruleType: 'in',
              value,
            },
          ],
        })
          .then((res) => {
            // view61b30d8ecd648
            let result = res?.data?.data;
            if (!result || !Array.isArray(result)) {
              result = res?.data?.data?.data;
            }
            resolve(result || []);
          })
          .catch((err) => {
            console.log(err);
            reject([]);
          });
      });
    },
    /**
     * @desc: 去除当前页未选中
     */
    removeTargetLess(selectList) {
      const idUUID = this.getIdUUID();
      const pageSelectIds = selectList.map((ele) => ele[idUUID]);
      let targetIds = this.tableData.map((ele) => ele[idUUID]);
      let lessIDs = targetIds.filter((ele) => {
        return !pageSelectIds.includes(ele);
      });
      this.selectCacheData = this.selectCacheData.filter((ele) => {
        return !lessIDs.includes(ele[idUUID]);
      });
    },
    /**
     * @desc: 设置回显/默认选中行
     */
    setDefaultSelect(tableData) {
      if (!Array.isArray(this.selectCacheData) || !this.selectCacheData?.length)
        return;
      const idUUID = this.getIdUUID();
      tableData.forEach((ele) => {
        if (this.selectCacheData.find((item) => item[idUUID] === ele[idUUID])) {
          this.$nextTick(() => {
            this.$refs?.[this.element?.id]?.toggleRowSelection(ele, true);
          });
        }
      });
    },
    /**
     * @desc: 格式化比对收缩内容
     * @param {Array} search 当前组件中的搜索内容
     * @param {Array} outputSearch 外部传入的搜索项目
     */
    formatSearch(search, outputSearch) {
      if (!search.length) return outputSearch;
      let result = search || [];
      for (let i = 0; i < outputSearch.length; i++) {
        const item = outputSearch[i];
        const index = search.findIndex(
          (ele) => ele.field_uuid === item.field_uuid
        );
        if (index === -1) {
          result.push(item);
        } else {
          result.splice(index, 1, item);
        }
      }
      return result;
    },
    /**
     * @desc: 验证
     * @param {Object} database 数据仓库的绑定
     */
    validDatabase(database) {
      if (!database || typeof database !== 'object') return false;
      if (database.mapping === 'interface' && database.interfaceConfig)
        return true;
      if (!database.objectData) return false;
      if (!database.viewData && !database.relationData) return false;
      return true;
    },
    /**
     * @desc: 根据请求返回获取列表结构
     * @param {Object} res 请求返回的数据
     * @param {Boolean} withPager 是否需要重置pager
     */
    getListFromRes(res, withPager = true) {
      if (res.data && Array.isArray(res.data)) {
        if (withPager) {
          this.pager = res;
        }
        return res.data;
      }
      if (res.data && typeof res === 'object') {
        return this.getListFromRes(res.data, withPager);
      }
      return [];
    },
    /**
     * @desc: 点击行展开详情操作（@廖总）
     * @param {Object} row 行数据
     * @param {Object} column 点击列
     * @param {Number} index 下标
     */
    onRowClick(row) {
      this.element.resolveData = row;
      this.$store.commit('updatePageCustomStatus', {
        origin: this.element,
        resolveData: row,
      });
      if (!this.actionConfig?.detail || !this.statusConfig?.rowShowDetails) {
        return;
      }
      const { detail } = this.actionConfig;
      if (!detail || !detail.eventList || !detail.eventList.length) return;
      const comEvents = detail.eventList || [];
      for (let i = 0; i < comEvents.length; i++) {
        const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
        if (pattern === undefined) {
          this.onRowClickItem(comEvents[i], row);
          break;
        }
        const result = pattern === 'special' ? specialEventList : eventList;
        result.forEach((ele) => {
          if (ele.key === 'click') {
            this.onRowClickItem(ele, row);
          }
        });
      }
    },
    onRowClickItem(eventItem, row) {
      const { effects } = eventItem;
      if (effects && effects.length) {
        for (let j = 0; j < effects.length; j++) {
          const effectItem = effects[j];
          const { actions = [], targets = [] } = effectItem;
          if (actions.length && targets.length && actions.includes('show')) {
            targets.forEach((ele) => {
              if (ele?.toString()?.indexOf?.('CommonDialog-') === 0) {
                this.element.resolveData = row;
                this.$store.commit('updatePageCustomStatus', {
                  origin: this.element,
                  resolveData: row,
                });
                this.$store.commit('triggerEvents', {
                  config: {
                    ...eventItem,
                    ...effectItem,
                    actions: ['hidden'],
                  },
                  element: this.element,
                  EDITOR_pageUUID: this.EDITOR_pageUUID,
                });
                this.$nextTick(() => {
                  this.$store.commit('triggerEvents', {
                    config: {
                      ...eventItem,
                      ...effectItem,
                    },
                    element: this.element,
                    EDITOR_pageUUID: this.EDITOR_pageUUID,
                  });
                  this.$nextTick(() => {
                    let count = 0;
                    const interval = setInterval(() => {
                      count++;
                      const drawer = document.querySelector('.common-drawer');
                      if (drawer || count > 100) {
                        clearInterval(interval);
                        const drawerBox = drawer.parentNode?.parentNode;
                        if (drawerBox?.classList) {
                          drawerBox.classList.add('Editor-drawer__wrapper');
                        }
                      }
                    }, 100);
                  });
                });
              }
            });
          }
        }
      }
    },
    /**
     * @desc: 操作
     * @param {String} action 操作类型
     * @param {Object} output 输出的数据
     */
    onAction(action, output, e, type = 'main', actionConfig) {
      e.preventDefault();
      e.stopPropagation();
      // 是否为删除
      if (action === 'delete') {
        this.doDelete(output, type);
        return;
      }
      // 为兼容自定义export，多个导出
      if (action === 'export' && type === 'main') {
        // 行内导出
        const exportConfig = this.element.exportConfig;
        this.exportConfig = exportConfig;
        this.spreadsheetType = 'export';
        this.exportWay = exportConfig.exportWay || '';
        this.doExport(exportConfig, output, type);
        return;
      }
      // 其他导出
      if (action?.toString()?.indexOf?.('export') === 0) {
        // 行内导出
        const btnConfig = actionConfig[action];
        const eventItems = btnConfig?.eventList || [];
        let exportConfig = null;
        for (let i = 0; i < eventItems.length; i++) {
          const {
            pattern,
            eventList = [],
            specialEventList = [],
          } = eventItems[i];
          if (pattern === undefined) {
            exportConfig = eventItems[i];
            break;
          }
          const result = pattern === 'special' ? specialEventList : eventList;
          result.forEach((ele) => {
            if (ele.type === 'click') {
              exportConfig = ele;
            }
          });
        }
        exportConfig = exportConfig || {};
        exportConfig.exportType = 'targetRow';
        this.exportConfig = exportConfig;
        this.spreadsheetType = 'export';
        this.exportWay = exportConfig.exportWay || '';
        this.doExport(exportConfig, output, type);
      }
      this.element.resolveData = output;
      this.$store.commit('modifyComponent', {
        component: {
          ...this.element,
          resolveData: output,
        },
        containerId: null,
        isModify: true,
        pageUUID: this.EDITOR_pageUUID,
      });
      this.$store.commit('updatePageCustomStatus', {
        origin: this.element,
        resolveData: output,
      });
      // 详情
      if (action === 'detail' && this.statusConfig?.rowShowDetails) {
        this.onRowClick(output);
        return;
      }
      // 关闭所有弹窗及抽屉
      this.removeModel();
      // 事件
      const actionKey =
        type === 'nesting' ? 'nestingActionConfig' : 'actionConfig';
      const comEvents = this.element?.[actionKey]?.[action]?.eventList || [];
      for (let i = 0; i < comEvents.length; i++) {
        const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
        if (pattern === undefined) {
          this.doActionItem(comEvents[i], output, type);
          break;
        }
        const result = pattern === 'special' ? specialEventList : eventList;
        result.forEach((ele) => {
          this.doActionItem(ele, output, type);
        });
      }
      this.$nextTick(() => {
        eventBus.$emit('databaseTrigger', {
          componentId: this.element.id,
          action,
          output,
          isInit: false,
          noUpdate: true,
        });
      });
      return false;
    },
    async doActionItem(ele, output, type = 'main') {
      console.log('事件触发， --------', ele, output);
      const { frontOperation = [] } = ele;
      let canNext = true;
      if (frontOperation && frontOperation.length) {
        // 触发预处理事件、行为
        window.$EditorDebug.startFrontOperation(this.element);
        canNext = await doFrontOperation(frontOperation, {
          isGroup: this.isGroup,
          componentList: this.subComponentData,
          componentData: this.subComponentData,
          groupComponents: this.groupComponents,
        });
        window.$EditorDebug.resultFrontOperation(canNext, this.element);
      }
      // console.log(canNext, '----canNext-----2222')
      if (!canNext) {
        console.log('操作失败!');
        this.$message.error('操作失败！');
        return false;
      }
      if (ele.key === 'click') {
        // 跳转页面
        if (ele.actionType === 'jumpPage') {
          if (ele.linkType === 'projectPage') {
            const query = {};
            ele.queryList.forEach((queryItem) => {
              let component = getComponentById(
                this.subComponentData,
                queryItem.componentId
              );
              if (!component && this.isGroup && this.groupComponents.length) {
                component = getComponentById(
                  this.groupComponents,
                  queryItem.componentId
                );
              }
              this.$set(
                query,
                queryItem.key,
                component.resolveData[queryItem.feild]
              );
            });
            if (ele.routePath) {
              this.$router.replace({
                path: ele.routePath,
                query,
              });
            }
            // 基座设置
            // if (ele.routePath) {
            // 	this.$router.replace({
            // 		path: ele.routePath,
            // 		query
            // 	});
            // }
            return;
          } else if (ele.linkType === 'outPage') {
            // window.open(ele.linkUrl);
            openUrl(ele.linkUrl, ele.linkUrl);
          } else if (ele.linkType === 'custom') {
            const customLink = getLinkByTemplate(ele.linkTemplate, output);
            openUrl(customLink, customLink);
            // window.open(customLink);
          }
        } else if (ele.actionType === 'eeAction') {
          // 触发后端事件
          // console.log(ele, '0000001111111111', output);
          this.doEEAction(ele, output, type);
          // 搜集参数
        } else {
          // 页面事件
          ele.effects.forEach((effect) => {
            this.$store.commit('triggerEvents', {
              config: {
                ...ele,
                ...effect,
              },
              element: this.element,
              EDITOR_pageUUID: this.EDITOR_pageUUID,
            });
          });
        }
      }
      // 组件行为
      const { behaviors = [] } = ele;
      behaviors.forEach((behavior) => {
        this.$store.commit('triggerEvents', {
          config: {
            behavior,
            isBehavior: true,
          },
          element: this.element,
          EDITOR_pageUUID: this.EDITOR_pageUUID,
        });
      });
      // 导出事件(只能导出当前行的数据)
      if (ele.actionType === 'export') {
        this.doExport(ele, output, type);
      }
    },
    /**
     * @desc: 触发后端事件
     */
    doEEAction(btnConfig, rowData, type = 'main') {
      if (btnConfig && btnConfig.actionType === 'eeAction') {
        window.$EditorDebug.startEEAction(this.element);
        const { objectUUID, viewUUID, eventName, eeType, interfaceUrl } =
          btnConfig;
        if ((!objectUUID || !viewUUID || !eventName) && !interfaceUrl) {
          this.$message.error('请正确配置事件!');
          window.$EditorDebug.failEEAction(this.element);
          return false;
        }
        this.loading = true;
        const sourceParams = this.getBindParams(btnConfig);
        // 蓝图
        if (eeType === 'blueprint') {
          dataInterface(sourceParams, `/api${interfaceUrl}`)
            .then((res) => {
              if (res.status === 200 && res.data.code === 200) {
                this.$message.success('操作成功！');
                window.$EditorDebug.successEEAction(
                  this.element,
                  `/api${interfaceUrl}`
                );
                doEEActionHandle(res.data?.__adds__);
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
              window.$EditorDebug.errorEEAction(
                this.element,
                `/api${interfaceUrl}`
              );
            });
          return true;
        }
        const idUUID =
          type === 'main' ? this.getIdUUID() : this.statusConfig.nestingRowKey;
        const data_id = rowData?.[idUUID];
        if (!data_id) {
          this.$message.error('事件未配置触发对象!');
          return false;
        }
        this.loading = true;
        dataInterface({
          __method_name__: 'customEventCall',
          object_uuid: objectUUID,
          view_uuid: viewUUID,
          data_id,
          ...sourceParams,
          event: eventName,
        })
          .then((res) => {
            if (res.status === 200 && res.data.code === 200) {
              this.$message.success('操作成功！');
              window.$EditorDebug.successEEAction(this.element, eventName);
              doEEActionHandle(res.data?.__adds__);
              this.$loading().close();
              this.onPage(this.pager.current_page);
            }
            this.loading = false;
          })
          .catch((err) => {
            console.log(err, '00000后端事件错误');
            this.loading = false;
            window.$EditorDebug.errorEEAction(this.element, eventName);
          });
      }
    },
    /**
     * @desc: 后端事件获取绑定参数
     * @param {Object} comp 组件数据
     * @return {Object}
     */
    getBindParams(comp) {
      if (!comp) {
        window.$EditorDebug.descEEActionParam(1);
        return {};
      }
      window.$EditorDebug.descEEActionParam(2);
      const sourceConfig = comp.sourceConfig || comp.sourceList || [];
      const sourceParams = {};
      for (let i = 0; i < sourceConfig.length; i++) {
        const {
          componentId,
          field,
          key,
          originType = '',
          urlParamKey = '',
          systemKey = '',
          systemCode = '',
          fixedValue = '',
          statusCode = '',
        } = sourceConfig[i];
        if (originType === 'url' && urlParamKey) {
          // 从url获取参数
          const result = getQueryValue(urlParamKey);
          this.$set(sourceParams, key, result);
        } else if (originType === 'system') {
          // 系统参数
          try {
            let obj = sessionStorage.getItem(systemKey);
            if (!obj) {
              obj = localStorage.getItem(systemKey);
            }
            if (!obj) break;
            const result = JSON.parse(obj);
            if (
              result &&
              Object.prototype.toString.call(result) === '[object Object]'
            ) {
              const queryVal = result[systemCode];
              this.$set(sourceParams, key, queryVal);
            }
          } catch (err) {
            console.log(err, '99999999');
          }
        } else if (originType === 'fixed') {
          // 固定值
          this.$set(sourceParams, key, fixedValue);
        } else if (originType === 'pageStatus') {
          // 页面状态
          const statusCodeValue =
            this._PageCustomStatus[statusCode] === undefined
              ? this._APPCustomStatus[statusCode] || null
              : this._PageCustomStatus[statusCode];
          this.$set(sourceParams, key, statusCodeValue || '');
        } else if (componentId && field && key) {
          // 普通从组件获取
          console.log(
            this.subComponentData,
            '----com--',
            componentId,
            this.groupComponents
          );
          let sourceComponent = getComponentById(
            this.subComponentData,
            componentId
          );
          if (!sourceComponent && this.isGroup && this.groupComponents.length) {
            sourceComponent = getComponentById(
              this.groupComponents,
              componentId
            );
          }
          const componentName = componentId?.toString().split('-')[0];
          if (
            field === 'DATAVIEWSELECT' &&
            [
              'CommonTree',
              'CommonTableContainer',
              'CommonLoopContainer',
            ].includes(componentName)
          ) {
            this.$set(sourceParams, key, sourceComponent?.resolveData || []);
          } else {
            const result = sourceComponent?.resolveData?.[field] || '';
            this.$set(sourceParams, key, result);
          }
        }
      }
      window.$EditorDebug.successEEActionParam(sourceParams, comp.sourceConfig);
      return sourceParams;
    },
    /**
     * @desc: 移除所有显示弹窗
     */
    removeModel() {
      if (!this.actionConfig?.detail) return;
      const { detail } = this.actionConfig;
      if (!detail?.eventList?.length) return;
      const comEvents = detail?.eventList || [];
      for (let i = 0; i < comEvents.length; i++) {
        const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
        if (pattern === undefined) {
          if (comEvents[i].key === 'click') {
            comEvents[i].effects.forEach((effect = {}) => {
              const { targets = [] } = effect;
              if (!targets.find((ele) => ele.includes('CommonDialog'))) {
                // 非弹窗不执行
                return false;
              }
              this.$store.commit('triggerEvents', {
                config: {
                  ...comEvents[i],
                  ...effect,
                  actions: ['hidden'],
                },
                element: this.element,
                EDITOR_pageUUID: this.EDITOR_pageUUID,
              });
            });
          }
          break;
        }
        const result = pattern === 'special' ? specialEventList : eventList;
        result.forEach((ele) => {
          if (ele.key === 'click') {
            ele.effects.forEach((effect) => {
              const { targets = [] } = effect;
              if (!targets.find((ele) => ele.includes('CommonDialog'))) {
                // 非弹窗不执行
                return false;
              }
              this.$store.commit('triggerEvents', {
                config: {
                  ...ele,
                  ...effect,
                  actions: ['hidden'],
                },
                element: this.element,
                EDITOR_pageUUID: this.EDITOR_pageUUID,
              });
            });
          }
        });
      }
    },
    /**
     * @desc: 删除
     * @param {Object} data 删除行的数据
     */
    doDelete(data, type = 'main') {
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          const idUUID =
            type === 'main'
              ? this.getIdUUID()
              : this.statusConfig?.nestingRowKey;
          const data_id = data?.[idUUID];
          this.loading = true;
          let object_uuid = this.element?.database?.objectData?.uuid;
          if (type === 'nesting' && this.statusConfig?.nestingObjectUUID) {
            // 子表
            object_uuid = this.statusConfig?.nestingObjectUUID;
          }
          dataInterface({
            __method_name__: 'deleteData',
            object_uuid,
            data_id,
          })
            .then((res) => {
              this.loading = false;
              if (res.status === 200 && res.data?.code === 200) {
                // 获取数据
                this.onPage(1);
                this.$message.success('操作成功！');
                this.updateRelationComponent();
              } else {
                // this.$message.error('操作失败!');
              }
            })
            .catch((err) => {
              this.loading = false;
              console.log(err, '---err');
              // this.$message.error('操作失败!');
            });
        })
        .catch(() => {});
    },
    /**
     * @desc: 切换页码
     * @param {Number} current_page 当前页码
     */
    onPage(current_page) {
      this.pager = {
        ...this.pager,
        current_page,
      };
      let {
        search = [],
        param = {},
        canPost,
      } = initParams(
        this.element?.database?.paramsConfig || [],
        this.isGroup,
        this.subComponentData,
        this.groupComponents,
        this.EDITOR_pageUUID
      );
      if (canPost) {
        this.getList(this.database, search, param || {});
      }
    },
    /**
     * @desc: 切换分页参数
     * @param {Number} per_page 跳转的页码
     */
    onSize(per_page) {
      this.pager = {
        ...this.pager,
        current_page: 1,
        per_page,
      };
      let {
        search = [],
        param = {},
        canPost,
      } = initParams(
        this.element?.database?.paramsConfig || [],
        this.isGroup,
        this.subComponentData,
        this.groupComponents,
        this.EDITOR_pageUUID
      );
      if (canPost) {
        this.getList(this.database, search, param || {});
      }
    },
    /**
     * @desc: 获取状态值
     * @param {any普通类型} value 值
     * @param {Array} statusOptions 状态选项
     * @return {Object} 用于状态显示的对象  label value color
     */
    filterStatus(value, statusOptions = []) {
      if (!Array.isArray(statusOptions)) {
        return {
          label: value,
        };
      }
      for (let i = 0; i < statusOptions.length; i++) {
        if (
          (isNaN(value) && statusOptions[i].value === value) ||
          +statusOptions[i].value === +value
        ) {
          return statusOptions[i];
        }
      }
      return {
        label: value,
      };
    },
    /**
     * @desc: 格式化时间
     * @param {String/Object/Number} value 时间值 兼容时间戳、时间字符串、时间对象
     * @param {String} timeFormat 格式化
     * @param {String} connector 连接符
     */
    filterTime(value, timeFormat, connector = '') {
      if (!value) return '';
      // 时间格式表单无法统一不做校验
      // const reg = /^(\d{4})(-|\/)(\d{2})(-|\/)(\d{2})/;
      // if ((isNaN(+value) && !reg.test(value))) return '';
      let dateObj = null;
      if (value instanceof Date) {
        dateObj = value;
      } else if (isNaN(value)) {
        dateObj = new Date(value);
      } else {
        dateObj = new Date(value * 1000);
      }
      // @凌志华 后端返回的无效时间显示为 '' (2022-02-15 15:51:51)
      if (!(dateObj instanceof Date) || dateObj?.toString() === 'Invalid Date')
        return '';
      const year = dateObj.getFullYear();
      // 无效的时间
      if (year === 1970) return '';
      let month = this.addZero(dateObj.getMonth() + 1);
      const day = this.addZero(dateObj.getDate());
      const hour = this.addZero(dateObj.getHours());
      const minute = this.addZero(dateObj.getMinutes());
      const second = this.addZero(dateObj.getSeconds());
      let connectorStr = '-';
      if (connector === '/') {
        connectorStr = '/';
      } else if (connector === 'cn') {
        connectorStr = connector;
      }
      const defaultStr = `${year}${
        connectorStr === 'cn' ? '年' : connectorStr
      }${month}${connectorStr === 'cn' ? '月' : connectorStr}${day}${
        connectorStr === 'cn' ? '日' : ''
      } ${hour}${connectorStr === 'cn' ? '时' : ':'}${minute}${
        connectorStr === 'cn' ? '分' : ':'
      }${second}${connectorStr === 'cn' ? '秒' : ''}`;
      switch (timeFormat) {
        case 'YMDhms':
          return defaultStr;
        case 'YMDhm':
          return `${year}${
            connectorStr === 'cn' ? '年' : connectorStr
          }${month}${connectorStr === 'cn' ? '月' : connectorStr}${day}${
            connectorStr === 'cn' ? '日' : ''
          } ${hour}${connectorStr === 'cn' ? '时' : ':'}${minute}${
            connectorStr === 'cn' ? '分' : ''
          }`;
        case 'YMDh':
          return `${year}${
            connectorStr === 'cn' ? '年' : connectorStr
          }${month}${connectorStr === 'cn' ? '月' : connectorStr}${day}${
            connectorStr === 'cn' ? '日' : ''
          } ${hour}${connectorStr === 'cn' ? '时' : ''}`;
        case 'YMD':
          return `${year}${
            connectorStr === 'cn' ? '年' : connectorStr
          }${month}${connectorStr === 'cn' ? '月' : connectorStr}${day}${
            connectorStr === 'cn' ? '日' : ''
          }`;
        case 'YM':
          return `${year}${
            connectorStr === 'cn' ? '年' : connectorStr
          }${month}${connectorStr === 'cn' ? '月' : ''}`;
        case 'MD':
          return `${month}${connectorStr === 'cn' ? '月' : connectorStr}${day}${
            connectorStr === 'cn' ? '日' : ''
          }`;
        case 'hms':
          return `${hour}${connectorStr === 'cn' ? '时' : ':'}${minute}${
            connectorStr === 'cn' ? '分' : ':'
          }${second}${connectorStr === 'cn' ? '秒' : ''}`;
        case 'hm':
          return `${hour}${connectorStr === 'cn' ? '时' : ':'}${minute}${
            connectorStr === 'cn' ? '分' : ''
          }`;
        case 'yyyy':
          return `${year}${connectorStr === 'cn' ? '年' : ''}`;
        case 'mm':
          return `${month}${connectorStr === 'cn' ? '月' : ''}`;
        case 'dd':
          return `${day}${connectorStr === 'cn' ? '日' : ''}`;
        case 'weekDay':
          return this.weekDays[dateObj.getDay()];
        case 'week':
          return `第${this.getWeek(dateObj)}周`;
        default:
          return defaultStr;
      }
    },
    /**
     * @desc: 数字
     * @param {Number} value
     * @param {Object} numberFormat
     */
    filterNumber(value, numberFormat = {}) {
      if (isNaN(value) && isNaN(+value)) return value;
      const { type, decimalPlace, prefix = '', suffix = '' } = numberFormat;
      let result = value;
      if (type === 'float') {
        result = (+value).toFixed(isNaN(+decimalPlace) ? 0 : +decimalPlace);
      }
      return `${prefix} ${result} ${suffix}`;
    },
    /**
     * @desc: 补零
     * @param {Number} val 需要补零的值
     */
    addZero(val) {
      if (isNaN(val) || val < 0 || val >= 10) return val;
      return `0${val}`;
    },
    /**
     * @desc: 获取当前日期对象为全年第几周
     * @param {Object<Date>} dateObj 时间对象
     * @return {Number} 第几周
     */
    getWeek(dateObj) {
      const firstDay = new Date(`${dateObj.getFullYear()}-1-1`);
      const timeBetween = dateObj - firstDay;
      const between = timeBetween / (7 * 24 * 60 * 60 * 1000);
      return Math.ceil(between);
    },
    /**
     * @desc: 验证架构类型
     * @param {Array, String} types 类型 group company project
     */
    validArchiType(types) {
      if (!Array.isArray(types) || !types.length) return true;
      if (this.$GetUserInfo('name') === 'admin') return true;
      const archiType = this.$GetTargetArchi('archiType');
      if (archiType && types.includes(archiType)) {
        return true;
      }
      return false;
    },
    /**
     * @desc: 导出
     * @param {Object} config 配置
     */
    async doExport(config, rowData = null, type = 'main') {
      this.exportTableType = type;
      const loading = this.$loading({
        text: '导出中....',
      });
      window.$EditorDebug.startExport(this.element);
      // const loading = {};
      const { exportType = 'all' } = config;
      let idUUID =
        type === 'main' ? this.getIdUUID() : this.statusConfig?.nestingRowKey;
      if (!idUUID) {
        loading.close();
        this.$message.error('导出失败！数据验证错误。');
        window.$EditorDebug.failExport(this.element);
        return;
      }
      /* eslint-disable */
      // exportType 导出配置 all 全部， targetPage 本页，checked 当前选中
      // 最终导出数据
      // 导出全部
      if (exportType === 'targetPage') {
        if (!this.tableData?.length) {
          loading.close();
          this.$message.error('暂无数据可供导出！');
          window.$EditorDebug.failExport(this.element, '暂无数据可供导出！');
          return;
        }
        // 本页
        this.sheetFormData = [];
        this.tableData.forEach((ele) => {
          this.sheetFormData.push(ele[idUUID]);
        });
      } else if (exportType === 'checked') {
        if (!this.element?.cacheData?.length) {
          loading.close();
          this.$message.error('请选择需要导出的数据！');
          window.$EditorDebug.failExport(
            this.element,
            '请选择需要导出的数据！'
          );
          return;
        }
        this.sheetFormData = [];
        // 当前选中
        this.element.cacheData.forEach((ele) => {
          this.sheetFormData.push(ele[idUUID]);
        });
      } else if (exportType === 'all') {
        this.sheetFormData = 'all';
      } else if (exportType === 'searchAll') {
        this.sheetFormData = 'all';
        const queryData = initParams(
          this.element?.database?.paramsConfig || [],
          this.isGroup,
          this.subComponentData,
          this.groupComponents,
          this.EDITOR_pageUUID
        );
        this.exportViewParams = queryData.param || {};
        this.exportViewSearch = queryData.search;
      } else if (exportType === 'targetRow') {
        this.sheetFormData = rowData?.[idUUID];
        if (this.exportWay === 'eeConfig') {
          // 后端导出配置
          const { exportTemplateField, objectUUIDField, viewUUIDField } =
            config;
          if (!exportTemplateField || !objectUUIDField || !viewUUIDField) {
            loading.close();
            this.$message.error('导出配置错误！');
            window.$EditorDebug.failExport(this.element, '导出配置错误！');
            return false;
          }
          this.eeExportTemplate = rowData?.[exportTemplateField];
          this.eeObjectUUID = rowData?.[objectUUIDField];
          this.eeViewUUID = rowData?.[viewUUIDField];
          this.exportConfig = {
            ...this.exportConfig,
            exportTemplate: this.eeExportTemplate,
            objectUUID: this.eeObjectUUID,
            viewUUID: this.eeViewUUID,
          };
          if (
            !this.eeExportTemplate ||
            !this.eeObjectUUID ||
            !this.eeViewUUID
          ) {
            loading.close();
            this.$message.error('导出配置错误！');
            window.$EditorDebug.failExport(this.element, '导出配置错误！');
            return false;
          }
        }
      }
      if (!this.sheetFormData) {
        loading.close();
        this.$message.error('导出配置错误！');
        window.$EditorDebug.failExport(
          this.element,
          '导出配置错误！不存在导出数据'
        );
        return false;
      }
      // 参数
      if (config?.params) {
        const { param = {} } = initParams(
          config.params,
          this.isGroup,
          this.subComponentData,
          this.groupComponents,
          this.EDITOR_pageUUID
        );
        this.exportParam = param;
      }
      this.showSheet = true;
    },
    /**
     * @desc: 获取导出数据（改版暂时废弃）
     */
    getExportData(
      database,
      params,
      search,
      { objectUUID, viewUUID, exportRequest }
    ) {
      return new Promise((resolve, reject) => {
        if (!this.validDatabase(database)) {
          this._failDataDebug('请求配置错误');
          return;
        }
        const paramsObj = {
          ...params,
          page: 1,
          size: 9999,
        };
        // 配置
        let __method_name__ = exportRequest || 'dataList';
        let configObj = {
          __method_name__,
          object_uuid: objectUUID,
          view_uuid: viewUUID,
          ...paramsObj,
          search,
        };
        // 获取表格数据
        dataInterface(configObj)
          .then((res) => {
            if (res && res.status === 200) {
              let tableData = [];
              if (
                __method_name__ === 'dataList' ||
                __method_name__ === 'relationList'
              ) {
                // 列表
                // 列表数据
                tableData = this.getListFromRes(res, false) || [];
                for (let i = 0; i < this.fieldConfig.length; i++) {
                  const item = this.fieldConfig[i];
                  if (item.type === 7) {
                    // switch 编辑
                    tableData = tableData.map((ele) => {
                      if (item.switchOptions.type === 'number') {
                        // 数字
                        ele[item.uuid] =
                          +ele[item.uuid] === +item.switchOptions.trueValue;
                      } else if (item.switchOptions.type === 'boolean') {
                        if (ele[item.uuid] === 'true') {
                          ele[item.uuid] = true;
                        } else if (ele[item.uuid] === 'false') {
                          ele[item.uuid] = false;
                        }
                      } else if (item.switchOptions.type === 'string') {
                        ele[item.uuid] =
                          ele[item.uuid] === item.switchOptions.trueValue;
                      }
                      return ele;
                    });
                  }
                }
                // 树形表格
                if (this.statusConfig.isTreeTable) {
                  let children = '';
                  for (let key in this.metadata) {
                    if (this.metadata[key] === this.statusConfig.children) {
                      children = key;
                    }
                  }
                  tableData = tableData.map((dataItem) => {
                    return {
                      ...dataItem,
                      children: dataItem[children] || [],
                    };
                  });
                }
              } else if (__method_name__ === 'dataInfo') {
                // 详情
                tableData = res.data.data || {};
              }
              resolve(tableData);
            }
            this._successDataDebug({
              url: '',
              content: configObj,
              res,
            });
          })
          .catch((err) => {
            console.log(err);
            this._errorDataDebug({
              url: '',
              content: configObj,
              err,
            });
            reject([]);
          });
      });
    },
    /**
     * @desc: 导出成功
     */
    onExportSuccess() {
      this.$loading().close();
      this.showSheet = false;
      this.$message.success('导出成功！');
      this.exportParam = {};
      window.$EditorDebug.successExport(this.element);
    },
    /**
     * @desc: 导出错误
     * @param {String} msg 错误信息
     */
    onExportError(msg) {
      this.$loading().close();
      this.showSheet = false;
      this.$message.error(msg || '导出失败！');
      this.exportParam = {};
      window.$EditorDebug.errorExport(this.element);
    },
    /**
     * @desc: 获取数据主键，ID UUID
     */
    getIdUUID() {
      let idUUID = this.statusConfig.idUUID || '';
      if (!idUUID) {
        // let idCount = 0;
        for (let key in this.metadata) {
          if (this.metadata[key] === 'id') {
            // idCount++;
            idUUID = key;
          }
        }
        // // 编辑器提示
        // if (['/modify-page', '/page-view'].includes(this.$route.path)) {
        // 	if (idCount === 0) {
        // 		setTimeout(() => {
        //       this.$message.warning(`组件【${this.element.name}】数据接口无【id】字段返回，请检查接口配置！`);
        // 		}, 0);
        // 		return '';
        // 	}
        // 	if (idCount > 1) {
        // 		setTimeout(() => {
        // 			this.$message.warning(`组件【${this.element.name}】数据接口存在【${idCount}】个【id】字段返回，请检查接口配置！以保证准确性！`);
        // 		}, 0);
        // 		return '';
        // 	}
        // }
      }
      return idUUID;
    },
    /**
     * @desc: 获取表格尺寸(暂弃用)
     */
    getTableSize() {
      this.$nextTick(() => {
				const box = $(`#${this.boxId}`);
				const tableWrap = $('.el-table__body-wrapper');
				if (box && box.offsetHeight) {
					this.height = box.offsetHeight;
				}
				this.boxWidth = box?.offsetWidth ? `${box?.offsetWidth - 32}px` : 'calc(100% - 1px)';
				this.mainWidth = box?.offsetWidth ? `${box?.offsetWidth}px` : 'calc(100% - 0px)';
				if (tableWrap && box) {
					setTimeout(() => {
						tableWrap.style.height = `${box.offsetHeight}!important`;
					}, 10);
				}
			});
    },
    /* 处理当前选择的合并名称 */
    /**
     * @desc: 处理当前选择的合并名称
     * @param {Array} positionList 部位列表
     */
    getPositionName(positionList = []) {
      if (!Array.isArray(positionList) || positionList.length === 0) {
        return '/';
      }
      const currentList = JSON.parse(JSON.stringify(positionList));
      let str = '';
      const showArr = [];
      if (positionList?.length && Array.isArray(positionList)) {
        const obj = {};
        currentList.forEach((element) => {
          if (element.checkbox) {
            str += element.name;
          }
          if (!obj || !obj[element.type_id]) {
            obj[element.type_id] = [element];
          } else {
            obj[element.type_id].push(element);
          }
        });
        for (const key in obj) {
          let element = obj[key];
          if (['1', '5', '6'].includes(key)) {
            element.forEach((item) => {
              showArr.push(item.name);
            });
          } else if (key === '2') {
            // 构件
            const cateObj = {};
            element = this.unique(element, 'name');
            element.forEach((item) => {
              const splitArr = item.name.split('-');
              if (!cateObj?.[splitArr?.[0]]) {
                cateObj[splitArr[0]] = [item];
              } else {
                cateObj[splitArr[0]].push(item);
              }
            });
            for (const i in cateObj) {
              if (cateObj?.[i]?.length) {
                const name = cateObj[i][0].name.split('-')[0];
                let arr = [];
                cateObj[i].forEach((element) => {
                  arr.push(Number(element.name.split('-')[1]));
                });
                arr = [...new Set(arr)];
                const list = this.fn(arr, 1);
                list.forEach((element) => {
                  if (element.length > 1) {
                    showArr.push(
                      `${name}_${element[0]}-${element[element.length - 1]}`
                    );
                  } else {
                    showArr.push(`${name}_${element[0]}`);
                  }
                });
              }
            }
          } else if (key === '3') {
            // 标高
            const cateObj = {};
            element = this.unique(element, 'name');
            element.forEach((item) => {
              const splitArr = item.name.split('-');
              if (!cateObj || !cateObj[splitArr[0]]) {
                cateObj[splitArr[0]] = [item];
              } else {
                cateObj[splitArr[0]].push(item);
              }
            });
            for (const i in cateObj) {
              if (cateObj[i] && cateObj[i].length) {
                const name = cateObj[i][0].name.split('-')[0];
                let arr = [];
                cateObj[i].forEach((element) => {
                  const str = element.name.split('-')[1];
                  arr.push(Number(str.slice(0, str.length - 1)));
                });
                arr = [...new Set(arr)];
                const list = this.fn(arr, cateObj[i][0].option_config.minUnit);
                list.forEach((element) => {
                  if (element.length > 1) {
                    showArr.push(
                      `${name}_${element[0]}-${element[element.length - 1]}米`
                    );
                  } else {
                    showArr.push(`${name}_${element[0]}米`);
                  }
                });
              }
            }
          } else if (key === '4') {
            // 里程
            const cateObj = {};
            element = this.unique(element, 'name');
            element.forEach((item) => {
              const str =
                item.option_config.name + item.start_mile + item.end_mile;
              if (!cateObj?.[str]) {
                cateObj[str] = [item];
              } else {
                cateObj[str].push(item);
              }
            });
            for (const i in cateObj) {
              if (cateObj[i] && cateObj[i].length) {
                const arr = [];
                cateObj[i].forEach((item) => {
                  const num1 = item.name
                    .split('-')[0]
                    .split('+')[0]
                    .replace(/[^0-9]/gi, '');
                  const start =
                    parseFloat(num1) * 1000 +
                    parseFloat(item.name.split('-')[0].split('+')[1]);
                  arr.push(start);
                });
                const list = this.fn(arr, cateObj[i][0].option_config.minUnit);
                const name = cateObj[i][0].name
                  .split('-')[0]
                  .split('+')[0]
                  .replace(/\d+/g, '');
                const minUnit = cateObj[i][0].option_config.minUnit;
                list.forEach((element) => {
                  let max = 0;
                  let maxNum = 0;
                  let num = element[element.length - 1] % 1000;
                  if (num < 10) {
                    num = `00${num}`;
                  } else if (num >= 10 && num < 100) {
                    num = `0${num}`;
                  }
                  const maxSatrt =
                    name +
                    Math.floor(element[element.length - 1] / 1000) +
                    '+' +
                    num;
                  /* 找到当前合并或者单个的显示对应的最大里程整数和单独零数 */
                  cateObj[i].forEach((ite) => {
                    if (ite.name.split('-')[0] === maxSatrt) {
                      max = ite.name
                        .split('-')[1]
                        .split('+')[1]
                        .replace(/[^0-9]/gi, '');
                      maxNum = ite.name
                        .split('-')[1]
                        .split('+')[0]
                        .replace(/[^0-9]/gi, '');
                    }
                  });
                  if (element.length > 1) {
                    const intNum1 = Math.floor(element[0] / 1000);
                    let remainderNum1 = element[0] % 1000;
                    const intNum2 =
                      element[element.length - 1] + parseFloat(minUnit) >
                      maxNum * 1000 + parseFloat(max)
                        ? Math.floor((maxNum * 1000 + parseFloat(max)) / 1000)
                        : Math.floor(
                            (element[element.length - 1] +
                              parseFloat(minUnit)) /
                              1000
                          );
                    let remainderNum2 =
                      element[element.length - 1] + parseFloat(minUnit) > max
                        ? max
                        : (element[element.length - 1] + parseFloat(minUnit)) %
                          1000;
                    if (remainderNum1 < 10) {
                      remainderNum1 = `00${remainderNum1}`;
                    } else if (remainderNum1 >= 10 && remainderNum1 < 100) {
                      remainderNum1 = `0${remainderNum1}`;
                    }
                    if (remainderNum2 < 10) {
                      remainderNum2 = `00${remainderNum2}`;
                    } else if (remainderNum2 >= 10 && remainderNum2 < 100) {
                      remainderNum2 = `0${remainderNum2}`;
                    }
                    showArr.push(
                      name +
                        intNum1 +
                        '+' +
                        remainderNum1 +
                        '-' +
                        name +
                        intNum2 +
                        '+' +
                        (remainderNum2 <= max ? remainderNum2 : max)
                    );
                  } else {
                    const intNum1 = Math.floor(element[0] / 1000);
                    let remainderNum1 = element[0] % 1000;
                    const intNum2 =
                      element[element.length - 1] + parseFloat(minUnit) >
                      maxNum * 1000 + parseFloat(max)
                        ? Math.floor((maxNum * 1000 + parseFloat(max)) / 1000)
                        : Math.floor(
                            (element[element.length - 1] +
                              parseFloat(minUnit)) /
                              1000
                          );
                    let remainderNum2 =
                      element[element.length - 1] + parseFloat(minUnit) > max
                        ? max
                        : (element[element.length - 1] + parseFloat(minUnit)) %
                          1000;
                    if (remainderNum1 < 10) {
                      remainderNum1 = `00${remainderNum1}`;
                    } else if (remainderNum1 >= 10 && remainderNum1 < 100) {
                      remainderNum1 = `0${remainderNum1}`;
                    }
                    if (remainderNum2 < 10) {
                      remainderNum2 = `00${remainderNum2}`;
                    } else if (remainderNum2 >= 10 && remainderNum2 < 100) {
                      remainderNum2 = `0${remainderNum2}`;
                    }
                    showArr.push(
                      name +
                        intNum1 +
                        '+' +
                        remainderNum1 +
                        '-' +
                        name +
                        intNum2 +
                        '+' +
                        (remainderNum2 <= max ? remainderNum2 : max)
                    );
                  }
                });
              }
            }
          }
        }
      }
      if (showArr && showArr.length) {
        showArr.forEach((element) => {
          if (!str) {
            str += element;
          } else {
            str += `, ${element}`;
          }
        });
      }
      return str;
    },
    /**
     * @description: 获取数组连续的1.2.3二维数组
     * @param {Array} arr
     * @param {Number} interval
     */
    fn(arr, interval) {
      var result = [];
      var i = 0;
      result[i] = [arr[0]];
      arr.reduce(function (prev, cur) {
        parseFloat((parseFloat(cur) - parseFloat(prev)).toFixed(5)) <=
        parseFloat(parseFloat(interval).toFixed(5))
          ? result[i].push(cur)
          : (result[++i] = [cur]);
        return cur;
      });
      return result;
    },
    /**
     * @description: 部位去重
     * @param {Array} arr
     * @param {String} attrName
     */
    unique(arr, attrName) {
      const res = new Map();
      return arr.filter(
        (a) => !res.has(a[attrName]) && res.set(a[attrName], 1)
      );
    },
  },
};
</script>

<style lang="less" scoped>
.table-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .el-table {
    flex: 1;
  }
  .table-box::before {
    height: 0 !important;
  }

  :deep(.transparent-table) {
    border: none !important;
    background: transparent;
    .el-table__expanded-cell {
      background: transparent;
    }
    .el-table__body-wrapper,
    .el-table__header-wrapper {
      tr {
        background: transparent;
        th {
          &::before {
            display: none;
          }
        }
        &:hover {
          & > td {
            .el-table__cell {
              background: transparent;
            }
          }
        }
        .el-table__cell {
          background: transparent;
          border: none;
        }
      }
    }
  }
  .el-pagination {
    box-sizing: border-box;
    padding: 5px 10px;
    text-align: left;
  }
  .image {
    height: 60px;
    width: 60px;
    margin: 0 auto;
    overflow: hidden;
  }
  :deep(.el-table) {
    .el-table__expanded-cell {
      box-sizing: border-box;
      padding: 16px;
    }
    &.mounted-class {
      .has-gutter {
        tr {
          th {
            position: relative;
            border-right: 0;
            &::before {
              position: absolute;
              z-index: 3; // 2888
              top: 0;
              right: 0;
              content: '';
              height: 100%;
              width: 1px;
              background: #ebeef5;
              cursor: col-resize !important;
            }
          }
        }
      }
    }
    span {
      &.status-block {
        display: inline-block;
        padding: 0 8px;
        font-size: 12px;
        border-radius: 12px;
        color: #fff;
        line-height: 24px;
        min-width: 60px;
        box-sizing: border-box;
        text-align: center;
      }
      &.status-point {
        display: block;
        span {
          display: inline-block;
          vertical-align: middle;
        }
        & > .point {
          height: 6px;
          width: 6px;
          box-sizing: border-box;
          border-radius: 3px;
          margin-right: 6px;
        }
      }
    }
    .hidden-scrollbar::-webkit-scrollbar {
      visibility: hidden !important;
      display: none !important;
    }
    p {
      &.ellipsis-1 {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      &.ellipsis {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        &.ellipsis-2 {
          -webkit-line-clamp: 2;
        }
        &.ellipsis-3 {
          -webkit-line-clamp: 3;
        }
        &.ellipsis-4 {
          -webkit-line-clamp: 4;
        }
        &.ellipsis-5 {
          -webkit-line-clamp: 5;
        }
        &.ellipsis-6 {
          -webkit-line-clamp: 6;
        }
        &.ellipsis-7 {
          -webkit-line-clamp: 7;
        }
        &.ellipsis-8 {
          -webkit-line-clamp: 8;
        }
        &.ellipsis-9 {
          -webkit-line-clamp: 9;
        }
        &.ellipsis-10 {
          -webkit-line-clamp: 10;
        }
      }
    }
  }
}
</style>
