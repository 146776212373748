<!--root
 * @Author: your name
 * @Date: 2021-12-02 15:32:01
 * @LastEditTime: 2023-03-06 10:42:32
 * @LastEditors: Shiltin 18580045074@163.com
 * @Description: 广播对讲设备被呼叫页面以及cline参数初始化页面，client会挂载在window上被下级页面使用
 * @FilePath: \nanhu-micro\src\views\components\bindModel\index.vue
-->
<template>
</template>
<script setup>
/* eslint-disable */
import { onMounted, onUnmounted, ref, getCurrentInstance } from "vue";
import axios from "axios";
import eventBus from "@/plugins/eventBus";
const useStore = () => {
  const vm = getCurrentInstance();
  if (!vm) throw new Error("must be called in setup");
  return vm.proxy.$store;
};
const store = useStore();
const deviceBaseUrl = ref('https://bcast-service.bimcc.com:8080');
const totalDevice = ref({
  onLine:[],
  offLine:[],
  busy:[]
});

const apiKey = ref('YWRtaW46YWRtaW5fYXBpX2tleQ==');

const getRequest = (str, query) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      params: query,
      url: `${deviceBaseUrl.value}${str}`,
      headers: {
        Authorization: `basic ${apiKey.value}`
      },
    }).then((res) => {
      resolve(res?.data?.data || []);
    }).catch(err => {
      console.log(err, '-----');
      reject();
    });
  });
}



const setGlobalState= () => {
  for(let key in totalDevice.value)
  store.commit('updatePageCustomStatus', {
    key: key,
    value: totalDevice.value[key].length
  });
  eventBus.$emit('deviceData', totalDevice.value);
}

const onlineStatus =  async() => {
  totalDevice.value.onLine=[];
  totalDevice.value.offLine=[];
  totalDevice.value.busy=[];
  //分控
  const arr = await getRequest('/v1/caster');
  // 广播设备
  const arr1 =  await getRequest('/v1/device');
  //对讲设备
  const arr2 = await getRequest('/v1/dialogue');
  const data = arr.concat(arr1.concat(arr2));
  console.log(data,'data')
  for(let i=0;i<data.length;i++){
    if(data[i].online){
      totalDevice.value.onLine.push({device_number:data[i].code,ao_volume:data[i].ao_volume,name:data[i].dev_name});
    } else {
      totalDevice.value.offLine.push({device_number:data[i].code,ao_volume:data[i].ao_volume,name:data[i].dev_name});
    }
    if(+data[i].tuning_task?.play_status === 2){
      totalDevice.value.busy.push({device_number:data[i].code,ao_volume:data[i].ao_volume,name:data[i].dev_name});
    }
    if(+data[i].play_status === 2){
      totalDevice.value.busy.push({device_number:data[i].code,ao_volume:data[i].ao_volume,name:data[i].dev_name});
    }
  }
  setGlobalState()
}
onUnmounted(() => {
  eventBus.$off('getDeviceStatus')
});
onMounted(() => {
  onlineStatus();
  eventBus.$on('getDeviceStatus',()=>{ 
    if(totalDevice.value){
      for(let key in totalDevice.value){
        let data = totalDevice.value[key];
        if(data?.length){
          for(let i=0;i<data.length;i++){
            if(key === 'offLine'){
              eventBus.$emit('deviceStatus',{
                ...data[i],
                status: -1
              })
            } else if(key === 'busy') {
              eventBus.$emit('deviceStatus',{
                ...data[i],
                status: 2
              })
            }
          }
        }
      }
    }
  })
});
</script>