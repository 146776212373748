<!--
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2024-11-27 15:16:40
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-11-28 11:04:19
 * @FilePath: \dataview-viewer-test\src\custom-component\broadcast\components\chooseDevice.vue
 * @Description: 
-->
<!--root
 * @Author: your name
 * @Date: 2021-12-02 15:32:01
 * @LastEditTime: 2023-03-06 10:42:32
 * @LastEditors: Shiltin 18580045074@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE /
 * @FilePath: \nanhu-micro\src\views\components\bindModel\index.vue
-->
<template>
	<div class="choose-device-cont">
    <div class="left-tree-cont">
      <el-input
        placeholder="请输入内容"
        @input="onFilterData"
        v-model="searchVal">
        <i slot="prefix" class="el-input__icon el-icon-search" @click="onFilterData(searchVal)"></i>
      </el-input>
      <div class="h-auto">
        <el-tree
          ref="treeRef"
          :data="treeData"
          :props="treeProps"
          :filterNodeMethod="filterNode"
          highlight-current
          node-key="id"
          default-expand-all
          show-checkbox
          @check="handleCheckChange">
        </el-tree>
      </div>
      
    </div>
    <div class="right-list-cont">
      <div class="top-title"><i class="iconfont iconjijia_konghang"></i> 已选中设备（{{chooseData.length}}）</div>
      <el-input
        class="group-name"
        placeholder="请输入组名称"
        v-model="groupName">
      </el-input>
      <div class="main-list">
        <div v-for="(item, index) in chooseData" :key="index" class="list-item">
          <i class="iconfont iconzaixianshebei device-icon"></i>
          <div class="device-name">{{ item.name }}</div> 
          <i class="iconfont iconbiaotiguanbi" @click="onDelDevice(index,item.id)"></i>
        </div>
      </div>  
      <div class="commit-btns" v-if="chooseData?.length">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="onSure">确 定</el-button>
      </div>
    </div>
	</div>	
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import { dataInterface } from '@/apis/data/index';
const chooseData = ref([]);
const searchVal = ref('');
const groupName = ref('自定义组');
const treeData = ref([]);
const treeRef = ref(null);
const emits = defineEmits(['on-save']);
const treeProps = ref(
  {
    children: 'children',
    label: 'name'
  }
) 
/**
 * @description: 过滤节点
 * @param {*} value
 * @param {*} data
 */
const filterNode = (value, data) => {
  if (!value || !treeProps.value?.label || !data) return true;
  return data[treeProps.value?.label].indexOf(value) !== -1;
};
const onFilterData = (value) => {
    treeRef.value.filter(value);
}
const handleCheckChange = () => {
  const checkData = treeRef.value.getCheckedNodes();
  chooseData.value = checkData.filter(v=>v.device_number);
}
const onDelDevice = (i,id) => {
  chooseData.value.splice(i,1);
  treeRef.value.setChecked(id,false);
}
const onCancel = () => {
  chooseData.value = [];
}
const onSure = () => {
  emits('on-save',chooseData.value, groupName.value)
}
const getData = () => {
  dataInterface(null, 'api/graph/1587').then((res) => {
    if(res.data.code === 200){
      treeData.value = res.data.data;
    }
  });
}
onMounted(() => {
  getData()
});
</script>
<style scoped lang="less">
	.choose-device-cont{
		width: 100%;
    box-sizing: border-box;
		height: 400px;
		display: flex;
		flex-direction: row;
		overflow: hidden;
		background: #fff;
    border-top: 1px solid var(--border-on-surface-medium, #E1E4EC);
    .left-tree-cont{
      width: 50%;
      padding: 16px;
      box-sizing: border-box;
      overflow: hidden;
      border-right: 1px solid var(--border-on-surface-medium, #E1E4EC);
      display: flex;
      flex-direction: column;
      .h-auto{
        flex: 1;
        overflow-y: auto;
        margin-top: 16px;
      }
    }
    .right-list-cont{
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .top-title{
        height: 32px;
        line-height: 32px;
        padding:16px 20px 0 20px;
        color: var(--sys-text-text-primary, #161C1F);
        font-family: "Source Han Sans CN";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.5px;
      }
      .group-name{
        padding: 0 20px;
        box-sizing: border-box;
      }
      .main-list{
        flex: 1;
        width: 100%;
        overflow-y: auto;
        .list-item{
          height: 40px;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 4px 20px 4px 25px;
          box-sizing: border-box;
          line-height: 32px;
          .device-icon{
            color: #2FA45A;
          }
          .device-name{
            text-align: left;
            flex: 1;
            margin-left: 8px;
            overflow: hidden;
            color: var(--text-on-surface-primary, #181B22);
            text-overflow: ellipsis;
            white-space: nowrap;
            font-family: "PingFang SC";
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.5px;
          }

        }
      }
      .commit-btns{
        text-align: right;
        width: 100%;
        height: 64px;
        padding: 16px;
        box-sizing: border-box;
      }
    }
	}
</style>
