<!--root
 * @Author: your name
 * @Date: 2021-12-02 15:32:01
 * @LastEditTime: 2023-03-06 10:42:32
 * @LastEditors: Shiltin 18580045074@163.com
 * @Description: 广播对讲设备被呼叫页面以及cline参数初始化页面，client会挂载在window上被下级页面使用
 * @FilePath: \nanhu-micro\src\views\components\bindModel\index.vue
-->
<template>
  <div>
    <div v-if="callShow" class="broadcast-wait">
      <p class="title">通话申请</p>
      <div class="img-cont">
        <img class="call-icon" :class="!isSpeaking?'shark-phone':''" src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/170180e884ff7931869bf9c2a587f5b0.png">
        <div class="wave"></div> 
      </div>
      <p calss="device-name"> {{!isSpeaking?`对讲设备${deviceName}请求通话`:`与${deviceName}通话中...`}}</p>
      <div class="handle-cont" :class="isSpeaking?'hide-left':'show-left'">
        <img v-if="!isSpeaking" class="call-icon" @click="duplexAnswer"  src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/8105d00fc901d4416d7f9546ec7ebf03.png">
        <img class="call-icon" @click="duplexBye"  src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/4ffabab8a4dd5de268a2a5fe02ea8eed.png">
      </div>
    </div>
    <div v-if="warnShow" class="warn-dialog">
      <img src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/c469f0100e4a67623fb9baf8d0466448.png" alt="">
    </div>
  </div>
  
</template>
<script setup>
/* eslint-disable */
import { onMounted, onUnmounted, ref, getCurrentInstance, watch } from "vue";
import { dataInterface } from '@/apis/data/index';
import axios from "axios";
import { HZRecorder } from '@/utils/HZRecorder.js';
import { getToken, getNowTime} from '@/utils/tools';
import { baseUrl } from '@/apis/http/request';
import eventBus from "@/plugins/eventBus";
const props = defineProps({
  broadcastInfo:{
    type: Object,
    default: () => {},
  },
  route:{
    type: Object,
    default: () => {},
  }
})
const useStore = () => {
  const vm = getCurrentInstance();
  if (!vm) throw new Error("must be called in setup");
  return vm.proxy.$store;
};
const store = useStore();
const isSpeaking = ref(false);
const callShow = ref(false);//对讲的页面
const warnShow = ref(false);//爆破预警的显示
const api = ref(null);
const isCall = ref(1);
const client = ref(null);
const player = ref(null);
const recorder = ref(null);
const deviceBaseUrl = ref('https://bcast-service.bimcc.com:8080');
const baseRecorder = ref(null);
const deviceNumber = ref('');
const deviceName = ref('');
const timer = ref(null);
const warningStartTime = ref('');
const totalDevice = ref({
  onLine:[],
  offLine:[],
  busy:[]
});
const server = ref({
  ws_url: "wss://bcast-service.bimcc.com:8080/v1/ws/client",
  user: props.broadcastInfo.userName,
  pwd: props.broadcastInfo.password,
});
const apiKey = ref('YWRtaW46YWRtaW5fYXBpX2tleQ==');

const getRequest = (str, query) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      params: query,
      url: `${deviceBaseUrl.value}${str}`,
      headers: {
        Authorization: `basic ${apiKey.value}`
      },
    }).then((res) => {
      resolve(res?.data?.data || []);
    }).catch(err => {
      console.log(err, '-----');
      reject();
    });
  });
}

watch(()=>totalDevice.value,()=>{
    setGlobalState();
},{
  deep:true
})
watch(()=>props.route,()=>{
  setTimeout(()=>{
    setGlobalState();
  },100)
},{
  deep:true
})

const setGlobalState= () => {
  for(let key in totalDevice.value){
    store.commit('updatePageCustomStatus', {
      key: key,
      value: totalDevice.value[key].length
    });
  }
  eventBus.$emit('deviceData', totalDevice.value);
}

const onlineStatus =  async() => {
  totalDevice.value.onLine=[];
  totalDevice.value.offLine=[];
  totalDevice.value.busy=[];
  //分控
  const arr = await getRequest('/v1/caster');
  // 广播设备
  const arr1 =  await getRequest('/v1/device');
  //对讲设备
  const arr2 = await getRequest('/v1/dialogue');
  const data = arr.concat(arr1.concat(arr2));
  for(let i=0;i<data.length;i++){
    if(data[i].online){
      totalDevice.value.onLine.push({device_number:data[i].code,ao_volume:data[i].ao_volume,name:data[i].dev_name});
    } else {
      totalDevice.value.offLine.push({device_number:data[i].code,ao_volume:data[i].ao_volume,name:data[i].dev_name});
    }
    if(+data[i].tuning_task?.play_status === 2){
      totalDevice.value.busy.push({device_number:data[i].code,ao_volume:data[i].ao_volume,name:data[i].dev_name});
    }
    if(+data[i].play_status === 2){
      totalDevice.value.busy.push({device_number:data[i].code,ao_volume:data[i].ao_volume,name:data[i].dev_name});
    }
  }
  setGlobalState()
}

const openParrot = () => {
  if (!api.value) {
    alert("尚未初始化成功");
    return;
  }
  if (!player.value) {
    player.value = new PCMPlayer({
      inputCodec: "Int16",
      channels: 1,
      sampleRate: 8000,
      flushTime: 320,
    });
  }

  if (!client.value) {
    client.value = new ParrotClient(api.value);
    window.client = client.value;
    client.value.on("playFrame", (event, data) => {
      let view = new DataView(data.buffer);
      let buf = new Int16Array(data.byteLength / 2);
      for (let i = 0; i < data.byteLength / 2; i++) {
        buf[i] = view.getInt16(2 * i, true);
      }
      player.value.feed(buf);
    });
    
    client.value.on("loginStatus",(event, data) => {
      console.log(event,data,'---------------');
      if(+data.status === 3){
        onlineStatus();
      } else if(+data.status === 4){
        closeParrot();
      }
    })
    //监控分控状态
    client.value.on("casterStatus",(event, data) => {
      if(data && [2,0].includes(+data.status)){
        const regex = /\((.*?)\)/;
        //取名称括号里的设备号
        const deviceNumber = data.name.match(regex)[1] || data.caster_id;
        if(data.status === 2){
          if(!totalDevice.value.busy.length || !totalDevice.value.busy.filter(v=>v.device_number === deviceNumber).length){
            totalDevice.value.busy.push({device_number:deviceNumber})
          }
        } else if(+data.status === 0) {
          let i = totalDevice.value.busy.findIndex(v=>v.device_number === deviceNumber)
          totalDevice.value.busy.splice(i,1)
        }
        eventBus.$emit('deviceStatus',{
          ...data,
          device_number:deviceNumber
        })
      }
    })
    //监听广播
    client.value.on("TuneListStatus",(event, data) => {
      const status =  data?.tuning_task?.play_status;
      if([2,0].includes(+status) && data.device_codes?.length){
        const deviceIds = data.device_codes;
        for(let i=0;i<deviceIds.length;i++){
          if(+status === 2){
            if(!totalDevice.value.busy.length || !totalDevice.value.busy.filter(v=>v.device_number === deviceIds[i]).length){
              totalDevice.value.busy.push({device_number:deviceIds[i]})
            }
          } else if(+status === 0){
            let n = totalDevice.value.busy.findIndex(v=>v.device_number === deviceIds[i])
            totalDevice.value.busy.splice(n,1)
          }
          eventBus.$emit('deviceStatus',{
            status:status,
            device_number:deviceIds[i]
          })
        }
      }
    })
    
    client.value.on("callStatus", (event, data) => {
      isCall.value = Number(data.is_caller);
      deviceName.value = data.name;
      if(+data.status === 180 && !data.is_caller){
        callShow.value = true;
      }
      if(+data.status === 200){
        deviceNumber.value = data.code;
        if(baseRecorder.value){
          baseRecorder.value.start();
        }
      }
      if(+data.status === 0){
        callShow.value = false;
        isSpeaking.value = false;
        saveRecorder();
      }
    });
    //爆破预警
    client.value.on("AlarmStatus", (event, data) => {
      clearTimeout(timer.value);
      timer.value = setTimeout(async()=>{
        warnShow.value = Boolean(data.status) || false; 
        if( +data.status === 1){
          warningStartTime.value = getNowTime(2);
        }
        if(+data.status === 0 && warningStartTime.value){
          const plan = await getRequest('/v1/alarm_plan',{id:data.alarm_plan_id});
          //将破预警记录存入数仓
          if(plan?.length){
            let param = {
              __method_name__: "createData", 
              object_uuid: "object64215a023df9b",
              blasting_state: 2,
              blasting_site:plan[0]?.org?.name || '',
              blasting_time:warningStartTime.value,
              blasting_end_time:getNowTime(2)
            }
            dataInterface(param).then(()=>{
              warningStartTime.value = ''
            })
          }
        }
      },300)
    })
  }

  if (!recorder.value) {
    recorder.value = new PcmRecorder();
    recorder.value.requestMicrophone();
    recorder.value.onFrameData = client.value.sendFrame.bind(client.value);
    client.value.on(
      "startRecord",
      recorder.value.startRecord.bind(recorder.value)
    );
    client.value.on(
      "stopRecord",
      recorder.value.stopRecord.bind(recorder.value)
    );
  }

  client.value.setLogLevel(0);
  client.value.setUsername(server.value.user);
  client.value.setTimestamp(new Date().getTime());
  client.value.setPassword(server.value.pwd);
  client.value.setUrl(server.value.ws_url);
  client.value.openParrot();
};
//应答
const duplexAnswer = () => {
  client.value.duplexAnswer(200);
  isSpeaking.value = true;
};
const duplexBye = () => {
  if (client.value) {
    client.value.duplexBye();
  } 
  saveRecorder();
};
const saveRecorder = () => {
  // 结束录音识别
  if(!baseRecorder.value){
    callShow.value = false;
    return;
  }
  baseRecorder.value.stop();
  let audioData = new FormData();
  const newBolb = baseRecorder.value.getBlob();
  const fileName = `对讲录音${new Date().getTime()}.wav`;
  const fileOfBlob = new File([newBolb],fileName, {
    type: 'audio/wav'
  });
  audioData.append('file', fileOfBlob);
  const seconds = baseRecorder.value.seconds;
  if(!seconds){
    return;
  }
  baseRecorder.value.destory();
  // 重新初始化
  baseRecorder.value = null;
  initRecorder({video: false, audio: true});
  axios({
    method: 'post',
    url: `${baseUrl}api/mapi?__method_name__=file&token=${getToken()}`,
    data: audioData,
  }).then((res) => {
    if(res.data?.code === 200){ 
      //存录音文件
      dataInterface({
        __method_name__: "createData", 
        object_uuid: "object67482cfe71951",
        commun_type: 2,
        file_name: fileName,
        commun_times: seconds,
        files:res.data.data,
        interphone_id:deviceNumber.value,
        is_caller:isCall.value,
        elvence_child: elvenceChild.value
      })
    }
  });
  callShow.value = false;
}
const closeParrot = () => {
  if (client.value) {
    client.value.closeParrot();
  }
};
//初始化录音
const initRecorder = (constrains) => {
  if (navigator?.mediaDevices?.getUserMedia) {
    navigator?.mediaDevices?.getUserMedia(constrains).then(stream => {
      baseRecorder.value = new HZRecorder(stream);
    }).catch(err => {
      console.log(err);
    });
  } else if (navigator?.webkitGetUserMedia) {
    navigator.webkitGetUserMedia(constrains).then(stream => {
      baseRecorder.value = new HZRecorder(stream);
    }).catch(err => {
      console.log(err);
    });
  } else if (navigator?.mozGetUserMedia) {
    navigator.mozGetUserMedia(constrains).then(stream => {
      baseRecorder.value = new HZRecorder(stream);
    }).catch(err => {
      console.log(err);
    });
  } else if (navigator?.getUserMedia) {
    navigator.getUserMedia(constrains).then(stream => {
      baseRecorder.value = new HZRecorder(stream);
    }).catch(err => {
      console.log(err);
    });
  }
}
onUnmounted(() => {
  closeParrot();
  window.client = null;
  eventBus.$off('getDeviceStatus')
});
onMounted(() => {
  if (navigator.mediaDevices.getUserMedia || navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia) {
    initRecorder({video: false, audio: true});
  }
  eventBus.$on('getDeviceStatus',()=>{ 
    if(totalDevice.value){
      for(let key in totalDevice.value){
        let data = totalDevice.value[key];
        if(data?.length){
          for(let i=0;i<data.length;i++){
            if(key === 'offLine'){
              eventBus.$emit('deviceStatus',{
                ...data[i],
                status: -1
              })
            } else if(key === 'busy') {
              eventBus.$emit('deviceStatus',{
                ...data[i],
                status: 2
              })
            }
          }
        }
      }
    }
  })
  ParrotApi().then((res) => {
    api.value = { ...res };
    setTimeout(() => {
      openParrot();
    }, 2000);
  });
});
</script>
<style scoped lang="less">
/* 定义关键帧动画 */
@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-10px);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(10px);
  }
}
.warn-dialog{
  width: 410px;
  height: 300px;
  display: flex;
  padding: 38px 16px 16px 16px;
  flex-direction: column;
  overflow: hidden;
  border-radius: 16px;
  background: linear-gradient(180deg, rgba(23, 41, 85, 0.70) 0%, rgba(23, 53, 75, 0.70) 100%);
  backdrop-filter: blur(8px);
  position:fixed;
  top:50%;
  left:50%;
  margin:-150px 0 0 -210px;
  z-index:2100;
  color:#fff;
  text-align:center;
}

.broadcast-wait {
  width: 410px;
  height: 300px;
  display: flex;
  padding: 38px 16px 16px 16px;
  flex-direction: column;
  overflow: hidden;
  border-radius: 16px;
  background: linear-gradient(180deg, rgba(23, 41, 85, 0.70) 0%, rgba(23, 53, 75, 0.70) 100%);
  backdrop-filter: blur(8px);
  position:fixed;
  top:50%;
  left:50%;
  margin:-150px 0 0 -210px;
  z-index:2100;
  color:#fff;
  text-align:center;
  .title{
    color: var(--text-on-brand-primary, #FFF);
    text-align: center;
    font-family: "Source Han Sans CN";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    margin-bottom:10px;
  }
  .img-cont{
    position:relative;
    width: 100%;
    height:125px;
    .call-icon{
      width:100px;
      height:100px;
      position:absolute;
      left:50%;
      top:15px;
      margin-left:-50px;
      
    }
    .shark-phone{
      animation: shake 1s cubic-bezier(.36,.07,.19,.97) infinite;
    }
    .wave{
      height:100%;
      width:100%;
      background-image: url('https://openim-1309784708.cos.ap-shanghai.myqcloud.com/03eb88e10a44ab4888af2789b7b67b73.png');
      background-size: 70% 100%;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .device-name{
    width:100%;
    color: var(--text-on-surface-disabled, #BFC7D8);
    text-align: center;
    font-family: "Source Han Sans CN";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-align:center;
  }
  .handle-cont{
    margin-top:60px;
    width:100%;
    padding:0 70px;
    box-sizing:border-box;
    img{
      width:56px;
      height:56px;
    }
  }
  .show-left{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
  }
  .hide-left{
    text-align:center;
  }
}
</style>
