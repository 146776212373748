<!--root
 * @Author: your name
 * @Date: 2021-12-02 15:32:01
 * @LastEditTime: 2023-03-06 10:42:32
 * @LastEditors: Shiltin 18580045074@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nanhu-micro\src\views\components\bindModel\index.vue
-->
<template>
  <div class="talkback-cont">
    <Group
      v-if="element.statusConfig?.showDevices || element.statusConfig?.showDevices === undefined"
      @on-change="onChooseDevice"
      :groupData="groupData"
      :onlineDevices="onlineDevices"
    />
    <div class="voice-cont">
      <div class="top-handle">
        {{ chooseDevice?.device_name }}
      </div>
      <div class="middle-handle">
        <img
          v-if="!chooseDevice"
          src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/73bd24219814e123c3c24b36aa50a133.png"
          alt=""
        />
        <div class="animate-cont" :style="{opacity:isOnLine?'1':'0.2'}" v-else>
          <img
            @click="onHandle"
            :src="
              !isSpeaking && !isHolding
                ? 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/24036b88f155f4e845f0f34914869093.png'
                : 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/2c8a6a0c49830285379f5ca982e2cd74.png'
            "
          />
          <div v-if="isSpeaking || isHolding" class="wave"></div>
          <div v-if="isSpeaking || isHolding" class="wave"></div>
          <div v-if="isSpeaking || isHolding" class="wave wave1"></div>
          <div v-if="isSpeaking || isHolding" class="wave wave2"></div>
        </div>
        <div class="desc-cont">
          <p v-if="!chooseDevice">点击组列表进行通话</p>
          <p v-else-if="!isOnLine">设备离线，暂无法对讲</p>
          <p v-else-if="!isSpeaking && !isHolding">点击拨打</p>
          <p v-else-if="!isSpeaking && isHolding">呼叫中...</p>
          <p v-else>已接通</p>
        </div>
      </div>
      <div v-if="chooseDevice" class="bottom-handle">
        <div class="voice-num-cont">
          <span> {{ voiceNum }}</span>
          <Slider
            v-model="voiceNum"
            :min="0"
            :max="100"
            :step="1"
            :disabled="!isOnLine"
            class="contentItem-slider"
            @change="onChangeVoice"
          ></Slider>
          <i class="iconfont iconyinliang"></i>
        </div>
      </div>
    </div>
    <History v-if="chooseDevice" :chooseDevice="chooseDevice" />
  </div>
</template>
<script setup>
/* eslint-disable */
import {
  onMounted,
  onUnmounted,
  inject,
  ref,
  computed,
  getCurrentInstance,
} from "vue";
import { Slider } from "element-ui";
import { dataInterface } from "@/apis/data/index";
import Group from "./components/group.vue";
import History from "./components/history.vue";
import { HZRecorder } from "@/utils/HZRecorder.js";
import axios from "axios";
import { getToken, removeEventBus, initParams } from "@/utils/tools";
import { baseUrl } from "@/apis/http/request";
import eventBus from "@/plugins/eventBus";
const useStore = () => {
  const vm = getCurrentInstance();
  if (!vm) throw new Error("must be called in setup");
  return vm.proxy.$store;
};
const useRoute = () => {
  const vm = getCurrentInstance();
  if (!vm) throw new Error("must be called in setup");
  return vm.proxy.$route;
};
const props = defineProps({
  element: {
    type: Object,
    required: true,
    default: () => {},
  },
  // 是否为预览
  isPreview: {
    type: Boolean,
    required: false,
    default: true,
  },
  componentList: {
    default: null,
  },
});
const EDITOR_pageUUID = inject("EDITOR_pageUUID");
const voiceNum = ref(100);
const chooseDevice = ref(null);
const timer = ref(null);
const isOnLine = ref(true);
const isSpeaking = ref(false);
const isHolding = ref(false);
const isWaitData = ref(null);
const baseRecorder = ref(null);
const groupData = ref([]);
const onlineDevices = ref([]);
const elvenceChild = ref([]);
const store = useStore();
const route = useRoute();
//用户
const talkUser = ref("client");
const isCaller = ref(1);
const apiKey = ref("YWRtaW46YWRtaW5fYXBpX2tleQ==");

const patchRequest = (url, data) => {
  axios({
    method: "PATCH",
    data: data,
    url: url,
    headers: {
      Authorization: `basic ${apiKey.value}`,
    },
  })
};

//左侧组列表数据
const getGroupData = () => {
  isCaller.value = 1;
  dataInterface(null, "api/graph/1621").then((res) => {
    if (res.data.code === 200) {
      groupData.value = res.data.data || [];
      if (isWaitData.value) {
        chooseDevice.value = groupData.value.find((v) =>
          JSON.stringify(v).includes(isWaitData.value.device_number)
        );
        talkUser.value = chooseDevice.value?.device_number || "test_2";
        isWaitData.value = null;
      } else if (chooseDevice.value) {
        chooseDevice.value = groupData.value.find(
          (v) => v.id === chooseDevice.value.id
        );
        talkUser.value = chooseDevice.value?.device_number || "test_2";
      }
    }
  });
};

const onChangeVoice = () => {
  if (chooseDevice.value) {
    patchRequest(
      `${deviceBaseUrl.value}/v1/dialogue/${chooseDevice.value.id}/volume`,
      { ao_volume: voiceNum.value }
    );
  }
};

const onChooseDevice = (item) => {
  chooseDevice.value = item;
  talkUser.value = item.device_number || "test_2";
  elvenceChild.value = [{ device_id: item.id, commun_type: 1 }];
  const findData = onlineDevices.value.find(v=> v.device_number === talkUser.value);
  if(findData){
    isOnLine.value = true
    voiceNum.value = findData.ao_volume;
  } else {
    isOnLine.value = false
  }
};

//初始化录音
const initRecorder = (constrains) => {
  if (navigator?.mediaDevices?.getUserMedia) {
    navigator?.mediaDevices
      ?.getUserMedia(constrains)
      .then((stream) => {
        baseRecorder.value = new HZRecorder(stream);
      })
      .catch((err) => {
        console.log(err);
      });
  } else if (navigator?.webkitGetUserMedia) {
    navigator
      .webkitGetUserMedia(constrains)
      .then((stream) => {
        baseRecorder.value = new HZRecorder(stream);
      })
      .catch((err) => {
        console.log(err);
      });
  } else if (navigator?.mozGetUserMedia) {
    navigator
      .mozGetUserMedia(constrains)
      .then((stream) => {
        baseRecorder.value = new HZRecorder(stream);
      })
      .catch((err) => {
        console.log(err);
      });
  } else if (navigator?.getUserMedia) {
    navigator
      .getUserMedia(constrains)
      .then((stream) => {
        baseRecorder.value = new HZRecorder(stream);
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

const duplexInvite = () => {
  if (window.client && talkUser.value) {
    window.client.duplexCall(talkUser.value);
  }
};
const onBehavior = (type, param) => {
  console.info(type,param,'======')
  //一键对讲
  if (type === "onBroadcast") {
    if (param?.device_number) {
      if (!groupData.value.length) {
        isWaitData.value = param;
      } else {
        chooseDevice.value = groupData.value.find((v) =>
          JSON.stringify(v).includes(param.device_number)
        );
        talkUser.value = param.device_number || "test_2";
      }
    }
  }
};
const onHandle = () => {
  if(!isOnLine.value){
    return;
  }
  if (!isSpeaking.value && !isHolding.value) {
    isHolding.value = true;
    duplexInvite();
  } else {
    isSpeaking.value = false;
    isHolding.value = false;
    duplexBye();
  }
};

const duplexBye = () => {
  if (window.client) {
    window.client.duplexBye();
  }
  saveRecorder();
};
const saveRecorder = () => {
  // 结束录音识别
  if (!baseRecorder.value) {
    return;
  }
  baseRecorder.value.stop();
  let audioData = new FormData();
  // const newBolb = new Blob([recorder.value.__buffer_data]);
  const newBolb = baseRecorder.value.getBlob();
  const fileName = `对讲录音${new Date().getTime()}.wav`;
  const fileOfBlob = new File([newBolb], fileName, {
    type: "audio/wav",
  });
  audioData.append("file", fileOfBlob);
  const seconds = baseRecorder.value.seconds;
  if (!seconds) {
    return;
  }
  baseRecorder.value.destory();
  // 重新初始化
  baseRecorder.value = null;
  initRecorder({ video: false, audio: true });
  axios({
    method: "post",
    url: `${baseUrl}api/mapi?__method_name__=file&token=${getToken()}`,
    data: audioData,
  }).then((res) => {
    if (res.data?.code === 200) {
      //存录音文件
      dataInterface({
        __method_name__: "createData",
        object_uuid: "object67482cfe71951",
        commun_type: 2,
        file_name: fileName,
        commu_volume: voiceNum.value,
        commun_times: seconds,
        files: res.data.data,
        interphone_id: chooseDevice.value.id,
        is_caller: isCaller.value,
        elvence_child: elvenceChild.value,
      }).then((re) => {
        if (re.data?.code === 200) {
          getGroupData();
        }
      });
    }
  });
};
const subComponentData = computed(() => {
  if (EDITOR_pageUUID) {
    return (
      store.state.subsidiaryComponentData?.[EDITOR_pageUUID]?.componentData ||
      store.state.componentData
    );
  }
  return store.state.componentData;
});

const listerFun = () => {
  window.client?.on("callStatus", (event, data) => {
    isCaller.value = Number(data.is_caller);
    if (+data.status !== 0) {
      if (+data.status === 100) {
        isSpeaking.value = false;
        isHolding.value = true;
      } else if (+data.status === 200) {
        isSpeaking.value = true;
        isHolding.value = false;
        if (baseRecorder.value) {
          baseRecorder.value.start();
        }
      }
    } else {
      if (isSpeaking.value) {
        saveRecorder();
      }
      isSpeaking.value = false;
      isHolding.value = false;
    }
  });
};
onUnmounted(() => {
  removeEventBus(["doComponentBehavior"], props.element.id);
  eventBus.$off('deviceData');
});
onMounted(() => {
  const doComponentBehavior = {
    [props.element.id]: (config) => {
      const { component, list = [] } = config;

      if (props.element.id !== component) return false;
      list.forEach((ele) => {
        const { behaviors, params } = ele;
        const { param = {}, canPost } = initParams(
          params,
          false,
          subComponentData.value,
          []
        );
        if (canPost) {
          // 调用行为方法
          behaviors.forEach((funName) => {
            try {
              onBehavior(funName, param);
            } catch (err) {
              console.log(err);
            }
          });
        }
      });
    },
  };
  eventBus.$on("doComponentBehavior", doComponentBehavior[props.element.id]);
  //获取设备状态信息
  eventBus.$on('deviceData',(data)=>{
    clearTimeout(timer.value);
    timer.value = setTimeout(()=>{
      onlineDevices.value = data.onLine || [];
      const devices = data.onLine.concat(data.offLine);
      const findData = devices.find(v=> v.device_number === talkUser.value)
      isOnLine.value = onlineDevices.value.filter(v=> v.device_number === talkUser.value).length !== 0;
      //获取音量
      if(findData){
        voiceNum.value = findData.ao_volume;
      }
    },300)

  });
  //路由带设备参数的情况
  if(route?.query?.device_number){
    //一键喊话
    talkUser.value = route.query.device_number; 
    if (!groupData.value.length) {
      isWaitData.value = {device_number:route.query.device_number};
    }
  }
  if (
    navigator?.mediaDevices?.getUserMedia ||
    navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia
  ) {
    initRecorder({ video: false, audio: true });
  }
  //监听设备呼叫状态
  listerFun();
  getGroupData();
});
</script>
<style scoped lang="less">
@keyframes scale {
  0% {
    transform: translateY(0) scale(0.9) rotateX(0);
    opacity: 0;
  }
  50% {
    transform: translateY(0) scale(1) rotateX(0);
    opacity: 0.3;
  }
  100% {
    transform: translateY(0) scale(1.1) rotateX(0);
    opacity: 0;
  }
}
.talkback-cont {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  :deep(.el-dialog__body) {
    padding: 0;
  }
  .voice-cont {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: var(--overall-surface-variant, #f2f5fa);
    border: 1px solid var(--border-on-surface-medium, #e1e4ec);
    overflow: hidden;
    .top-handle {
      height: 56px;
      line-height: 36px;
      width: 100%;
      padding: 10px 16px;
      border-bottom: 1px solid var(--border-on-surface-medium, #e1e4ec);
      box-sizing: border-box;
      color: var(--text-on-surface-primary, #181b22);
      font-family: "Source Han Sans CN";
      font-size: 16px;
      font-weight: 500;
      text-align: center;
    }
    .middle-handle {
      flex: 1;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      img {
        width: 163px;
        height: 163px;
      }
      .animate-cont {
        position: relative;
        .voice-wave {
          display: flex;
          flex-direction: row;
          align-items: center;
          position: relative;
        }
        .wave {
          width: 163px;
          height: 163px;
          border-radius: 50%;
          opacity: 0;
          border-width: 10px;
          border-style: solid;
          border-color: #ed474a;
          animation: scale 2s linear infinite;
          position: absolute;
          top: -10px;
          left: -10px;
          pointer-events: none;
        }
      }

      .wave1 {
        animation-delay: 1s;
      }
      .wave2 {
        animation-delay: 2s;
      }
      .desc-cont {
        margin-top: 40px;
        color: var(--text-on-surface-tertiary, #707786);
        font-family: "Source Han Sans CN";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        span {
          color: var(--text-on-surface-brand, #387ffc);
        }
      }
    }
    .bottom-handle {
      height: 48px;
      padding: 8px 16px;
      box-sizing: border-box;
      width: 100%;
      text-align: right;
      line-height: 16px;
      color: #4d535e;
      .voice-num-cont {
        width: 100%;
        text-align: right;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        i,
        span {
          line-height: 24px;
          font-size: 16px;
        }
        :deep(.el-slider) {
          width: 130px;
          margin: 0 5px;
        }
        :deep(.el-slider__button) {
          width: 9px;
          height: 9px;
          border-width: 1px;
        }
      }
    }
  }
}
</style>
