export default class X6GraphEvent {
  base = null;

  // x6实例
  graph = null;

  // vue实例
  vm = null;

  constructor(base) {
    this.base = base;
    this.vm = this.base.vm;
    this.graph = this.base.graph;
    this.register();
  }

  // 注册事件
  register() {
    this.cellEvent(); // 单元事件
    // this.modelEvent(); // 画布事件
    this.keyboardEvent(); // 键盘事件
    this.selectionEvent(); // 选区事件
  }

  cellEvent() {
    // 单元右键事件
    this.graph.on('cell:contextmenu', ({
      e, x, y, cell,
    }) => {
      // console.log('cell:contextmenu', x, y, cell, view);
      this.base.curCell = cell;

      let { offsetX } = e;
      let { offsetY } = e;

      // 选中时使用节点坐标
      if (this.graph.isSelected(cell)) {
        offsetX = x;
        offsetY = y;
      }

      this.base.showContextmenu(offsetX, offsetY);
    });

    this.graph.on('node:mouseenter', () => {
      this.showPorts();
    });
    this.graph.on('node:mouseleave', () => {
      this.showPorts(false);
    });

    this.graph.on('edge:mouseenter', ({ cell, e }) => {
      if (e?.altKey) {
        cell.addTools('vertices', 'onhover')
      }
    })

    this.graph.on('edge:mouseleave', ({ cell }) => {
      if (cell.hasTools('onhover')) {
        cell.removeTools()
      }
    })

    // 节点删除后
    this.graph.on('node:removed', (args) => {
      if (args.cell.parent) {
        args.cell.parent(args.cell);
      }
    });
    // 选择节点监听
    // 监听选择变化事件 -- @廖总加的需求，选中颜色改变，保存的时候回把颜色保存下来与回显时颜色冲突
    /*this.graph.on('selection:changed', (val) => {
      const { added, removed } = val
      if(added?.length) {
        added.forEach(element => {
          if (element?.store?.data?.shape === 'edge') {
            element.setAttrs({
              line: {
                stroke: '#6C75F5',
                strokeWidth: 3
              },
            })
          } else {
            element.setAttrs({
              body: {
                stroke: '#6BCA25',
                strokeWidth: 4,
              },
              label: {
                fill: '#6BCA25'
              }
            })
          }
        });
      } else if (removed?.length) {
        removed.forEach(element => {
          if (element?.store?.data?.shape) {
            const styleObj = {
              edge: {
                line: {
                  stroke: '#A2B1C3',
                  strokeWidth: 2,
                }
              },
              Start: {
                body: {
                  strokeWidth: 2,
                  stroke: '#6BCA25',
                },
                label: {
                  fill: '#7E8595'
                }
              },
              Step: {
                body: {
                  strokeWidth: 2,
                  stroke: '#5F95FF',
                },
                label: {
                  fill: '#262626'
                }
              },
              Send: {
                body: {
                  strokeWidth: 1,
                  stroke: '#5F95FF',
                },
                label: {
                  fill: '#262626'
                }
              },
              Gateway: {
                body: {
                  strokeWidth: 2,
                  stroke: '#7E8595',
                },
                label: {
                  fill: '#7E8595'
                }
              },
              End: {
                body: {
                  strokeWidth: 4,
                  stroke: '#F16463',
                },
                label: {
                  fill: '#7E8595'
                }
              },
              Event: {
                body: {
                  strokeWidth: 1,
                  stroke: '#5F95FF',
                },
                label: {
                  fill: '#262626'
                }
              },
              SubFlow: {
                body: {
                  strokeWidth: 1,
                  stroke: '#5F95FF',
                },
                label: {
                  fill: '#262626'
                }
              },
            }
            element.setAttrs({
              ...styleObj[element?.store?.data?.shape]
            })
          }
        });
      }
    })*/
  }

  /**
   * @description: 键盘事件
   * @return {*}
   */
  keyboardEvent() {
    // 删除
    this.graph.bindKey('delete', () => {
      this.base.deleteCellsWithSelected();
    });

    // 复制
    this.graph.bindKey('ctrl+c', () => {
      const cells = this.graph.getSelectedCells();
      if (cells.length) {
        this.graph.copy(cells);
      }
      return false;
    });

    // 粘贴
    this.graph.bindKey('ctrl+v', () => {
      this.base.isPasting = true;
      if (!this.graph.isClipboardEmpty()) {
        const cells = this.graph.paste({ offset: 32 });
        this.graph.cleanSelection();
        this.graph.select(cells);

        this.base.autoFixId(cells);
      }
      this.base.isPasting = false;
      return false;
    });
  }

  selectionEvent() {
    const ids = {};
    this.graph.on('blank:click', () => {
      this.base.vm.changeFLowProp() // 点击空白处右侧属性未同步
      this.base.vm.cellType = 'Start'
      this.base.vm.showPool = false
      this.base.vm.dialogVisible = false
    })
    this.graph.on('node:click', (args) => {
      if(this.base.vm.editable){
        this.base.curCell = args.cell;
        this.base.vm.curCell = args.cell;
        this.base.vm.showPool = false;
        this.base.vm.dialogVisible = false;
        this.base.vm.curCellProp = {
          ...args.cell.getData(),
          nodeId: args.cell.id
        };
        this.base.vm.cellType = args.cell.shape;
        this.base.vm.key = args.cell.id;
        this.showPorts(false);

        if (args.cell.isNode()) {
          ids[args.cell.id] = args.cell.zIndex;
          setTimeout(() => {
            args.cell.toFront();
          }, 0);
        }
      }
    });

    this.graph.on('edge:click', (args) => {
      if(this.base.vm.editable){
        this.base.curCell = args.cell;
        this.base.vm.curCell = args.cell;
        this.base.vm.showPool = false;
        this.base.vm.dialogVisible = false;
        this.base.vm.curCellProp = {
          ...args.cell.getData(),
          nodeId: args.cell.id
        };
        this.base.vm.cellType = args.cell.shape;
        this.base.vm.key = args.cell.id;
        this.showPorts(false);

        if (args.cell.isNode()) {
          ids[args.cell.id] = args.cell.zIndex;
          setTimeout(() => {
            args.cell.toFront();
          }, 0);
        }
      }
    });
  }

  // 控制连接桩显示/隐藏
  showPorts(show = true) {
    const container = this.vm.$el;
    const ports = container.querySelectorAll('.x6-port-body');

    for (let i = 0, len = ports.length; i < len; i += 1) {
      ports[i].style.visibility = show ? 'visible' : 'hidden';
    }
  }
}
